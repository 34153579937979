import type {Branch} from '../../../services/rest'
import type {BuildTypeId, ProjectId, ProjectOrBuildTypeNode} from '../../../types'

export const Sizes = {
  S: 12,
  M: 16,
  L: 20,
}
export enum EntityIconType {
  PROJECT = 'project',
  BUILD_TYPE = 'buildType',
  TEMPLATE = 'template',
  INVESTIGATION = 'investigation',
  PIPELINE = 'pipeline',
}

export type OverviewHeaderIconOwnProps = {
  showInvestigationInStatus?: boolean
  ignoreStatus?: boolean
  size?: keyof typeof Sizes
  className?: string | null | undefined
  title?: string
  projectOrBuildTypeNode?: ProjectOrBuildTypeNode | null | undefined
  edit?: string
  locked?: boolean
}
export type OverviewHeaderIconAddProps =
  | {
      type: EntityIconType.PROJECT
      id?: ProjectId
      branch?: Branch
    }
  | {
      type: EntityIconType.BUILD_TYPE
      id?: BuildTypeId
      branch?: Branch
      showInfoStatus?: boolean
    }
  | {
      type?: Exclude<EntityIconType, EntityIconType.PROJECT | EntityIconType.BUILD_TYPE> | null
    }
export type OverviewStatusIconOwnProps = OverviewHeaderIconOwnProps & OverviewHeaderIconAddProps
export type OverviewStatusIconStateProps = {
  title?: string | null | undefined
  status?: 'failed' | 'successful' | null
  type: EntityIconType | null | undefined
  composite?: boolean
  paused?: boolean
}
export type DefaultProps = {
  type: EntityIconType
  size: keyof typeof Sizes
}
export type Props = OverviewStatusIconOwnProps & OverviewStatusIconStateProps & DefaultProps
