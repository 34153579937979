import {setDisplayName} from 'recompose'

import {useCallback} from 'react'
import type {JSX, ComponentType} from 'react'

import {wrapDisplayName} from '../library/utils/getDisplayName'
import type {Enhancer} from '../types'

const fromRenderProps =
  <OwnProps, RenderProps, AddProps extends JSX.IntrinsicAttributes>(
    RenderPropsComponent: ComponentType<any>,
    propsMapper: (arg0: RenderProps) => AddProps,
    renderPropName = 'children',
  ): Enhancer<AddProps, OwnProps> =>
  BaseComponent => {
    function FromRenderProps(ownerProps: OwnProps) {
      return (
        <RenderPropsComponent
          {...{
            [renderPropName]: useCallback(
              (props: RenderProps) => <BaseComponent {...ownerProps} {...propsMapper(props)} />,
              [ownerProps],
            ),
          }}
        />
      )
    }

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(wrapDisplayName(BaseComponent, 'fromRenderProps'))(FromRenderProps)
    }

    return FromRenderProps
  }

export default fromRenderProps
