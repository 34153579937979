import Link from '@jetbrains/ring-ui/components/link/link'
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip'
import classnames from 'classnames'
import type {SyntheticEvent} from 'react'
import * as React from 'react'

import EntityLink from '../../../containers/EntityLink'
import SvgIcon from '../../../library/components/SvgIcon/SvgIcon'
import {BuildTypePageTabNamesEnum, stringifyId} from '../../../types'
import {Modes} from '../../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import CopyIconButton from '../CopyIconButton/CopyIconButton'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import RouterLink from '../RouterLink/RouterLink'
import {hasSelection} from '../Tree/Tree.utils'

import type {Props} from './BranchLabel.types'

import styles from './BranchLabel.css'

function BranchLabel(props: Props) {
  const {
    className,
    nameClassName,
    title,
    name,
    main,
    noLink,
    autoTrim,
    defaultTrim,
    withBorder,
    withTooltip,
    low,
    withIcon,
    inline,
    tailLength,
    href,
    to,
    buildId,
    branchNameInHref,
    isSakuraUI,
    buildTypeId,
    visibleNameClassName,
    ...restProps
  } = props

  const onClick = React.useCallback((e: SyntheticEvent) => {
    if (hasSelection()) {
      e.stopPropagation()
      e.preventDefault()
    }
  }, [])

  if (name == null) {
    return null
  }

  const classes = classnames(styles.buildBranch, className, {
    [styles.link]: !noLink,
    [styles.main]: main,
    [styles.autoTrim]: autoTrim,
    [styles.defaultTrim]: defaultTrim,
    [styles.withBorder]: withBorder,
    [styles.withIcon]: withIcon,
    [styles.low]: low,
    [styles.inline]: inline,
  })
  const commonProps = {
    ...restProps,
    className: classes,
    'data-test-branch-link': true,
    children: (
      <span className={styles.buildBranchContent}>
        {withIcon === true ? <SvgIcon icon="branches" className={styles.icon} /> : null}
        {withTooltip ? (
          <Tooltip long delay={500} title={name} className={styles.tooltipWrapper} selfOverflowOnly>
            <MiddleEllipsis
              title={title}
              tailLength={tailLength}
              className={nameClassName}
              visibleClassName={visibleNameClassName}
            >
              {name}
            </MiddleEllipsis>
          </Tooltip>
        ) : (
          <MiddleEllipsis
            title={title}
            tailLength={tailLength}
            className={nameClassName}
            visibleClassName={visibleNameClassName}
          >
            {name}
          </MiddleEllipsis>
        )}
        {inline === true ? (
          <CopyIconButton iconClassName={styles.copyIcon} text={name} title={'Copy to clipboard'} />
        ) : null}
      </span>
    ),
  }

  if (noLink === true) {
    return <span {...commonProps} />
  }

  if (to != null) {
    return <RouterLink to={to} {...commonProps} />
  }

  if (isSakuraUI === true) {
    return (
      <EntityLink
        {...commonProps}
        buildTypeId={buildTypeId}
        params={() => ({
          branch: branchNameInHref,
          buildTypeTab: stringifyId(BuildTypePageTabNamesEnum.OVERVIEW),
          mode: Modes.BUILDS,
        })}
        draggable="false"
        onClick={onClick}
      />
    )
  }

  return <Link {...commonProps} href={href} />
}

export default BranchLabel
