import Button from '@jetbrains/ring-ui/components/button/button'
import {useCallback} from 'react'

import {useSetUserProperty} from '../../../../../hooks/useSetUserProperty'
import {useBooleanUserProperty} from '../../../../../hooks/useUserProperty'
import {UserProperties} from '../../../../../utils/userProperties'
import useBooleanState from '../../hooks/useBooleanState'
import {isPipelinesPromoBannerEnabled} from '../../utils/featureToggles'

import PipelinesPromoPopup from './PipelinesPromoPopup/PipelinesPromoPopup'

import styles from './PipelinesPromoBanner.css'

function PipelinesPromoBanner() {
  const setUserProperty = useSetUserProperty()

  const isShowPopup = useBooleanUserProperty(UserProperties.SHOW_PIPELINES_PROMO_BANNER, true)
  const [isOpen, openPopup, closePopup] = useBooleanState(false)

  const dismiss = useCallback(
    () => setUserProperty(UserProperties.SHOW_PIPELINES_PROMO_BANNER, 'false'),
    [setUserProperty],
  )

  if (!isPipelinesPromoBannerEnabled || !isShowPopup) {
    return null
  }

  return (
    <>
      <div className={styles.banner}>
        <div className={styles.message}>
          {'Pipelines are coming to TeamCity, with a planned availability in 2025'}
        </div>
        <div>
          <Button className={styles.learnMoreButton} onClick={openPopup} inline>
            {'Learn more'}
          </Button>
          <Button className={styles.dismissButton} onClick={dismiss} inline>
            {'Dismiss'}
          </Button>
        </div>
      </div>
      <PipelinesPromoPopup isOpen={isOpen} onClose={closePopup} />
    </>
  )
}

export default PipelinesPromoBanner
