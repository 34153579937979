import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {getBranchLocator} from '../../../../rest/locators'
import type {Branch} from '../../../../services/rest'
import type {ProjectId} from '../../../../types'
import {makeResource} from '../../../../utils/makeResource'
import {commiterSelectQuery} from '../../../common/UserSelect/CommiterSelect.queries'

import type {ProjectChangeLogTabComponent} from './ProjectChangeLogTab'

type Params = {
  projectId: ProjectId
  branch: Branch | null | undefined
}
export const ProjectChangeLogTabEntryPoint: EntryPoint<ProjectChangeLogTabComponent, Params> = {
  root: makeResource(
    'ProjectChangeLogTab',
    () => import(/* webpackChunkName: "ProjectChangeLogTab" */ './ProjectChangeLogTab'),
  ),
  getPreloadProps: ({projectId, branch}) => {
    const branchLocator = getBranchLocator(branch, true)
    const branchAndPolicyLocator = branchLocator ? `,${branchLocator}` : ''
    const baseLocator =
      branch != null
        ? `buildType:(affectedProject:${projectId})${branchAndPolicyLocator}`
        : `project:${projectId}`
    return {
      queries: {
        commiterSelect: {
          parameters: getRequest(commiterSelectQuery),
          variables: {changeLocator: baseLocator},
        },
      },
      extraProps: {baseLocator},
    }
  },
}
