import alertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import {skipToken} from '@reduxjs/toolkit/query'
import deepEqual from 'fast-deep-equal'
import isEmpty from 'lodash/isEmpty'
import {createElement, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {shallowEqual} from 'react-redux'
import {fetchQuery, graphql, useFragment, useMutation, useRelayEnvironment} from 'react-relay'
import {useLocation, useSearchParams} from 'react-router-dom'

import {useAppDispatch, useAppSelector, useAppStore} from '../../../../hooks/react-redux'
import {useRefetchableLazyLoadQuery} from '../../../../hooks/relay/useRefetchableLazyLoadQuery'
import {mergeIfDifferent, mergeIfDifferentAndPrune} from '../../../../reducers/utils'
import {getBranchLocator} from '../../../../rest/locators'
import {type Build, restApi} from '../../../../services/rest'
import {defaultBranch} from '../../../../utils/branchNames'
import {copyClipboard} from '../../../../utils/clipboard'
import {emptyArray as frozenEmptyArray, getEmptyHash} from '../../../../utils/empty'
import {subscribeOnBuildTypeEvents, subscribeOnProjectEvents} from '../../../../utils/subscriber'
import {
  BUILD_FINISHED,
  BUILD_INTERRUPTED,
  BUILD_STARTED,
  BUILD_TYPE_ADDED_TO_QUEUE,
  BUILD_TYPE_REMOVED_FROM_QUEUE,
} from '../../../../utils/subscriptionEvents'
import {useBanners} from '../contexts/Banners/BannersContext'
import {VALIDATION_ERRROR_MESSAGE} from '../contexts/Banners/BannersContext.types'
import type {pipelineHeadFragment$key} from '../hooks/__generated__/pipelineHeadFragment.graphql'
import {useJobIdState, usePipelineHeadId, usePipelineId} from '../hooks/pipeline'
import {useVersionedSettingsStatus} from '../hooks/useVersionedSettings'
import {usePipelineDebugArg} from '../PipelineRunPage/PipelineRunPage.hooks'
import {pipelinesApi} from '../services/pipelinesApi'
import type {VersionedSettingsDto} from '../services/pipelinesApi.types'
import type {Job} from '../types'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'
import {generateFreeName} from '../utils/generateFreeName'
import type {GraphNode} from '../utils/graph'
import {createGraph} from '../utils/graph'

import type {EditPipelinePageApplySecretQuery} from './__generated__/EditPipelinePageApplySecretQuery.graphql'
import type {EditPipelinePageCancelBuildMutation} from './__generated__/EditPipelinePageCancelBuildMutation.graphql'
import type {EditPipelinePageDependencyFragment$key} from './__generated__/EditPipelinePageDependencyFragment.graphql'
import type {EditPipelinePageLastRunQuery} from './__generated__/EditPipelinePageLastRunQuery.graphql'
import {validateAllForms} from './EditPipelinePage.actions'
import {applySecretQuery} from './EditPipelinePage.queries'
import {
  getDraft,
  getDraftJobs,
  getIsDebugRunning,
  getIsPipelineValid,
  getJobName,
  getNewSettings,
  getNewYaml,
  getPipelineErrorMessage,
  getResolvedSettings,
  getStringifiedDependencies,
  getYaml,
  isDraftChanged,
  isDraftSettingsChanged,
} from './EditPipelinePage.selectors'
import {getExtractErrorMessage} from './EditPipelinePage.utils'
import PipelineAlertServiceError from './PipelineAlertServiceError/PipelineWrapperAlertMessage'
import {
  debug,
  pipelineDraft,
  pipelineDraftForm,
  pipelineErrors,
  pipelineForm,
  pipelineSecrets,
  pipelineYaml,
  pipelineYamlValidity,
} from './slices/EditPipelinePage.slices'
import {FormType} from './slices/EditPipelinePage.slices.types'

export const useJobId = (): string | null => {
  const [searchParams] = useSearchParams()
  return searchParams.get('job')
}

export const usePipelineNameOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.name)
}

export const usePipelineIntegrationsOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.integrations)
}

export const usePipelineAdditionalVcsRootsOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.additionalVcsRoots) ?? frozenEmptyArray
}

const useExistingJobs = () => {
  const id = usePipelineId()
  const {existing} = pipelinesApi.endpoints.getPipelineById.useQuery(id, {
    selectFromResult: ({data}) => ({existing: data?.settings.jobs}),
  })
  return existing
}

const emptyArray: never[] = []
export const usePipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const id = usePipelineId()
  return useAppSelector(state => Object.keys(getDraftJobs(state, id) ?? {}), shallowEqual)
}
export const useNonDeletedPipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const jobIds = usePipelineJobIdsOrDraft()
  const id = usePipelineId()
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[id]?.deleted?.jobs)

  return useMemo(() => jobIds.filter(jobId => !deleted?.includes(jobId)), [jobIds, deleted])
}

export const usePipelineExistingJobIdsSet = (): Set<string> => {
  const existing = useExistingJobs()
  return useMemo(() => new Set(existing != null ? Object.keys(existing) : emptyArray), [existing])
}

export const usePipelineExistingJob = (jobId: string): Job | undefined => {
  const existingJobs = useExistingJobs()

  return existingJobs?.[jobId]
}

export const usePipelineDraftExistingJob = (jobId: string): Job | undefined => {
  const pipelineId = usePipelineId()
  const draftExistingJobs = useAppSelector(state => getDraftJobs(state, pipelineId))

  return draftExistingJobs?.[jobId]
}

export const useDoesJobExist = (jobId: string) => {
  const existing = usePipelineExistingJob(jobId)
  return existing != null
}

export const usePipelineGraph = (stringifiedDependencies: string): Record<string, GraphNode> =>
  useMemo(() => {
    const dependencies = JSON.parse(stringifiedDependencies)
    return createGraph({
      data: Object.keys(dependencies),
      getId: id => id,
      getDependencies: id => dependencies[id],
    })
  }, [stringifiedDependencies])

export const usePipelineDraftGraph = (): Record<string, GraphNode> => {
  const id = usePipelineId()
  const stringifiedDependencies = useAppSelector(state =>
    getStringifiedDependencies(getDraftJobs(state, id)),
  )
  return usePipelineGraph(stringifiedDependencies)
}

export const useGenerateFreeJobName = () => {
  const pipelineId = usePipelineId()
  const usedNames = useAppSelector(
    state =>
      Object.entries(getDraftJobs(state, pipelineId) ?? {}).flatMap(([jobId, job]) => [
        job.name ?? jobId,
        getJobName(state, pipelineId, jobId),
      ]),
    shallowEqual,
  )

  return useCallback(
    (): string =>
      generateFreeName({
        getName: iteration => `Job ${iteration + 1}`,
        usedNames,
        errorMessage: 'Error while generating new job name',
      }),
    [usedNames],
  )
}

export const useGenerateFreeJobId = () => {
  const pipelineId = usePipelineId()
  const usedIds = useAppSelector(
    state => Object.keys(getDraftJobs(state, pipelineId) ?? {}),
    shallowEqual,
  )

  const [generateId] = restApi.endpoints.generateId.useMutation()

  return useCallback(
    async (name: string): Promise<string> => {
      const generatedId = await generateId({
        object: 'buildType',
        name,
      }).unwrap()
      return generateFreeName({
        getName: iteration => (iteration === 0 ? generatedId : `${generatedId}_${iteration + 1}`),
        usedNames: usedIds,
        errorMessage: 'Error while generating new job id',
      })
    },
    [generateId, usedIds],
  )
}

export const useGenerateCloneJobNames = ({name}: {name: string}) => {
  const pipelineId = usePipelineId()!
  const generateFreeJobId = useGenerateFreeJobId()
  const usedNames = useAppSelector(
    state =>
      Object.entries(getDraftJobs(state, pipelineId) ?? {}).flatMap(([jobId, job]) => [
        job.name ?? jobId,
        getJobName(state, pipelineId, jobId),
      ]),
    shallowEqual,
  )

  return useCallback(async () => {
    const newJobName = generateFreeName({
      getName: iteration => `${name} clone${iteration > 0 ? ` (${iteration})` : ''}`,
      usedNames,
      errorMessage: 'Error while generating cloned job name',
    })

    const newId = await generateFreeJobId(newJobName)

    return {name: newJobName, id: newId}
  }, [generateFreeJobId, name, usedNames])
}

export const useIsJobDeleted = (jobId: string) => {
  const id = usePipelineId()
  return useAppSelector(
    state => state.pipelines.pipelineDraft[id]?.deleted?.jobs?.includes(jobId) ?? false,
  )
}

function useLastRunLocator(pipelineHeadKey: pipelineHeadFragment$key | null) {
  const headId = usePipelineHeadId(pipelineHeadKey)
  const baseLocator = [
    `count:1,buildType:(id:${headId}),state:finished,personal:false`,
    getBranchLocator(defaultBranch, true),
  ]
    .filter(Boolean)
    .join(',')
  return `item(defaultFilter:false,${baseLocator}),item(${baseLocator})`
}

const dependencyFragment = graphql`
  fragment EditPipelinePageDependencyFragment on Build {
    snapshotDependencies {
      build {
        ...EditPipelineJobLastRunFragment
        ...useJobStepRunnerSummarizeErrorPreviewRunFragment
        ...JobDirectoryPopupLastRunFragment
        buildType {
          name
        }
        canceledInfo {
          timestamp
        }
      }
    }
  }
`
const useDependency = (buildKey: EditPipelinePageDependencyFragment$key | null, dep: string) => {
  const build = useFragment(dependencyFragment, buildKey)
  return build?.snapshotDependencies?.build?.find(item => item.buildType?.name === dep) ?? null
}

const lastRunQuery = graphql`
  query EditPipelinePageLastRunQuery($locator: String!) {
    builds(locator: $locator) {
      build {
        ...EditPipelinePageDependencyFragment
      }
    }
  }
`
export function useLastRun(id: string, pipelineHeadKey: pipelineHeadFragment$key | null) {
  const locator = useLastRunLocator(pipelineHeadKey)
  const [{builds}, refetch] = useRefetchableLazyLoadQuery<EditPipelinePageLastRunQuery>(
    lastRunQuery,
    {locator},
  )
  const pipelineId = usePipelineId()
  const name = useAppSelector(state => getJobName(state, pipelineId, id))
  const lastRun = useDependency(builds?.build?.[0] ?? null, name)
  const lastFinishedRun = useDependency(builds?.build?.[1] ?? null, name)

  useEffect(
    () =>
      pipelineId != null
        ? subscribeOnProjectEvents(
            pipelineId,
            [
              BUILD_TYPE_ADDED_TO_QUEUE,
              BUILD_TYPE_REMOVED_FROM_QUEUE,
              BUILD_STARTED,
              BUILD_FINISHED,
              BUILD_INTERRUPTED,
            ],
            refetch,
          )
        : undefined,
    [pipelineId, refetch],
  )

  if (lastRun == null || lastRun.canceledInfo != null) {
    return lastFinishedRun
  }
  return lastRun
}
export const useIsSettingsChanged = () => {
  const pipelineId = usePipelineId()
  const hasChangesDraft = useAppSelector(state =>
    isDraftSettingsChanged(state.pipelines.pipelineDraft[pipelineId]),
  )
  const hasChangesOriginal = useAppSelector(state => {
    const updated = getResolvedSettings(state, pipelineId)
    const {jobs, ...restUpdatedSettings} = updated?.settings ?? {}
    const updatedPipeline = {...updated, settings: restUpdatedSettings}
    const original = state.pipelines.pipelineDraft[pipelineId]?.original
    const {jobs: originalJobs, ...restOriginalSettings} = original?.settings ?? {}
    const originalPipeline = {...original, settings: restOriginalSettings}
    return (
      (updated != null && !deepEqual(updatedPipeline, originalPipeline)) ||
      !isEmpty(state.pipelines.pipelineSecrets[pipelineId])
    )
  })
  return hasChangesDraft || hasChangesOriginal
}
export const useEditPipeline = () => {
  const dispatch = useAppDispatch()
  const pipelineId = usePipelineId()
  const [jobId, setJobId] = useJobIdState()
  const hasChangesDraft = useAppSelector(state =>
    isDraftChanged(state.pipelines.pipelineDraft[pipelineId]),
  )
  const hasChangesOriginal = useAppSelector(state => {
    const updated = getResolvedSettings(state, pipelineId)
    const original = state.pipelines.pipelineDraft[pipelineId]?.original
    return (
      (updated != null && !deepEqual(updated, original)) ||
      !isEmpty(state.pipelines.pipelineSecrets[pipelineId])
    )
  })
  const hasChanges = hasChangesDraft || hasChangesOriginal
  const [updatePipeline, {isLoading}] = pipelinesApi.endpoints.updatePipeline.useMutation({
    fixedCacheKey: 'shared-save-pipeline',
  })

  const {getState} = useAppStore()
  const applySecrets = useApplySecrets()
  const handlerSaveButtonClick = async () => {
    await applySecrets()
    const isValid = dispatch(validateAllForms(pipelineId))

    if (!isValid) {
      return false
    }

    const state = getState()
    const updated = getResolvedSettings(state, pipelineId)

    if (hasChangesDraft && !hasChangesOriginal) {
      dispatch(pipelineDraft.actions.reset(pipelineId))

      const pipelineOriginal = state.pipelines.pipelineDraft[pipelineId]?.original

      if (jobId != null && pipelineOriginal?.settings.jobs?.[jobId] == null) {
        setJobId(null)
      }
    } else if (updated != null) {
      dispatch(pipelineDraftForm.actions.submit())
      const result = await updatePipeline({id: pipelineId, body: updated})
      if ('data' in result && result.data != null) {
        if (jobId != null && result.data.settings.jobs?.[jobId] == null) {
          setJobId(null)
        }
        dispatch(pipelineForm.actions.closeAll(pipelineId))
        dispatch(pipelineSecrets.actions.unsetAll(pipelineId))
        dispatch(pipelineErrors.actions.clear({pipelineId}))
        dispatch(pipelineDraft.actions.set(result.data))
      } else if ('error' in result && result.error != null) {
        const errorMessage = getExtractErrorMessage(result.error)

        alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
      }
    }

    return true
  }

  return {
    hasChanges,
    isLoading,
    handlerSaveButtonClick,
  }
}
const ALERT_TIMEOUT = 2000

export function useApplyYAMLChangesHandler() {
  const pipelineId = usePipelineId()
  const dispatch = useAppDispatch()
  const {getState} = useAppStore()
  return () => {
    const state = getState()
    const newSettings = getNewSettings(state, pipelineId)
    const pipelineDraftState = getDraft(state, pipelineId)

    if (pipelineDraftState !== undefined) {
      dispatch(pipelineSecrets.actions.unsetAll(pipelineId))
      dispatch(
        pipelineDraft.actions.setDraft({
          id: pipelineId,
          draft: {
            ...pipelineDraftState,
            settings: mergeIfDifferentAndPrune(pipelineDraftState.settings, newSettings),
          },
        }),
      )
    }
  }
}

export function useIsEditPipelineYAMLValid() {
  const pipelineId = usePipelineId()
  return useAppSelector(state => getIsPipelineValid(state, {pipelineId}))
}

export function useSaveYAMLChangesHandler(): [
  () => Promise<boolean>,
  ReturnType<typeof pipelinesApi.endpoints.updatePipeline.useMutation>[1],
] {
  const dispatch = useAppDispatch()
  const [updatePipeline, updatePipelineResult] = pipelinesApi.endpoints.updatePipeline.useMutation({
    fixedCacheKey: 'shared-save-yaml',
  })
  const pipelineId = usePipelineId()
  const {getState} = useAppStore()
  return [
    async () => {
      const state = getState()
      const newSettings = getNewSettings(state, pipelineId)
      const pipelineDraftState = getDraft(state, pipelineId)

      if (pipelineDraftState !== undefined) {
        dispatch(pipelineDraftForm.actions.submit())
        const result = await updatePipeline({
          id: pipelineId,
          body: {
            ...pipelineDraftState,
            settings: mergeIfDifferent(pipelineDraftState.settings, newSettings),
          },
        })
        if ('data' in result && result.data != null) {
          dispatch(pipelineDraft.actions.set(result.data))
        } else if ('error' in result && result.error != null) {
          const errorMessage = getExtractErrorMessage(result.error)
          alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
        }

        return true
      }
      return false
    },
    updatePipelineResult,
  ]
}

export function useYAMLHasChanges() {
  const pipelineId = usePipelineId()
  return useAppSelector(
    state => getYaml(state, pipelineId)?.trim() !== getNewYaml(state, pipelineId)?.trim(),
  )
}

export function useEditPipelineYAML() {
  const dispatch = useAppDispatch()
  const pipelineId = usePipelineId()
  const yaml = useAppSelector(state => getYaml(state, pipelineId))
  const hasChanges = useYAMLHasChanges()
  const isReadonly = useIsDebugRunning()

  const applyChangesHandler = useApplyYAMLChangesHandler()
  const {getState} = useAppStore()
  const {removeBanner} = useBanners()

  const copyToClipboardHandler = () =>
    copyClipboard(getNewYaml(getState(), pipelineId)).then(() =>
      alertService.message('Copied to clipboard', ALERT_TIMEOUT),
    )

  const yamlEditorChangeHandler = useCallback(
    (value: string) => dispatch(pipelineYaml.actions.setYaml({id: pipelineId, yaml: value})),
    [dispatch, pipelineId],
  )

  const yamlEditorValidateHandler = (isPipelineValid: boolean) => {
    if (isPipelineValid && hasChanges) {
      applyChangesHandler()
    }
  }
  useEffect(() => {
    if (isStoreYamlInVcsEnabled && hasChanges) {
      dispatch(pipelineYamlValidity.actions.setParsed({id: pipelineId, value: true}))
      removeBanner(pipelineId, `${pipelineId}-${VALIDATION_ERRROR_MESSAGE}`)
    }
  }, [dispatch, hasChanges, pipelineId, removeBanner])

  return {
    yaml,
    hasChanges,
    isReadonly,
    yamlEditorChangeHandler,
    yamlEditorValidateHandler,
    copyToClipboardHandler,
  }
}

export const useDraftStepError = (props: {stepIndex: number; name?: string}) => {
  const {stepIndex, name} = props
  const jobId = useJobId()!
  const pipelineId = usePipelineId()
  return useAppSelector(
    getPipelineErrorMessage({
      pipelineId,
      jobId,
      formType: FormType.STEP,
      formId: stepIndex,
      fieldName: name,
    }),
  )
}

export const useHasNonDeletedDependants = (jobId: string) => {
  const pipelineId = usePipelineId()

  const graph = usePipelineDraftGraph()
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.deleted?.jobs)
  return (
    graph[jobId] != null &&
    Array.from(graph[jobId].successors).some(successorId => !deleted?.includes(successorId))
  )
}

export const useConfigurationStorage = () => {
  const id = usePipelineId()
  const dispatch = useAppDispatch()
  const [jobId, setJobId] = useJobIdState()

  const location = useLocation()
  const isCreated = location?.state?.isCreated ?? false

  const pipelineDraftState = useAppSelector(state => getDraft(state, id))
  const vcsRoot = pipelineDraftState?.vcsRoot

  const [dialogVisible, setDialogVisible] = useState(false)

  const [checkVersionedSettings, {hasFileInRepo}] =
    pipelinesApi.endpoints.checkVersionedSettings.useLazyQuery({
      selectFromResult: ({data}) => ({hasFileInRepo: data ?? false}),
    })

  useVersionedSettingsStatus()
  const [updatePipeline, {isLoading, error}] = pipelinesApi.endpoints.updatePipeline.useMutation()

  const onConfigurationStorageChange = useCallback(
    async (settings: VersionedSettingsDto) => {
      if (pipelineDraftState) {
        dispatch(pipelineDraft.actions.setVersionedSettings({id, storedInRepo: true}))
        const result = await updatePipeline({
          id,
          body: {
            ...pipelineDraftState,
            versionedSettings: settings,
          },
        })
        if ('data' in result && result.data != null) {
          if (jobId != null && result.data.settings.jobs?.[jobId] == null) {
            setJobId(null)
          }
          dispatch(pipelineDraft.actions.set(result.data))
        }
        setDialogVisible(false)
        history.replaceState({}, '')
      }
    },
    [dispatch, id, jobId, pipelineDraftState, setJobId, updatePipeline],
  )

  useEffect(() => {
    if (error != null) {
      const errorMessage = getExtractErrorMessage(error)
      alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
    }
  }, [error])

  useEffect(() => {
    if (isCreated && vcsRoot?.url) {
      checkVersionedSettings({vcsRoot})
    }
  }, [checkVersionedSettings, isCreated, vcsRoot])

  useEffect(() => {
    if (isCreated) {
      setDialogVisible(hasFileInRepo)
    }
  }, [hasFileInRepo, isCreated])

  return {
    dialogVisible,
    isLoading,
    onConfigurationStorageChange,
  }
}

export function useApplySecrets() {
  const pipelineId = usePipelineId()
  const environment = useRelayEnvironment()
  const dispatch = useAppDispatch()
  const secrets = useAppSelector(
    state => state.pipelines.pipelineSecrets[pipelineId] ?? getEmptyHash(),
  )
  // needed to avoid memoization reset on secret change
  const secretsRef = useRef<Record<string, string>>({})
  useEffect(() => {
    secretsRef.current = secrets
  }, [secrets])
  return useCallback(
    (filter: (savedName: string) => boolean = () => true) =>
      Promise.all(
        Object.entries(secretsRef.current)
          .filter(([savedName]) => filter(savedName))
          .map(async ([savedName, value]) => {
            let newValue
            if (value) {
              const {addSecureToken} =
                (await fetchQuery<EditPipelinePageApplySecretQuery>(
                  environment,
                  applySecretQuery,
                  {
                    projectLocator: `internalId:${pipelineId}`,
                    input: value,
                  },
                  {fetchPolicy: 'store-or-network'},
                ).toPromise()) ?? {}
              if (addSecureToken == null) {
                return
              } else {
                newValue = addSecureToken
              }
            } else {
              newValue = value
            }
            dispatch(
              pipelineDraft.actions.updateSecret({id: pipelineId, savedName, value: newValue}),
            )
          }),
      ),
    [dispatch, environment, pipelineId],
  )
}

function usePredecessors(jobId?: string | null) {
  const graph = usePipelineDraftGraph()
  if (!jobId) {
    return new Set()
  }

  const {predecessors} = graph[jobId] ?? {
    predecessors: new Set([jobId]),
  }
  return new Set([...predecessors, jobId])
}

export function useIsDebugRunning() {
  const pipelineId = usePipelineId()
  const runId = useAppSelector(state => state.pipelines.debug[pipelineId]?.runId)
  const isRunning = useAppSelector(state => getIsDebugRunning(state, pipelineId) ?? false)
  return !!runId && isRunning
}

export const useDebugJobIdMap = () => {
  const arg = usePipelineDebugArg()
  const pipelineId = usePipelineId()
  const pipelineRunId = useAppSelector(state => state.pipelines.debug[pipelineId]?.runId)
  const debugJobId = useAppSelector(state => state.pipelines.debug[pipelineId]?.jobId)
  const predecessors = usePredecessors(debugJobId)
  const {jobs, headInternalId, state, refetch} = restApi.endpoints.getBuildNormalized.useQuery(
    pipelineRunId ? arg : skipToken,
    {
      selectFromResult: ({data}) => {
        const run = data?.entities?.builds?.[data?.result!]
        const headId = run?.buildType
        const state = run?.state
        const dependencies = run?.['snapshot-dependencies']?.build
        return {
          jobs: dependencies ?? (emptyArray as Build[]),
          headInternalId: data?.entities?.buildTypes?.[headId!]?.internalId,
          state,
        }
      },
    },
  )

  const [firstJob] = jobs
  const childInternalId = firstJob?.buildType?.project?.internalId
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (state === 'finished') {
      dispatch(debug.actions.finish(pipelineId))
    }
  }, [dispatch, pipelineId, state])

  useEffect(
    () =>
      childInternalId && pipelineRunId
        ? subscribeOnProjectEvents(
            childInternalId,
            [BUILD_TYPE_REMOVED_FROM_QUEUE, BUILD_STARTED, BUILD_FINISHED, BUILD_INTERRUPTED],
            refetch,
          )
        : undefined,
    [childInternalId, refetch, pipelineRunId],
  )

  useEffect(
    () =>
      headInternalId && pipelineRunId
        ? subscribeOnBuildTypeEvents(
            headInternalId,
            [BUILD_TYPE_REMOVED_FROM_QUEUE, BUILD_STARTED, BUILD_FINISHED, BUILD_INTERRUPTED],
            refetch,
          )
        : undefined,
    [headInternalId, refetch, pipelineRunId],
  )

  return useMemo(
    () =>
      jobs.reduce<Map<string, number>>((acc, {id, attributes}) => {
        const jobIdValue = attributes?.entry?.find(
          entry => entry?.name === 'teamcity.pipeline.job.id',
        )?.value

        if (!jobIdValue || !id || !predecessors.has(jobIdValue)) {
          return acc
        }

        acc.set(jobIdValue, id)

        return acc
      }, new Map()),
    [jobs, predecessors],
  )
}

const cancelBuildMutation = graphql`
  mutation EditPipelinePageCancelBuildMutation($buildLocator: String!) {
    cancelBuild(buildLocator: $buildLocator, input: {}) {
      id
    }
  }
`

export const getDebugArgs = (pipelineId: string, id: string, yaml: string) => ({
  pipelineId,
  body: {
    debug: {
      [id]: {breakpoints: []},
    },
    yaml,
  },
  fields: 'id,state,head(buildTypeInternalId),jobs(job(build(id,state,buildType(name))))',
})

export const useDebugJobActions = (id?: string) => {
  const pipelineId = usePipelineId()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const yaml = useAppSelector(state => getYaml(state, pipelineId))
  const debugYaml = useAppSelector(state => state.pipelines.debug[pipelineId]?.yaml)
  const debugRunId = useAppSelector(state => state.pipelines.debug[pipelineId]?.runId)

  useEffect(() => {
    if (debugYaml && yaml !== debugYaml) {
      dispatch(debug.actions.stop(pipelineId))
      alertService.message('The debug session has been stopped', ALERT_TIMEOUT)
    }
  }, [debugYaml, dispatch, pipelineId, yaml])

  const [runDebug] = pipelinesApi.endpoints.runPipelineDebug.useLazyQuery()
  const [cancelBuild] = useMutation<EditPipelinePageCancelBuildMutation>(cancelBuildMutation)

  const onDebug = useCallback(() => {
    if (!id) {
      return
    }
    searchParams.delete('step')
    const searchParamsObject = Object.fromEntries(searchParams.entries())
    setSearchParams(searchParamsObject)

    dispatch(debug.actions.start({pipelineId, jobId: id}))
    runDebug(getDebugArgs(pipelineId, id, yaml))
  }, [dispatch, id, pipelineId, runDebug, searchParams, setSearchParams, yaml])

  const onStopDebug = useCallback(() => {
    if (!debugRunId) {
      return
    }
    dispatch(debug.actions.stop(pipelineId))
    cancelBuild({variables: {buildLocator: `id:${debugRunId}`}})
  }, [cancelBuild, debugRunId, dispatch, pipelineId])

  return {
    onDebug,
    onStopDebug,
  }
}
