import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {BuildTypeId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'

import type {TagsFiltersContainerComponent} from './TagsFilters'
import {buildTypeTagsQuery} from './TagsList.queries'

export const TagsFiltersEntryPoint: EntryPoint<TagsFiltersContainerComponent, BuildTypeId> = {
  root: makeResource(
    'TagsFilters',
    () => import(/* webpackChunkName: "TagsFilters" */ './TagsFilters'),
  ),
  getPreloadProps: id => ({
    queries: {
      buildTypeTags: {
        parameters: getRequest(buildTypeTagsQuery),
        variables: {btLocator: `id:${id}`, skip: false},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
