import type {EntryPoint} from 'react-relay'

import {makeResource} from '../../../../../utils/makeResource'

import type {EditPipelineBreadcrumbsComponent} from './EditPipelineBreadcrumbs'

const EditPipelineBreadcrumbsEntryPoint: EntryPoint<EditPipelineBreadcrumbsComponent> = {
  root: makeResource(
    'EditPipelineBreadcrumbs',
    () =>
      import(
        /* webpackChunkName: "EditPipelineBreadcrumbs" */
        './EditPipelineBreadcrumbs'
      ),
  ),
  getPreloadProps: () => ({}),
}
export default EditPipelineBreadcrumbsEntryPoint
