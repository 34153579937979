import {ControlsHeight} from '@jetbrains/ring-ui/components/global/controls-height'
import type {IconType} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'

import {useMeasureSelector} from '../../../hooks/useMeasure'
import SvgIcon from '../../../library/components/SvgIcon/SvgIcon'
import type {RouterButtonProps} from '../RouterLink/RouterLink'
import {RouterButton} from '../RouterLink/RouterLink'

import styles from './ToggleLink.css'

declare module 'csstype' {
  interface Properties {
    '--tc-toggle-link-text-width'?: string
  }
}

type Props = RouterButtonProps & {
  icon: IconType | string
  label: string
  checked: boolean
  disabled?: boolean
  switcherClassName?: string
  animated?: boolean
}

export default function ToggleLink({
  icon,
  label,
  checked,
  disabled,
  className,
  switcherClassName,
  animated = true,
  ...props
}: Props) {
  const {measureRef, result: textWidth} = useMeasureSelector(
    {client: true},
    ({client}) => client?.width,
    0,
  )

  const switcher = (
    <SvgIcon
      icon={icon}
      className={classNames(styles.switcher, switcherClassName, {[styles.checked]: checked})}
    />
  )
  const labelElement = (
    <span ref={animated ? measureRef : null} className={styles.label}>
      {label}
    </span>
  )

  return (
    <RouterButton
      className={classNames(styles.toggle, className, {
        [styles.disabled]: disabled,
        [styles.checked]: checked,
        [styles.animated]: animated,
      })}
      primary={checked}
      height={ControlsHeight.M}
      aria-label={label}
      disabled={disabled}
      {...props}
    >
      <span
        className={styles.content}
        style={animated ? {'--tc-toggle-link-text-width': `${textWidth}px`} : undefined}
      >
        {checked && !animated ? (
          <>
            {labelElement}
            {switcher}
          </>
        ) : (
          <>
            {switcher}
            {labelElement}
          </>
        )}
      </span>
    </RouterButton>
  )
}
