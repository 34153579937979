import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {branchExistsInBuildTypeQueryDefinition} from '../../../../../queries/branchExistsInBuildType'
import {getBuildTypeHasNonDefaultBranchesVariables} from '../../../../../rest/branches'
import {stringifyBranch} from '../../../../../utils/branchNames'
import {makeResource} from '../../../../../utils/makeResource'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../../../common/Permalinks/Permalinks.queries'
import {TagsFiltersEntryPoint} from '../../../../common/TagsList/TagsFilters.entryPoint'

import type {BuildTypeHistoryQueryParams} from './BuildTypeHistory.queries'
import {buildTypeHistoryQuery, getBuildTypeHistoryQueryVariables} from './BuildTypeHistory.queries'
import type {BuildTypeHistoryComponent} from './BuildTypeHistory.standalone'

export const BuildTypeHistoryEntryPoint: EntryPoint<
  BuildTypeHistoryComponent,
  BuildTypeHistoryQueryParams
> = {
  root: makeResource(
    'BuildTypeHistory',
    () => import(/* webpackChunkName: "BuildTypeHistory" */ './BuildTypeHistory.standalone'),
  ),
  getPreloadProps: params => {
    const {buildTypeId, branch} = params
    return {
      queries: {
        main: {
          parameters: getRequest(buildTypeHistoryQuery),
          variables: getBuildTypeHistoryQueryVariables(params),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
        permalinks: {
          parameters: getRequest(permalinksQuery),
          variables: getPermalinksQueryVariables(buildTypeId, stringifyBranch(branch)),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
        withBranches: {
          parameters: getRequest(branchExistsInBuildTypeQueryDefinition),
          variables: getBuildTypeHasNonDefaultBranchesVariables(buildTypeId),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints: {
        tagsFilters: {
          entryPoint: TagsFiltersEntryPoint,
          entryPointParams: buildTypeId,
        },
      },
    }
  },
}
