import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {getBuildOrPermalinkLocator} from '../../pages/BuildPage/BuildPage.utils'

import type {OverviewBreadcrumbsComponent} from './OverviewBreadcrumbs'
import {query} from './OverviewBreadcrumbs.queries'

export const OverviewBreadcrumbsEntryPoint: EntryPoint<
  OverviewBreadcrumbsComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'OverviewBreadcrumbs',
    () =>
      import(
        /* webpackChunkName: "OverviewBreadcrumbs" */
        './OverviewBreadcrumbs'
      ),
  ),
  getPreloadProps({params, request}) {
    const {projectId, buildTypeId, buildId} = params
    const url = new URL(request.url)
    const {branch} = queryToObject(url.search)

    return {
      queries: {
        main: {
          parameters: getRequest(query),
          variables: {
            projectLocator: `id:${projectId}`,
            buildTypeLocator: `id:${buildTypeId}`,
            buildLocator: getBuildOrPermalinkLocator(buildId, buildTypeId, parseBranch(branch)),
            fetchProject: projectId != null,
            fetchBuildType: buildTypeId != null,
            fetchBuild: buildId != null,
          },
        },
      },
    }
  },
}
