import {createContext} from 'react'

type ProjectsTreeContextValue = {
  isPopup: boolean
  isAdmin: boolean
  isMultiselect?: boolean
  isAction?: boolean
  buildTypeUrlFormat?: string | null | undefined
  templateUrlFormat?: string | null | undefined
  pipelineUrlFormat?: string | null | undefined
  projectUrlFormat?: string | null | undefined
  currentId?: string
  currentNodeType?: 'project' | 'bt'
}
export const ProjectsTreeContext = createContext<ProjectsTreeContextValue>({
  isPopup: false,
  isAdmin: false,
  buildTypeUrlFormat: null,
  projectUrlFormat: null,
})
