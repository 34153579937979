import type {AlertType} from '@jetbrains/ring-ui/components/alert/alert'
import Alert from '@jetbrains/ring-ui/components/alert/alert'
import type {AlertItem} from '@jetbrains/ring-ui/components/alert-service/alert-service'
import AlertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import {
  ControlsHeight,
  ControlsHeightContext,
} from '@jetbrains/ring-ui/components/global/controls-height'
import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import {useContext} from 'react'
import * as React from 'react'
import {Provider} from 'react-redux'
import {RelayEnvironmentProvider} from 'react-relay'
import {UNSAFE_LocationContext, UNSAFE_NavigationContext} from 'react-router-dom'

import {CurrentUserKeyContext} from '../../../contexts/currentUserKey'
import SvgIcon from '../../../library/components/SvgIcon/SvgIcon'
import getEnvironment from '../../../relay/getEnvironment'

import styles from './ServiceMessage.css'

export type ServiceMessageType = Partial<AlertItem> & {
  icon?: Icon | string
  description?: string
  children?: React.ReactNode
  controls?: React.ReactNode
  titleClassName?: string
  containerClassName?: string
  closeButtonClassName?: string
  captionClassName?: string
  descriptionClassName?: string
  iconClassName?: string
  title?: React.ReactNode
  closeable?: boolean
  onClose?: () => void
  onCloseRequest?: () => void
  type?: AlertType
}

function ServiceMessage({
  icon,
  captionClassName,
  descriptionClassName,
  containerClassName,
  closeButtonClassName,
  iconClassName,
  titleClassName,
  title,
  controls,
  children,
  closeable,
  onCloseRequest,
  onClose,
  type,
  ...restProps
}: ServiceMessageType) {
  const keyRef = React.useRef<string | number | null>()

  const innerContent = React.useMemo(
    () => (
      <div className={styles.container}>
        {icon != null && (
          <div>
            <SvgIcon className={classNames(styles.icon, iconClassName)} icon={icon} />
          </div>
        )}
        <div>
          {title != null && <h3 className={classNames(styles.title, titleClassName)}>{title}</h3>}
          {children != null && (
            <div className={classNames(styles.description, descriptionClassName)}>{children}</div>
          )}
          {controls}
        </div>
      </div>
    ),
    [children, controls, descriptionClassName, icon, iconClassName, title, titleClassName],
  )

  const locationContext = useContext(UNSAFE_LocationContext)
  const navigationContext = useContext(UNSAFE_NavigationContext)
  const userKey = useContext(CurrentUserKeyContext)

  const content = React.useMemo(
    () => (
      <CurrentUserKeyContext.Provider value={userKey}>
        <Provider store={window.ReactUI.store}>
          <RelayEnvironmentProvider environment={getEnvironment()}>
            <ControlsHeightContext.Provider value={ControlsHeight.S}>
              {/* eslint-disable react/jsx-pascal-case */}
              <UNSAFE_LocationContext.Provider value={locationContext}>
                <UNSAFE_NavigationContext.Provider value={navigationContext}>
                  {/* eslint-enable */}
                  {innerContent}
                </UNSAFE_NavigationContext.Provider>
              </UNSAFE_LocationContext.Provider>
            </ControlsHeightContext.Provider>
          </RelayEnvironmentProvider>
        </Provider>
      </CurrentUserKeyContext.Provider>
    ),
    [innerContent, locationContext, navigationContext, userKey],
  )

  const options = React.useMemo(
    () => ({
      captionClassName: classNames(styles.caption, captionClassName),
      className: containerClassName,
      closeButtonClassName,
      closeable,
      onCloseRequest,
      onClose,
      ...restProps,
    }),
    [
      captionClassName,
      closeButtonClassName,
      closeable,
      containerClassName,
      onClose,
      onCloseRequest,
      restProps,
    ],
  )

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      if (keyRef.current != null) {
        const existingAlert = AlertService.showingAlerts.find(alert => alert.key === keyRef.current)
        if (existingAlert != null) {
          existingAlert.isClosing = false
          existingAlert.message = content
          existingAlert.theme = options.theme
          AlertService.renderAlerts()
          return
        }
      }
      keyRef.current = AlertService.addAlert(content, type, 0, options)
    }
  }, [
    captionClassName,
    closeButtonClassName,
    closeable,
    containerClassName,
    content,
    onClose,
    onCloseRequest,
    options,
    restProps,
    type,
  ])

  React.useEffect(
    () => () => {
      if (keyRef.current) {
        AlertService.remove(keyRef.current)
      }
    },
    [],
  )

  return process.env.NODE_ENV === 'test' ? (
    <Alert {...options} type={type}>
      {innerContent}
    </Alert>
  ) : null
}

export default React.memo(ServiceMessage)
