import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../../utils/makeResource'

import {cloudImagesQuery} from './JobAgentRequirements/JobAgentRequirements.queries'
import type {JobSettingsSidebarComponent} from './JobSettingsSidebar'

export const JobSettingsSidebarEntryPoint: EntryPoint<JobSettingsSidebarComponent> = {
  root: makeResource(
    'JobSettingsSidebar',
    () =>
      import(
        /* webpackChunkName: "JobSettingsSidebar" */
        './JobSettingsSidebar'
      ),
  ),
  getPreloadProps: () => ({
    // TODO load only if the tab selected
    queries: {cloudImages: {parameters: getRequest(cloudImagesQuery), variables: {}}},
  }),
}
