import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {branchExistsInBuildTypeQueryDefinition} from '../../../queries/branchExistsInBuildType'
import {getBuildTypeHasNonDefaultBranchesVariables} from '../../../rest/branches'
import type {BuildTypeId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../common/Permalinks/Permalinks.queries'
import {TagsFiltersEntryPoint} from '../../common/TagsList/TagsFilters.entryPoint'

import type {BuildTypeOverviewComponent} from './BuildTypeOverview'

type BuildTypeOverviewEntryPointParams = {
  buildTypeId: BuildTypeId
  branch?: string
}

export const BuildTypeOverviewEntryPoint: EntryPoint<
  BuildTypeOverviewComponent,
  BuildTypeOverviewEntryPointParams
> = {
  root: makeResource(
    'BuildTypeOverview',
    () => import(/* webpackChunkName: "BuildTypeOverview" */ './BuildTypeOverview'),
  ),
  getPreloadProps: ({buildTypeId, branch}) => ({
    queries: {
      permalinks: {
        parameters: getRequest(permalinksQuery),
        variables: getPermalinksQueryVariables(buildTypeId, branch),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      hasNonDefaultBranches: {
        parameters: getRequest(branchExistsInBuildTypeQueryDefinition),
        variables: getBuildTypeHasNonDefaultBranchesVariables(buildTypeId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
    entryPoints: {
      tagsFilters: {
        entryPoint: TagsFiltersEntryPoint,
        entryPointParams: buildTypeId,
      },
    },
  }),
}
