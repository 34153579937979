import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../utils/makeResource'
import {getAgentsPagesQueries} from '../../pages/AgentsPages/AgentsPages.queries'

import type {AgentDomainBreadcrumbsComponent} from './AgentDomainBreadcrumbs'
import {agentQuery} from './AgentDomainBreadcrumbs.queries'

export const AgentDomainBreadcrumbsEntryPoint: EntryPoint<
  AgentDomainBreadcrumbsComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'AgentDomainBreadcrumbs',
    () =>
      import(
        /* webpackChunkName: "AgentDomainBreadcrumbs" */
        './AgentDomainBreadcrumbs'
      ),
  ),
  getPreloadProps: ({params}) => {
    const {agentId} = params
    return {
      queries: {
        agent: {
          parameters: getRequest(agentQuery),
          variables: {
            agentLocator: `id:${agentId}`,
            fetchAgent: agentId != null,
          },
        },
        ...getAgentsPagesQueries(),
      },
    }
  },
}
