import type {
  ButtonButtonProps,
  ButtonLinkProps,
  ContainerProps,
} from '@jetbrains/ring-ui/components/button/button'
import Button from '@jetbrains/ring-ui/components/button/button'
import type {ClickableLinkProps} from '@jetbrains/ring-ui/components/link/clickableLink'
import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import Link from '@jetbrains/ring-ui/components/link/link'
import type {
  RouterLinkProps as RouterLinkAPIProps,
  RouterButtonProps as RouterButtonAPIProps,
} from '@jetbrains/teamcity-api'
import classNames from 'classnames'
import type {To} from 'react-router'
import {useHref, useLinkClickHandler, useLocation} from 'react-router-dom'

import {getHrefWithQueryParams} from '../../../routes'
import type {QueryParams} from '../../../utils/queryParams'

import styles from './RouterLink.css'

type RouterLinkInnerProps = ClickableLinkProps & {
  to?: string | To
  replace?: boolean
  params?: QueryParams | ((prevParams: QueryParams) => QueryParams)
  hash?: string
  activeClassName?: string | null
  withMergeParams?: boolean
}
export type RouterLinkProps = LinkProps<RouterLinkInnerProps>

const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i

export function useLinkProps({
  to,
  params,
  hash,
  withMergeParams,
  replace,
  onClick,
}: RouterLinkInnerProps) {
  const location = useLocation()
  const paramsToPass =
    withMergeParams === true ? (prevParams: QueryParams) => ({...prevParams, ...params}) : params
  const resolvedTo =
    (paramsToPass || hash != null) && (typeof to === 'string' || to == null)
      ? getHrefWithQueryParams(location, to, paramsToPass, hash)
      : (to ?? location)

  const href = useHref(resolvedTo)
  const clickHandler = useLinkClickHandler(resolvedTo, {replace})
  return typeof resolvedTo === 'string' && ABSOLUTE_URL_REGEX.test(resolvedTo)
    ? {href: resolvedTo}
    : {
        href,
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          onClick?.(e)
          if (!e.defaultPrevented) {
            clickHandler(e)
          }
        },
      }
}

function RouterLink(props: RouterLinkProps) {
  const {to, replace, params, hash, withMergeParams, onClick, ...restProps} = props
  const linkProps = useLinkProps(props)
  return <Link {...restProps} {...linkProps} />
}
export default RouterLink

export function RouterLinkAPI({to, children, ...restProps}: RouterLinkAPIProps) {
  const href = useHref(to)
  const onClick = useLinkClickHandler(to)
  return (
    <Link {...restProps} href={href} onClick={onClick}>
      {children}
    </Link>
  )
}

export type RouterButtonProps = RouterLinkInnerProps & Omit<ContainerProps<ButtonLinkProps>, 'href'>
export function RouterButton(props: RouterButtonProps) {
  const {
    to,
    replace,
    params,
    hash,
    withMergeParams,
    onClick,
    activeClassName,
    className,
    disabled,
    ...restProps
  } = props
  const classes = classNames(className, {[styles.disabled]: disabled})
  const linkProps = useLinkProps(props)

  return disabled ? (
    <Button {...(restProps as ContainerProps<ButtonButtonProps>)} disabled className={classes} />
  ) : (
    <Button {...restProps} {...linkProps} className={classes} />
  )
}

export function RouterButtonAPI({to, children, ...restProps}: RouterButtonAPIProps) {
  const href = useHref(to)
  const onClick = useLinkClickHandler(to)
  return (
    <Button {...restProps} href={href} onClick={onClick}>
      {children}
    </Button>
  )
}
