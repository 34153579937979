import {graphql} from 'react-relay'

export const query = graphql`
  query AgentTypePageQuery($agentTypeLocator: String!, $cloudImagesLocator: String!) {
    ...AgentsPagesCanViewAgentDetailsFragment
    agentType(agentTypeLocator: $agentTypeLocator) {
      ...AgentTypePage_agentType
    }
    cloudImages(locator: $cloudImagesLocator) {
      ...AgentTypePage_cloudImages
    }
  }
`
