import EmptyStarIcon from '@jetbrains/icons/star-empty.svg'
import StarIcon from '@jetbrains/icons/star-filled.svg'
import classnames from 'classnames'

import {usePermission} from '../../../hooks/usePermission'
import IconButton from '../../../library/components/IconButton/IconButton'
import type {BuildId, ProjectId} from '../../../types'
import {Permission, ROOT_PROJECT_ID} from '../../../types'
import {noop} from '../../../utils/empty'

import styles from './StarBuild.css'

type Props = {
  buildId?: BuildId
  projectId?: ProjectId
  className?: string
  isStarred: boolean
  showEmpty?: boolean
  onStar?: (starred: boolean) => unknown
}

function StarBuild({projectId, className, isStarred, showEmpty = false, onStar = noop}: Props) {
  const canTag = usePermission(Permission.TAG_BUILD, projectId)
  const canChangeOwmProfile = usePermission(Permission.CHANGE_OWN_PROFILE, ROOT_PROJECT_ID)
  const isStarrable = canTag && canChangeOwmProfile

  const getTitle: () => string = () => (isStarred ? 'Remove from favorites' : 'Add to favorites')

  const handleStar: () => unknown = () => onStar(!isStarred)

  const invisibleClasses = classnames({
    [styles.invisible]: !showEmpty && !isStarred,
    [styles.hidden]: !isStarrable && !isStarred,
  })
  const classes = classnames(styles.button, className, invisibleClasses, {
    [styles.starred]: isStarred,
  })

  return (
    <IconButton
      title={getTitle()}
      disabled={!isStarrable}
      icon={isStarred ? StarIcon : EmptyStarIcon}
      onClick={handleStar}
      className={classes}
      iconClassName={invisibleClasses}
    />
  )
}

export default StarBuild
