import type {SkipToken} from '@reduxjs/toolkit/query'
import {skipToken} from '@reduxjs/toolkit/query'
import {useMemo} from 'react'

import {useAppSelector} from '../../../hooks/react-redux'
import {getBuildFields} from '../../../rest/builds'
import {investigation} from '../../../rest/investigations'
import type {
  GetAllTestOccurrencesNormalizedApiArg,
  GetTestOccurrenceApiArg,
  GetTestOccurrenceNormalizedApiArg,
} from '../../../services/rest'
import {restBaseApi} from '../../../services/restBase'
import type {BuildTypeHierarchy, ProjectId, TestId} from '../../../types'
import {getPager} from '../../common/Pager/Pager.selectors'
import {PagerGroup} from '../../common/Pager/Pager.types'

import type {RequestTestOccurrenceOptionsParams} from './Tests.types'

export const muteFields =
  'mute(id,state,resolution(type,time),scope(project(id,name),buildTypes(buildType(id,name))),assignee(id,name,username),assignment(user(id,name,username),timestamp,text))'

const getTestCountersFields = (withDuration?: boolean): string =>
  `testCounters(failed,success,muted,all,ignored,newFailed${
    withDuration === true ? ',duration' : ''
  })`

const getTestOccurrenceInnerFields = (options?: RequestTestOccurrenceOptionsParams): string => {
  const {
    withNewFailure,
    withMetadataCount,
    withNextFixed,
    withFirstFailed,
    withRunOrder,
    withMuteInfo,
    withInvocationsCounters,
    withInvestigationInfo,
    withBuildInfo,
  } = options ?? {}
  let fields =
    `id,name,status,duration,logAnchor,currentlyInvestigated,currentlyMuted,muted,` +
    `test(id,parsedTestName${
      withInvestigationInfo === true ? `,investigations(${investigation})` : ''
    }${withMuteInfo === true ? `,mutes(${muteFields})` : ''})`

  if (withNewFailure === true) {
    fields += ',newFailure'
  }

  if (withMetadataCount === true) {
    fields += ',metadata(count)'
  }

  if (withNextFixed === true) {
    fields += ',nextFixed(id)'
  }

  if (withFirstFailed === true) {
    fields += ',firstFailed(id)'
  }

  if (withRunOrder === true) {
    fields += ',runOrder'
  }

  if (withMuteInfo === true) {
    fields += `,${muteFields}`
  }

  if (withInvocationsCounters === true) {
    fields += `,invocations($locator(count:-1),${getTestCountersFields()})`
  }

  if (withBuildInfo === true) {
    fields += `,build(buildTypeId,${getBuildFields({
      withShortProjectDetails: true,
      withBuildTypeDetails: true,
      withRunningInfo: true,
    })})`
  } else {
    fields += `,build(id,buildTypeId,personal,startDate,buildType(internalId,project(id,virtual,parentProjectId,internalId)),agent(id,name))`
  }

  return fields
}

const getTestOccurrenceFields = (options?: RequestTestOccurrenceOptionsParams): string =>
  `testOccurrence(${getTestOccurrenceInnerFields(options)})`

export const useTestOccurencesArg = (
  locator: string,
  options?: RequestTestOccurrenceOptionsParams,
): GetAllTestOccurrencesNormalizedApiArg => {
  const pageSize = useAppSelector(state => getPager(state, PagerGroup.TEST).pageSize)
  return useMemo(
    () => ({
      locator,
      fields: `nextHref,${getTestOccurrenceFields(options)}`,
      pageSize,
      options,
    }),
    [locator, options, pageSize],
  )
}

export const getTestOccurenceArg = (
  testLocator: string,
  options?: RequestTestOccurrenceOptionsParams,
): GetTestOccurrenceNormalizedApiArg => ({
  testLocator,
  fields: getTestOccurrenceInnerFields(options),
  options,
})

export const getTestOccurrencesTreeFields = (options?: RequestTestOccurrenceOptionsParams) => {
  const countersFields = getTestCountersFields(true)
  const testOccurrenceFields = getTestOccurrenceFields(options)
  return `node(name,parentId,type,id,${countersFields},childrenCount),leaf(nodeId,testOccurrences(${testOccurrenceFields}))`
}

export const getTestOccurrenceMetadataArg = (
  testLocator?: string | null,
): GetTestOccurrenceApiArg | SkipToken =>
  testLocator != null
    ? {
        testLocator,
        fields: 'metadata',
      }
    : skipToken

export const getTestOccurrencesInvocationsArg = (
  locator: string,
  invocationsLocator: string,
  options?: RequestTestOccurrenceOptionsParams,
): GetAllTestOccurrencesNormalizedApiArg => ({
  isInvocations: true,
  locator,
  fields: `testOccurrence(id,invocations($locator(${encodeURIComponent(
    invocationsLocator,
  )}),${getTestOccurrenceFields(options)}))`,
  options,
})

type BuildTypeHierarchyArg = {
  projectId: ProjectId | null | undefined
  testNameId: TestId | null | undefined
}
export const testsApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getBuildTypeHierarchy: build.query<BuildTypeHierarchy, BuildTypeHierarchyArg>({
      query: params => ({url: '/app/testBuildTypeHierarchy', params}),
    }),
  }),
})
