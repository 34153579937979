import type {PopupAttrs} from '@jetbrains/ring-ui/components/popup/popup'
import * as React from 'react'

const PopupLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Popup" */
      './Popup'
    ),
)

function Popup(props: PopupAttrs) {
  return props.hidden === true && process.env.NODE_ENV !== 'test' ? null : (
    <React.Suspense fallback="">
      <PopupLazy {...props} />
    </React.Suspense>
  )
}

export default React.memo(Popup)
