import settingsModifierIcon from '@jetbrains/icons/settings-modifier.svg'
import spinnerIcon from '@jetbrains/icons/spinner.svg'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classNames from 'classnames'
import {memo, useState} from 'react'

import type {State} from '../../../reducers/types'
import {getBuildType, getBuildTypeLinks, getIsReadOnly, getProjectLinks} from '../../../selectors'
import type {TabParams} from '../../../types'
import {resolveWebEntityEditLink, resolveWebEntitySettingsLink} from '../../../utils/entityLinks'
import {resolveRelative} from '../../../utils/url'
import {buildTypeSettingsHint} from '../../packages/Hints/HintItems/Hints.buildOverview'
import {projectSettingsHint} from '../../packages/Hints/HintItems/Hints.projectOverview'
import {useHint} from '../../packages/Hints/Hints.hooks'
import DarkTooltip from '../DarkTooltip/DarkTooltip'
import {useSequenceLoaderReadyHandler} from '../SequenceLoader/SequenceLoader.hooks'
import ToggleLink from '../ToggleLink/ToggleLink'

import type {EditHref, Props} from './EditEntity.types'

import styles from './EditEntity.css'

export function getEditHref(state: State, {buildTypeId, projectId}: TabParams): EditHref {
  const resolvedProjectId = projectId ?? getBuildType(state, buildTypeId)?.projectId
  const links = getBuildTypeLinks(state, buildTypeId) ?? getProjectLinks(state, projectId)
  const editHref = resolveWebEntityEditLink(links)
  return {
    href: editHref ?? resolveWebEntitySettingsLink(links),
    readOnly: editHref == null || getIsReadOnly(state, resolvedProjectId),
  }
}

function EditEntity({href, readOnly = false, buildTypeId, projectId, isGoBack = false}: Props) {
  useSequenceLoaderReadyHandler(true)
  useHint(
    !isGoBack &&
      href != null &&
      (buildTypeId != null ? buildTypeSettingsHint : projectSettingsHint),
  )
  const [loading, setLoading] = useState(false)

  return (
    <DarkTooltip
      delay={100}
      title={
        isGoBack
          ? 'Exit Settings Mode'
          : href == null
            ? 'No edit or view permissions'
            : readOnly
              ? 'View settings (no edit permissions)'
              : 'Edit settings'
      }
      popupProps={{offset: 8, directions: [Directions.BOTTOM_LEFT, Directions.BOTTOM_RIGHT]}}
    >
      <ToggleLink
        animated={false}
        to={
          href ??
          (buildTypeId != null
            ? resolveRelative('/admin/editBuild.html', {id: `buildType:${buildTypeId}`})
            : resolveRelative('/admin/editProject.html', {projectId}))
        }
        icon={loading ? spinnerIcon : settingsModifierIcon}
        switcherClassName={classNames({[styles.spinner]: loading})}
        label="Settings"
        checked={isGoBack}
        onConditionalClick={isPlainLeft => {
          if (isPlainLeft) {
            setLoading(true)
          }
        }}
        data-hint-container-id={projectSettingsHint.id}
        loader={loading}
      />
    </DarkTooltip>
  )
}

export default memo(EditEntity)
