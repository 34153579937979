import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'

import {resolveHelpURL} from '../../../../utils/url'
import {HINT_CATEGORY, toHintId} from '../Hints.types'
import type {Hint} from '../Hints.types'

export const projectSettingsHint: Hint = {
  id: toHintId('edit-entity'),
  name: 'Project Settings',
  text: 'Use this link to switch between the project overview and its edit mode. To go to a specific settings tab, expand the edit menu.',
  helpLink: resolveHelpURL('?Creating+and+Editing+Projects'),
  category: HINT_CATEGORY.projectOverview,
  offset: 40,
}
export const subprojectStatusesHint: Hint = {
  id: toHintId('subproject-statuses'),
  name: 'Recent build statuses',
  text: 'See the recent statuses of the project’s build configurations without expanding their details.',
  category: HINT_CATEGORY.projectOverview,
}
export const trendsOrBuildsHint: Hint = {
  id: toHintId('project-mode-switcher'),
  name: 'View Mode',
  text: 'Switch between a build list and a visual chart of builds.',
  category: HINT_CATEGORY.projectOverview,
}
export const projectCreateHint: Hint = {
  id: toHintId('project-create-entity'),
  name: 'Create Subprojects',
  text: 'Create subprojects and build configurations right from the Project Home: look for the + button opposite each project’s name.',
  category: HINT_CATEGORY.projectOverview,
  directions: [Directions.BOTTOM_LEFT],
}
