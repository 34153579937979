import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {getAgentsPagesQueries} from '../AgentsPages.queries'

import type {AgentsPoolsFavoritePageContainerComponent} from './AgentsPoolsFavoritePage'
import {query} from './AgentsPoolsFavoritePage.queries'

export const AgentsPoolsFavoritePageEntryPoint: EntryPoint<AgentsPoolsFavoritePageContainerComponent> =
  {
    root: makeResource(
      'AgentsPoolsFavoritePage',
      () => import(/* webpackChunkName: "AgentPoolsFavoritePage" */ './AgentsPoolsFavoritePage'),
    ),
    getPreloadProps: () => ({
      queries: {
        ...getAgentsPagesQueries(),
        main: {
          parameters: getRequest(query),
          variables: {},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }),
  }
