import classNames from 'classnames'
import * as React from 'react'

import type {HealthItemOwnProps} from './HealthItem.types'
import {getSeverityClassName} from './HealthItem.utils'

import styles from './HealthItem.css'

const HealthItem = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HealthItem" */
      './HealthItem'
    ),
)

function HealthItemLazy(props: HealthItemOwnProps) {
  return (
    <React.Suspense
      fallback={
        <div
          className={classNames(styles.fallbackHealthItem, getSeverityClassName(props.severity))}
        />
      }
    >
      <HealthItem {...props} />
    </React.Suspense>
  )
}

export default React.memo(HealthItemLazy)
