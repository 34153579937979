import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {toAgentTypeId} from '../../../../../types'
import {AgentTypeParametersEntryPoint} from '../../../AgentsPages/AgentTypePage/AgentTypeParameters/AgentTypeParameters.entryPoint'

import type {AgentTypePipelinePageContainerComponent} from './AgentTypePipelinePage'
import {query} from './AgentTypePipelinePage.queries'

import {makeResource} from 'src/utils/makeResource'

export const AgentTypePipelinePageEntryPoint: EntryPoint<
  AgentTypePipelinePageContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'AgentTypePipelinePage',
    () => import(/* webpackChunkName: "AgentTypePipelinePage" */ './AgentTypePipelinePage'),
  ),
  getPreloadProps: ({params}) => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {locator: `id:${params.agentTypeId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
    entryPoints: {
      parameters: {
        entryPoint: AgentTypeParametersEntryPoint,
        entryPointParams: toAgentTypeId(params.agentTypeId!),
      },
    },
  }),
}
