import {createSlice} from '@reduxjs/toolkit'

export const actionsHint = createSlice({
  name: 'actionsHint',
  initialState: {
    dismissed: false,
    showedTimes: 0,
  },
  reducers: {
    dismiss(state) {
      state.dismissed = true
    },
    recordShowed(state) {
      state.showedTimes++
      if (state.showedTimes > 2) {
        state.dismissed = true
      }
    },
  },
})
