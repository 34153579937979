import {createSlice} from '@reduxjs/toolkit'

export type AdminSidebarPanelSettings = {
  currentId?: string
  currentNodeType?: 'project' | 'bt'
  buildTypeUrlFormat?: string
  templateUrlFormat?: string
  projectUrlFormat?: string
}

const initialState: AdminSidebarPanelSettings = {}

export const adminSidebarPanelSettings = createSlice({
  name: 'adminSidebarPanelSettings',
  initialState,
  reducers: {
    set(state, action) {
      return action.payload
    },
  },
})
