import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'

import type {BuildTestsTabComponent, NestedEntryPoints} from './BuildTestsTab'
import {countsQuery, getCountsVariables} from './BuildTestsTab.queries'
import {getFilterQueryParams} from './BuildTestsTab.utils'
import {TestScopesViewEntryPoint} from './TestScopesView/TestScopesView.entryPoint'
import {ViewType} from './TestsFilter/TestsViewSelect/TestsViewSelect.utils'

type BuildTestsTabEntryPointParams = {
  buildLocator: string
}

export const BuildTestsTabEntryPoint: EntryPoint<
  BuildTestsTabComponent,
  LoaderFunctionArgs<BuildTestsTabEntryPointParams>
> = {
  root: makeResource(
    'BuildTestsTab',
    () => import(/* webpackChunkName: "BuildTestsTab" */ './BuildTestsTab'),
  ),
  getPreloadProps: ({context, request, params}) => {
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}

    const url = new URL(request.url)
    const {view} = getFilterQueryParams(url)

    if (view !== ViewType.TESTS) {
      entryPoints.testScopesView = {
        entryPoint: TestScopesViewEntryPoint,
        entryPointParams: {
          request,
          params,
          context,
        },
      }
    }

    return {
      queries: {
        counts: {
          parameters: getRequest(countsQuery),
          variables: getCountsVariables(context?.buildLocator ?? ''),
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
