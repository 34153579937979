import {archivedProjectsOptions, getProjectsArg, getSingleProjectArg} from '../rest/projects'
import {restApi} from '../services/rest'
import type {ProjectId, RequestOptionsParams} from '../types'
import {ROOT_PROJECT_ID} from '../types'
import type {Refetchable} from '../utils/refetchable'
import {combineRefetchables} from '../utils/refetchable'

import type {AppThunk} from './types'

export const fetchSingleProjectData = (projectId: ProjectId, options?: RequestOptionsParams) =>
  restApi.endpoints.getProjectNormalized.initiate(getSingleProjectArg(projectId, options))
export const fetchProjectWithAllParentsData =
  (projectId: ProjectId): AppThunk<Refetchable> =>
  dispatch => {
    const refetchables: Refetchable[] = []

    if (projectId !== ROOT_PROJECT_ID) {
      refetchables.push(
        dispatch(
          fetchSingleProjectData(projectId, {
            withAncestorProjects: true,
            withBuildTypes: true,
            withArchivedSubprojectsIds: true,
          }),
        ),
      )
    }

    return combineRefetchables(refetchables)
  }
export const fetchProjectsWithArchivedData = () =>
  restApi.endpoints.getAllProjectsNormalized.initiate(
    getProjectsArg(ROOT_PROJECT_ID, {
      withBuildTypes: true,
      withLinks: true,
      archived: 'any',
    }),
  )

export const fetchArchivedProjects = () =>
  restApi.endpoints.getAllProjectsNormalized.initiate(
    getProjectsArg(ROOT_PROJECT_ID, archivedProjectsOptions),
  )
