import {QueryStatus} from '@reduxjs/toolkit/query'
import {createSelector} from 'reselect'

import {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import {getPager} from '../components/common/Pager/Pager.selectors'
import {PagerGroup} from '../components/common/Pager/Pager.types'
import {getOffset} from '../components/common/Pager/Pager.utils'
import {
  getExpandAll,
  getFocusLine,
  getLogFilter,
} from '../components/packages/BuildLog/BuildLog.selectors'
import {ClassicChangePageTabName} from '../components/packages/Changes/ChangeDetailsTabs/ChangeDetailsTabs.types'
import {isCloudImageManageable} from '../components/pages/AgentsPages/AgentsPages.utils'
import {BranchesToShow} from '../components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeBranches/CollapsibleBranchLines/CollapsibleBranchLines.types'
import type {ProjectsState, State} from '../reducers/types'
import {getAgentPreviewsArg, getAgentsArg} from '../rest/agents'
import {BuildTypeProperties, getBuildTypeArg} from '../rest/buildTypes'
import {overviewArg} from '../rest/projects'
import type {StatusKey} from '../rest/schemata'
import {getStatusKey} from '../rest/schemata'
import {getTabsArg} from '../rest/tabs'
import {getOverviewHref} from '../routes'
import type {Branch, Build, BuildType, Pipeline} from '../services/rest'
import {restApi} from '../services/rest'
import type {
  ActiveEntityProps,
  ActiveEntityURLProps,
  AgentId,
  AgentPoolId,
  AgentPreviewsHash,
  AgentRequestOptions,
  AgentTypeId,
  BuildArtifacts,
  BuildId,
  BuildState,
  BuildTypeId,
  ChangeId,
  CommentInfo,
  CompatibleAgent,
  DialogType,
  EntityDescription,
  Fetchable,
  FullPath,
  Id,
  InexactEntityParameters,
  NormalizedAgent,
  NormalizedBuild,
  NormalizedBuildTriggered,
  NormalizedCloudImage,
  NormalizedProject,
  ProjectId,
  ProjectOrBuildTypeNode,
  ProjectOrBuildTypeStatus,
  ProjectPathItem,
  ServerInfo,
  Sorting,
  StatusRequest,
  Tab,
  TabId,
  TabParams,
  TabParamsKey,
  Tag,
  Template,
  TestId,
  UrlExtension,
  UserId,
  WebLinks,
  WritableAgentPreviewsHash,
} from '../types'
import {
  BuildPageTabNamesEnum,
  BuildStatusType,
  BuildTypePageTabNamesEnum,
  getBuildTypeStatusRequest,
  ProjectPageTabNamesEnum,
  ROOT_PROJECT_ID,
  STAR_TAG,
  stringifyId,
  toProjectId,
} from '../types'
import {getBranchParam, parseBranch, stringifyBranch} from '../utils/branchNames'
import {emptyArray, emptyArrayFetchable, getEmptyHash} from '../utils/empty'
import {resolveWebEntityLink, WebEntityType} from '../utils/entityLinks'
import {getPropertyFromList} from '../utils/getProperty'
import {notNull} from '../utils/guards'
import type {FullPathCacheItem} from '../utils/memoryCache'
import memoryCache from '../utils/memoryCache'
import type {KeyValue, WritableKeyValue} from '../utils/object'
import {objectEntries, objectValues} from '../utils/object'
import type {QueryParams} from '../utils/queryParams'
import {objectToQuery, queryToObject} from '../utils/queryParams'
import {getTabParamsKey} from '../utils/tabs'
import {parseURL, resolveRelative} from '../utils/url'

import type {ProjectsTree} from './projectTrees'
// For some reason, calling a method on emptyArray makes type refinement work correctly
emptyArray.slice()
export const getBuildTypesHash: (arg0: State) => KeyValue<BuildTypeId, BuildType> = state =>
  state.entities.buildTypes

export const getPipelinesHash: (arg0: State) => KeyValue<string, Pipeline> = state =>
  state.entities.pipelines

export const getPipeline: (
  arg0: State,
  arg1: string | null | undefined,
) => Pipeline | null | undefined = (state, id) => (id != null ? getPipelinesHash(state)[id] : null)

export const getBuildType: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => BuildType | null | undefined = (state, id) =>
  id != null ? getBuildTypesHash(state)[id] : null
export const getBuildTypeLinks: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => WebLinks | null | undefined = (state, id) => (id ? state.entities.buildTypeLinks[id] : null)
export const getProjectLinks: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => WebLinks | null | undefined = (state, id) => (id ? state.entities.projectLinks[id] : null)
export const getBuildTypeParameters: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => InexactEntityParameters | null | undefined = (state, id) =>
  id ? state.entities.buildTypeParameters[id] : null
const getProjectParameters: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => InexactEntityParameters | null | undefined = (state, id) =>
  id ? state.entities.projectParameters[id] : null
export const isBuildTypeLoaded: (arg0: State, arg1: BuildTypeId | null | undefined) => boolean = (
  state,
  id,
) =>
  id
    ? getBuildType(state, id) != null &&
      getBuildTypeLinks(state, id) != null &&
      getBuildTypeParameters(state, id) != null
    : false
export const getOverviewBuildTypesHash: (arg0: State) => KeyValue<BuildTypeId, BuildType> = state =>
  state.entities.overviewBuildTypes
export const getOverviewPipelinesHash: (arg0: State) => KeyValue<string, Pipeline> = state =>
  state.entities.overviewPipelines
export const getOverviewBuildType: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => BuildType | null | undefined = (state, id) =>
  id != null ? getOverviewBuildTypesHash(state)[id] : null
export const getOwnerProjectId: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => ProjectId | null | undefined = (state, buildTypeId) =>
  getBuildType(state, buildTypeId)?.projectId
export const getProjectsHash: (arg0: State) => KeyValue<ProjectId, NormalizedProject> = state =>
  state.entities.projects
export const getProjectTemplates = (state: State): KeyValue<ProjectId, ReadonlyArray<Template>> =>
  state.entities.projectTemplates
export const getProject: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => NormalizedProject | null | undefined = (state, id) =>
  id != null ? getProjectsHash(state)[id] : null
export const getHasProjects: (arg0: State) => boolean = state => getProjectsHash(state) != null

const getTabsResult = (state: State, key: TabParamsKey) =>
  restApi.endpoints.getTabs.select(getTabsArg(key))(state)

const getTabs: (state: State, key: TabParamsKey) => ReadonlyArray<Tab> = (state, key) =>
  getTabsResult(state, key).data ?? emptyArray

export const isProjectLoaded: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  id,
) =>
  id
    ? getProject(state, id) != null &&
      getProjectLinks(state, id) != null &&
      getProjectParameters(state, id) != null
    : false
export const isArchivedProject: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  id,
) => {
  const project = getProject(state, id)
  return project ? project.archived : false
}
export const isArchivedSubprojectLoadedForAllProjects: (arg0: State) => boolean = createSelector(
  getProjectsHash,
  projects => {
    const projectIds = Object.keys(projects).map(toProjectId)

    for (let i = 0; i < projectIds.length; i++) {
      const subprojects = projects[projectIds[i]]?.projects?.project || emptyArray

      for (let j = 0; j < subprojects.length; j++) {
        if (!projects[subprojects[j].id]) {
          return false
        }
      }
    }

    return true
  },
)

export const getProjectsState: (arg0: State, arg1?: ProjectId) => ProjectsState = (
  state,
  rootProjectId = ROOT_PROJECT_ID,
) => state.projects[rootProjectId] ?? {loaded: false, data: emptyArray}
const getOverviewResult = restApi.endpoints.getAllProjectsNormalized.select(overviewArg)
export const getOverviewProjectsHash: (
  arg0: State,
) => KeyValue<ProjectId, NormalizedProject> = state =>
  getOverviewResult(state).data?.entities.overviewProjects ?? getEmptyHash()
export const getOverviewProject: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => NormalizedProject | null | undefined = (state, id) =>
  id != null ? getOverviewProjectsHash(state)[id] : null
export const getOverviewProjectParents: (
  arg0: State,
  arg1: ProjectId,
) => ReadonlyArray<ProjectId> = (state, id) =>
  getOverviewProject(state, id)?.ancestorProjects?.project ?? []

export const getShowQueuedBuildsInBuildsList: (arg0: State) => boolean = state =>
  state.showQueuedBuildsInBuildsList
export const getIsSakuraUI: (arg0: State) => boolean = state => state.isSakuraUI

export const getProjectsReady = (state: State, projectId?: ProjectId) =>
  getProjectsState(state, projectId).loaded

export const getAllProjectsReady = (state: State) =>
  getProjectsState(state).receiveMeta?.sidebarProjectsLoaded === true

export const getPathBuildTypeArg = (buildTypeId: BuildTypeId) =>
  getBuildTypeArg(buildTypeId, {withLinks: true})

export const getPathBuildType = (state: State, id: BuildTypeId) => {
  const buildTypePathResult = restApi.endpoints.getPathBuildTypeNormalized.select(
    getPathBuildTypeArg(id),
  )(state)

  if (buildTypePathResult.status === QueryStatus.fulfilled) {
    const {result: buildTypeId, entities} = buildTypePathResult.data!
    return entities?.buildTypes?.[buildTypeId]
  }

  return null
}

const getPathBuildTypeLinks = (state: State, id: BuildTypeId) => {
  const buildTypePathResult = restApi.endpoints.getPathBuildTypeNormalized.select(
    getPathBuildTypeArg(id),
  )(state)

  if (buildTypePathResult.status === QueryStatus.fulfilled) {
    const {result: buildTypeId, entities} = buildTypePathResult.data!
    return entities?.buildTypeLinks?.[buildTypeId]
  }

  return null
}

const getFullPathPart: (
  arg0: State,
  arg1: ProjectOrBuildTypeNode | null | undefined,
  arg2?: boolean | null | undefined,
) => FullPathCacheItem = (state, node, onlyFavorites) => {
  const result = {
    fullPath: emptyArray,
    isFullyLoaded: false,
  }

  if (node == null || node.nodeType === 'all' || node.id === ROOT_PROJECT_ID) {
    result.isFullyLoaded = true
    return result
  }

  const cacheKey = `${node.nodeType}_${stringifyId(node.id)}`
  const cached: FullPathCacheItem | null | undefined = memoryCache.get('fullPath', cacheKey)

  if (cached?.isFullyLoaded === true) {
    return cached
  }

  if (node.nodeType === 'bt') {
    const buildType = getBuildType(state, node.id) ?? getPathBuildType(state, node.id)

    if (buildType == null) {
      result.fullPath = [
        {
          id: node.id,
          name: stringifyId(node.id),
          projectId: ROOT_PROJECT_ID,
        },
      ]
    } else {
      const links = getBuildTypeLinks(state, node.id) ?? getPathBuildTypeLinks(state, node.id)
      const item: BuildType = links != null ? {...buildType, ...links} : {...buildType}
      const parentPart = getFullPathPart(state, {
        nodeType: 'project',
        id: buildType.projectId,
      })
      result.fullPath = parentPart.fullPath.concat(item)
      result.isFullyLoaded = parentPart.isFullyLoaded
    }
  } else {
    const project = onlyFavorites ? getOverviewProject(state, node.id) : getProject(state, node.id)

    if (project == null) {
      return result
    }

    const links = getProjectLinks(state, node.id)
    const item: ProjectPathItem = links != null ? {...project, ...links} : {...project}
    const {parentProjectId} = project

    if (parentProjectId == null) {
      result.fullPath = [item]
      result.isFullyLoaded = true
    } else {
      const parentPart = getFullPathPart(
        state,
        {
          nodeType: 'project',
          id: parentProjectId,
        },
        onlyFavorites,
      )
      result.fullPath = parentPart.fullPath.concat(item)
      result.isFullyLoaded = parentPart.isFullyLoaded
    }
  }

  if (cached != null && result.fullPath.length === cached.fullPath.length) {
    return cached
  }

  memoryCache.set('fullPath', cacheKey, result)
  return result
}

export const getFullPath: (
  arg0: State,
  arg1: ProjectOrBuildTypeNode | null | undefined,
  arg2?: boolean | null | undefined,
) => FullPath = (state, node, onlyFavorites) => getFullPathPart(state, node, onlyFavorites).fullPath

export const getBuildsData: (
  arg0: State,
  arg1: string | null | undefined,
) => Fetchable<ReadonlyArray<BuildId>> = (state, locator) =>
  (locator != null && state.builds[locator]) || emptyArrayFetchable
export const getBuildsHash: (state: State) => KeyValue<BuildId, NormalizedBuild | null> = state =>
  state.entities.builds
export const getBuild = (
  state: State,
  id: BuildId | null | undefined,
): NormalizedBuild | null | undefined => (id != null ? getBuildsHash(state)[id] : null)
export const getBuildTestOccurrencesCount = (
  state: State,
  id: BuildId | null | undefined,
): number | null | undefined => (id != null ? state.entities.buildsTestOccurrencesCount[id] : null)

export function getBuildAgent(state: State, id: BuildId): NormalizedAgent | null | undefined {
  const build = getBuild(state, id)
  return build?.state === 'queued'
    ? build?.plannedAgent?.typeId === 0
      ? null
      : build?.plannedAgent
    : build?.agent
}

export const makeBuildBranchSelector = () =>
  createSelector(
    [
      (state: State, id: BuildId | null | undefined) => getBuild(state, id)?.branchName,
      (state: State, id: BuildId | null | undefined) => getBuild(state, id)?.defaultBranch,
    ],
    (branchName, defaultBranch) => {
      const parsedBranch = parseBranch(branchName)
      if (parsedBranch) {
        return {
          ...parsedBranch,
          default: defaultBranch,
        }
      }
      return parsedBranch
    },
  )

export const getSnapshotDependencies = (state: State): KeyValue<BuildId, ReadonlyArray<BuildId>> =>
  state.entities.snapshotDependencies
export const getBuildSnapshotDependencies: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<BuildId> = (state, id) =>
  id != null ? (getSnapshotDependencies(state)[id] ?? emptyArray) : emptyArray

export const getSnapshotDependenciesLoaded: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => boolean = (state, id) => id != null && state.entities.snapshotDependencies[id] != null
export const getBuildTriggered: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => NormalizedBuildTriggered | null | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build ? build.triggered : null
}
export const getBuildComment: (
  arg0: State,
  arg1: BuildId | null | undefined,
) =>
  | (CommentInfo &
      Readonly<{
        buildTypeId: BuildTypeId
      }>)
  | null
  | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build
    ? {
        buildTypeId: build.buildType,
        ...build.comment,
      }
    : null
}
export const getBuildPinInfo: (
  arg0: State,
  arg1: BuildId | null | undefined,
) =>
  | (CommentInfo &
      Readonly<{
        buildTypeId: BuildTypeId
      }>)
  | null
  | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build
    ? {
        buildTypeId: build.buildType,
        ...build.pinInfo,
      }
    : null
}
export const getBuildCanceledInfo: (
  arg0: State,
  arg1: BuildId | null | undefined,
) => CommentInfo | null | undefined = (state, id) => {
  const build = getBuild(state, id)
  return build ? build.canceledInfo : null
}
export const makeGetMaybeBuilds: () => (
  state: State,
  locator: string | null | undefined,
) => ReadonlyArray<NormalizedBuild | null | undefined> = () =>
  createSelector(
    [
      getBuildsHash,
      (state: State, locator: string | null | undefined) => getBuildsData(state, locator).data,
    ],
    (hash: KeyValue<BuildId, NormalizedBuild | null>, data: ReadonlyArray<BuildId>) =>
      data.map(id => hash[id]),
  )
export const makeGetBuilds: () => (
  state: State,
  locator: string | null | undefined,
) => ReadonlyArray<NormalizedBuild> = () =>
  createSelector(makeGetMaybeBuilds(), builds => {
    const result = builds.filter(notNull)
    return result.length > 0 ? result : emptyArray
  })
// This selector is suitable only for components that use the main locator of page (pager, filters, etc.)
const getBuilds = makeGetBuilds()

export const getBuildsLocatorWithPage = (state: State, locator: string, page: number) => {
  const pager = getPager(state, PagerGroup.BUILD)
  const offset = getOffset(pager, page)
  const count = pager.pageSize
  return `${locator},start:${offset},count:${count},lookupLimit:${String(pager.lookupLimit)}`
}

export const getNoMatchingWords = (source: string, words: string[]): string[] => {
  const [firstWord] = words
  const notFoundWords: Array<string> = []

  if (!source?.includes(firstWord)) {
    return words
  }

  if (source) {
    let startIndex = 0
    let matchedWordIndex: number | void

    words.forEach(word => {
      if (matchedWordIndex !== -1) {
        matchedWordIndex = source.indexOf(word, startIndex)
      }

      if (matchedWordIndex === -1) {
        notFoundWords.push(word)
      } else {
        startIndex = matchedWordIndex + word.length
      }
    })
  }

  return notFoundWords
}

export const getBuildsFilteredBySearchQuery = (
  state: State,
  builds: readonly (NormalizedBuild | null | undefined)[],
  searchQuery: string | null | undefined,
): NormalizedBuild[] => {
  const words: Array<string> | undefined = searchQuery?.toLowerCase().split(' ').filter(Boolean)

  return builds.filter((build): build is NormalizedBuild => {
    const isNotNull = build != null

    if (isNotNull && words?.length) {
      const fullPath = getFullPath(state, {
        nodeType: 'bt',
        id: build?.buildType,
      })
      const fullPathString = fullPath
        .map(({name}) => name)
        .join(' ')
        .toLowerCase()
      const noMatchingWords = getNoMatchingWords(fullPathString, words)

      return !noMatchingWords.length
    }

    return isNotNull
  })
}

const filterBuildsByPage = (
  currentPage: number,
  pageSize: number,
  builds: readonly NormalizedBuild[],
) => builds.slice((currentPage - 1) * pageSize, currentPage * pageSize)

export const getBuildsFilteredByPage: (
  state: State,
  builds: readonly NormalizedBuild[],
  currentPage: number,
) => NormalizedBuild[] = createSelector(
  (_: State, __: readonly NormalizedBuild[], currentPage: number) => currentPage,
  (state: State) => getPager(state, PagerGroup.BUILD).pageSize,
  (state: State, builds: readonly NormalizedBuild[]) => builds,
  filterBuildsByPage,
)

export const getBuildsLoading = (state: State, locator?: string | null): boolean =>
  getBuildsData(state, locator).loading
export const getBuildsInited = (state: State, locator?: string): boolean =>
  getBuildsData(state, locator).inited
export const getBuildsBackgroundLoading: (
  arg0: State,
  arg1: string | null | undefined,
) => boolean = (state, locator) => getBuildsData(state, locator).backgroundLoading
export const getBuildsHasError: (state: State, locator?: string | null) => boolean = (
  state,
  locator,
) => getBuildsData(state, locator).error != null
export const getBuildsReady = (
  state: State,
  locator?: string | null,
  withPath?: boolean,
): boolean => getBuildsData(state, locator).ready && (withPath !== true || getProjectsReady(state))
export const getSomeBuildsLoaded: (arg0: State, arg1: string | null | undefined) => boolean = (
  state,
  locator,
) => getBuildsData(state, locator).data.length > 0
const getBuildArtifactsHash: (arg0: State) => KeyValue<BuildId, NormalizedBuild | null> = state =>
  state.entities.buildArtifacts

const hasArtifactsInBuild: (
  arg0: BuildArtifacts | null | undefined,
) => boolean | null | undefined = build => {
  const {artifacts} = build || {}
  return artifacts?.count != null ? artifacts.count > 0 : null
}

export const getHasArtifacts: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  id,
) => {
  const hasArtifacts = hasArtifactsInBuild(getBuild(state, id))
  return hasArtifacts != null ? hasArtifacts : hasArtifactsInBuild(getBuildArtifactsHash(state)[id])
}
export const getHasArtifactsState: (arg0: State, arg1: BuildId) => BuildState | null | undefined = (
  state,
  id,
) => {
  const build = getBuild(state, id)
  const hasArtifacts = hasArtifactsInBuild(build)
  const buildToCheck: NormalizedBuild | null | undefined =
    hasArtifacts != null ? build : getBuildArtifactsHash(state)[id]
  return buildToCheck?.state
}

const getBuildCompatibleAgentsHash: (
  arg0: State,
) => KeyValue<BuildId, CompatibleAgent | null> = state => state.entities.compatibleAgents

export const getBuildCompatibleAgents: (
  arg0: State,
  arg1: BuildId,
) => CompatibleAgent | null | undefined = (state, id) => getBuildCompatibleAgentsHash(state)[id]

export const isDialogShown: (state: State, dialogId?: string, type?: DialogType) => boolean = (
  state,
  dialogId = '',
  type,
) => state.dialog.opened === true && state.dialog.type === type && state.dialog.id === dialogId
export const isDialogProcessing: (arg0: State) => boolean = state => !!state.dialog.processing
export const dialogHasError: (arg0: State) => boolean = state => !!state.dialog.error

export const getAllTags: (arg0: State, arg1: BuildId | null | undefined) => ReadonlyArray<Tag> = (
  state,
  buildId,
) => getBuild(state, buildId)?.tags?.tag ?? emptyArray

// If you need this array as a prop, use makeGetTags factory instead
export const getTags: (arg0: State, arg1: BuildId | null | undefined) => ReadonlyArray<Tag> = (
  state,
  buildId,
) => getAllTags(state, buildId).filter(tag => !tag.private)
export const getTagsCount: (arg0: State, arg1: BuildId | null | undefined) => number = (
  state,
  buildId,
) => getTags(state, buildId).length
export const getTagsLabel: (arg0: State, arg1: BuildId) => string = (state, buildId) => {
  const count = getTagsCount(state, buildId)

  switch (count) {
    case 0:
      return ''

    case 1:
      return getTags(state, buildId)[0].name

    default:
      return count.toString()
  }
}
export const makeGetTags: () => (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<Tag> = () => createSelector(getAllTags, tags => tags.filter(tag => !tag.private))
export const makeGetTagNames: () => (
  arg0: State,
  arg1: BuildId | null | undefined,
) => ReadonlyArray<string> = () => createSelector(makeGetTags(), tags => tags.map(tag => tag.name))
export const getIsStarred: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  buildId,
) => {
  if (buildId == null) {
    return false
  }

  const {tags} = getBuild(state, buildId) || {}
  return tags?.tag?.some(tag => tag.private && tag.name === STAR_TAG) || false
}
export const getIsStopping: (arg0: State, arg1: BuildId) => boolean = (state, buildId) =>
  !!state.stoppingBuilds[buildId]
export const getHasDependencies: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  buildId,
) => {
  const deps = getBuild(state, buildId)?.['snapshot-dependencies']
  return deps?.count != null ? deps.count > 0 : false
}

const getAgentsHash: (arg0: State) => KeyValue<AgentId, NormalizedAgent | null> = state =>
  state.entities.agents

export const getAgent: (arg0: State, arg1: AgentId) => NormalizedAgent | null | undefined = (
  state,
  id,
) => getAgentsHash(state)[id]
export const getAgents = createSelector(
  (state: State, locator: string, options: AgentRequestOptions) =>
    restApi.endpoints.getAllAgentsNormalized.select(getAgentsArg(locator, options))(state).data,
  data => data?.result.map(id => data.entities.agents?.[id]).filter(notNull) ?? emptyArray,
)
export const getAgentsReady = (state: State, locator: string, options: AgentRequestOptions) =>
  restApi.endpoints.getAllAgentsNormalized.select(getAgentsArg(locator, options))(state).data !=
    null && getProjectsReady(state)
export const getSingleAgent: (arg0: State, arg1: AgentId) => NormalizedAgent | null | undefined = (
  state,
  agentId,
) => state.entities.agent[agentId]
const getAgentPreviewsResult =
  restApi.endpoints.getAllAgentPreviewsNormalized.select(getAgentPreviewsArg())
export const getAgentPreviewsReady = (state: State): boolean =>
  getAgentPreviewsResult(state).data != null
const getAgentPreviews: (state: State) => AgentPreviewsHash | null | undefined = createSelector(
  getAgentPreviewsReady,
  (state: State) => getAgentPreviewsResult(state).data?.result ?? emptyArray,
  (state: State) => state.entities.agentPreviews,
  (
    ready: boolean,
    agentIds: ReadonlyArray<AgentId>,
    entities: AgentPreviewsHash,
  ): AgentPreviewsHash | null | undefined =>
    ready
      ? agentIds.reduce((hash: WritableAgentPreviewsHash, agentId: AgentId) => {
          hash[agentId] = entities[agentId]
          return hash
        }, {})
      : null,
)
export const getAllAgentPreviews = (state: State): AgentPreviewsHash =>
  getAgentPreviews(state) || getEmptyHash()
export const getSingleCloudImage: (
  arg0: State,
  arg1: AgentTypeId,
) => NormalizedCloudImage | null | undefined = (state, agentTypeId) =>
  state.entities.cloudImage[agentTypeId]
export const getSorting: (arg0: State) => Sorting = state => state.sorting
export const getSortingDimension: (arg0: State) => string = state => getSorting(state).dimension
export const getSortingDescending: (arg0: State) => boolean = state => getSorting(state).descending
export const getAgentInCloud: (arg0: State, arg1: AgentId) => boolean = (state, agentId) =>
  state.agentsInCloud[agentId] ?? false

const getBlocksHash: (arg0: State, arg1: CollapsibleBlock) => KeyValue<string | Id, boolean> = (
  state,
  block,
) => state.blocks[block] ?? getEmptyHash()

export const getBlocks: (arg0: State, arg1: CollapsibleBlock) => ReadonlyArray<string | Id> =
  createSelector(getBlocksHash, hash => Object.keys(hash).filter(id => hash[id]))
const getIsBlockIncluded: (
  state: State,
  block: CollapsibleBlock,
  id: string | Id,
  defaultTrue?: boolean,
) => boolean = (state, block, id, defaultTrue) =>
  getBlocksHash(state, block)[id] ?? defaultTrue === true

export const getOverviewData: (arg0: State) => ReadonlyArray<ProjectId> = state =>
  getOverviewResult(state).data?.result ?? emptyArray
export const makeGetFavoriteSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getOverviewProjectsHash,
    getOverviewData,
    (_: State, projectId: ProjectId) => projectId,
    (projects, favoriteIds, projectId) => {
      function isChild(childId: ProjectId | null | undefined): boolean {
        if (childId == null || childId === ROOT_PROJECT_ID) {
          return false
        }

        const {parentProjectId} = projects[childId] || {}

        if (parentProjectId === projectId) {
          return true
        }

        if (parentProjectId != null && favoriteIds.includes(parentProjectId)) {
          return false
        }

        return isChild(parentProjectId)
      }

      return favoriteIds.filter(isChild)
    },
  )
export const makeGetIsSubprojectsExist: () => (
  state: State,
  projectId: ProjectId,
) => boolean = () =>
  createSelector(
    getProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectValues(projects).some(project => project?.parentProjectId === projectId),
  )
export const makeGetIsFavoriteSubprojectsExist: () => (
  state: State,
  projectId: ProjectId,
) => boolean = () =>
  createSelector(
    getOverviewProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectValues(projects).some(project => project?.parentProjectId === projectId),
  )
export const makeGetDirectNotArchivedSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectEntries(projects)
        .filter(
          ([_, project]) =>
            project?.parentProjectId === projectId &&
            project?.virtual !== true &&
            !project.archived,
        )
        .map(([key]) => toProjectId(key)),
  )
export const makeGetDirectNotArchivedFavoriteSubProjectIds: () => (
  state: State,
  projectId: ProjectId,
) => ReadonlyArray<ProjectId> = () =>
  createSelector(
    getOverviewProjectsHash,
    (_: State, projectId: ProjectId) => projectId,
    (projects, projectId) =>
      objectEntries(projects)
        .filter(
          ([_, project]) =>
            project?.parentProjectId === projectId &&
            project?.virtual !== true &&
            !project.archived,
        )
        .map(([key]) => toProjectId(key)),
  )
export const getHasDependants: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  buildId,
) => state.haveDependants[buildId]
export const getBuildTypesFromLoadedBuild: (
  state: State,
  locator?: string | null,
) => ReadonlyArray<BuildTypeId> = createSelector(getBuilds, builds =>
  builds.reduce<BuildTypeId[]>(
    (x, y) => (y.buildType != null && x.includes(y.buildType) ? x : [...x, y.buildType]),
    [],
  ),
)
export const getIsUpdating: (arg0: State) => boolean = state =>
  Boolean(state.buildsFilters.updating)

export const getBuildStatusTypeFromBuild = (
  build: Pick<Build, 'status' | 'state' | 'canceledInfo' | 'failedToStart'> | null | undefined,
): BuildStatusType => {
  const {status, state: buildState, canceledInfo, failedToStart} = build || {}

  if (canceledInfo != null && buildState !== 'running') {
    return BuildStatusType.CANCELED
  } else if (failedToStart === true) {
    return BuildStatusType.FAILURE
  } else if (buildState === 'queued') {
    return BuildStatusType.QUEUED
  } else {
    const isGreen = status == null || status === 'SUCCESS'
    return isGreen ? BuildStatusType.SUCCESS : BuildStatusType.FAILURE
  }
}
export const getBuildStatusType = (state: State, buildId: BuildId | undefined): BuildStatusType =>
  getBuildStatusTypeFromBuild(getBuild(state, buildId))
export const getIsBuildDetached: (arg0: State, arg1: BuildId) => boolean | null | undefined = (
  state,
  buildId,
) => getBuild(state, buildId)?.detachedFromAgent

export const getBuildStatusIconFromBuild = (
  build:
    | Pick<
        Build,
        | 'status'
        | 'state'
        | 'canceledInfo'
        | 'failedToStart'
        | 'personal'
        | 'detachedFromAgent'
        | 'user'
      >
    | null
    | undefined,
  myId: UserId | null | undefined,
  onlyEmpty?: boolean,
  debug?: boolean,
  ignorePersonal?: boolean,
) => {
  if (build == null) {
    return 'help'
  }

  const {
    status,
    state: buildState,
    canceledInfo,
    failedToStart,
    personal,
    detachedFromAgent,
  } = build
  const modifiers = []

  const userId = build.user?.id
  const isCurrentUser = userId != null && userId === myId
  if (debug) {
    modifiers.push('debug')
  } else if (personal === true && !ignorePersonal) {
    modifiers.push(isCurrentUser ? 'my' : 'personal')
  }

  if (canceledInfo != null && buildState !== 'running') {
    modifiers.push('canceled')
  } else if (failedToStart === true) {
    modifiers.push('failedToStart')
    if (onlyEmpty) {
      modifiers.push('empty')
    }
  } else if (buildState === 'queued') {
    modifiers.push(buildState)
  } else {
    if (buildState === 'running') {
      modifiers.push(buildState)

      if (detachedFromAgent) {
        modifiers.push('detached')
      }
    } else {
      modifiers.push('finished')
    }

    const isGreen = status == null || status === 'SUCCESS'
    modifiers.push(isGreen ? 'green' : 'red')
    if (!isGreen && onlyEmpty) {
      modifiers.push('empty')
    }
  }

  return modifiers.join('_')
}

export const getIsProjectOnOverview: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => boolean = (state, id) => {
  const toggling = id ? state.togglingOverview.project[id] : null
  return toggling != null ? toggling : id != null && getOverviewData(state).includes(id)
}
export const getIsBuildTypeOnOverview: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => boolean = (state, id) => {
  const toggling = id ? state.togglingOverview.bt[id] : null

  if (toggling != null) {
    return toggling
  }

  const projectId = getOwnerProjectId(state, id)

  if (!getIsProjectOnOverview(state, projectId)) {
    return false
  }

  const buildTypes = getOverviewProject(state, projectId)?.buildTypes?.buildType
  return buildTypes && id != null ? buildTypes.includes(id) : false
}

export const getStatuses: (arg0: State) => KeyValue<StatusKey, ProjectOrBuildTypeStatus> = state =>
  state.buildTypeStatuses
export const getStatus: (
  arg0: State,
  arg1: StatusRequest | null | undefined,
) => ProjectOrBuildTypeStatus | null | undefined = (state, request) =>
  request && getStatuses(state)[getStatusKey(request)]
export const getStatusByKey: (
  arg0: State,
  arg1: StatusKey,
) => ProjectOrBuildTypeStatus | null | undefined = (state, statusKey) =>
  getStatuses(state)[statusKey]
export const getIsBuildStarting: (
  arg0: State,
  arg1: BuildTypeId,
  arg2: Branch | null | undefined,
) => boolean = (state, buildTypeId, branch) =>
  Boolean(state.startingBuilds[getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))])
export const getExtensionEndpoint = (state: State, name: string) =>
  state.urlExtensions.find(e => e.name === name)
export const getExtensionEndpointsByKind: (
  arg0: State,
  arg1: string,
) => ReadonlyArray<UrlExtension<any>> = (state, kind) =>
  state.urlExtensions.filter(e => e.kind === kind)
export const generateClassicUILinkWithQuery = (
  href: string | null | undefined,
  params: QueryParams = {},
  hash?: string,
): string => {
  const url = parseURL(href ?? '')
  url.search = objectToQuery({...queryToObject(url.search), ...params, fromSakuraUI: 'true'})

  if (hash != null) {
    url.hash = hash
  }

  return url.href
}
export const getClassicUIFavoriteBuildsLink = (): string =>
  generateClassicUILinkWithQuery(resolveRelative('/favoriteBuilds.html'))
export const getClassicUIAgentsLink = (tab?: string | null | undefined): string =>
  generateClassicUILinkWithQuery(resolveRelative('/agents.html'), {
    tab,
  })
export const getClassicUIAgentLink = (
  id: AgentId,
  tab?: TabId | null | undefined,
  kind?: string | null | undefined,
): string =>
  generateClassicUILinkWithQuery(resolveRelative('/agentDetails.html'), {
    id: String(id),
    tab: tab != null ? stringifyId(tab) : null,
    kind,
  })
export const getClassicUIAgentPoolLink = (id: AgentPoolId): string =>
  generateClassicUILinkWithQuery(
    resolveRelative('/agents.html'),
    {
      tab: 'agentPools',
    },
    String(id),
  )
export const getClassicUICloudImageLink = (
  agentTypeId: AgentTypeId,
  tab?: string | null | undefined,
  kind?: string | null | undefined,
): string =>
  generateClassicUILinkWithQuery(
    resolveRelative('/agentDetails.html', {
      agentTypeId,
      tab,
      kind,
    }),
  )
export const getClassicUITestHistoryLink = ({
  testId,
  projectId,
  buildTypeId,
  branch,
}: {
  testId: TestId | null | undefined
  projectId: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  branch?: Branch | null | undefined
}): string => {
  const params: WritableKeyValue<string, string | null | undefined> = {
    testNameId: stringifyId(testId),
    projectId: stringifyId(projectId),
    buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
    tab: 'testDetails',
  }

  if (branch != null) {
    params[`branch_${params.projectId}`] = stringifyBranch(branch)
  }

  return generateClassicUILinkWithQuery(resolveRelative('/project.html'), params)
}

export const getClassicUIChangeLink = ({
  changeId,
  buildTypeId,
  personal,
  tab,
}: {
  changeId: ChangeId
  buildTypeId?: BuildTypeId | null | undefined
  personal?: boolean | null | undefined
  tab?: ClassicChangePageTabName | null | undefined
}): string =>
  generateClassicUILinkWithQuery(
    resolveRelative('/viewModification.html', {
      modId: stringifyId(changeId),
      buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
      personal: personal != null ? String(personal) : null,
      tab: tab ?? ClassicChangePageTabName.FILES,
    }),
  )

export const getClassicUIChangesLink = (): string =>
  generateClassicUILinkWithQuery(resolveRelative('/changes.html'))

export const getClassicUIDiffBetweenTwoFiles = ({
  changeId,
  personal,
  file,
}: Readonly<{
  changeId: ChangeId
  personal?: boolean
  file: string
}>): string =>
  resolveRelative(`/diffView.html`, {
    id: stringifyId(changeId),
    vcsFileName: file,
    personal: personal != null ? personal.toString() : 'false',
  })

export const getClassicUIQueueLink = (): string =>
  generateClassicUILinkWithQuery(resolveRelative('/queue.html'))

export const getClassicUIInvestigationsLink = (): string =>
  generateClassicUILinkWithQuery(resolveRelative('/investigations.html', {init: '1'}))

export const makeGetClassicUILinkWithBranch = (
  {projectId, buildTypeId, buildId}: ActiveEntityURLProps,
  branch?: Branch | null,
  params?: KeyValue<string, string | null | undefined> | null,
): ((state: State) => string) =>
  createSelector(
    (state: State) => projectId ?? getOwnerProjectId(state, buildTypeId),
    getFocusLine,
    getLogFilter,
    getExpandAll,
    (branchProjectId, focusLine, logFilter, expandAll) => {
      let webEntityType: WebEntityType | null = null
      let id: Id | null = null

      if (!buildId && buildTypeId) {
        webEntityType = WebEntityType.BUILD_TYPE
        id = buildTypeId
      } else if (projectId && projectId !== ROOT_PROJECT_ID) {
        webEntityType = WebEntityType.PROJECT
        id = projectId
      }

      const href =
        buildId != null
          ? resolveRelative('/viewLog.html')
          : ((webEntityType && resolveWebEntityLink(webEntityType, id)) ??
            resolveRelative('/overview.html'))
      const branchParam: string = buildId != null ? 'buildBranch' : getBranchParam(branchProjectId)
      return generateClassicUILinkWithQuery(href, {
        ...params,
        buildId: buildId != null ? stringifyId(buildId) : null,
        buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
        [branchParam]: branch && stringifyBranch(branch),
        _focus: focusLine?.toString() ?? undefined,
        filter: logFilter?.toString() ?? undefined,
        expand: expandAll ? 'all' : undefined,
      })
    },
  )
export const getShowQueuedBuildsPerBranch: (arg0: State, arg1: StatusKey) => boolean = (
  state,
  key,
) => state.showQueuedBuildsPerBranch[key] ?? false
export const getShowQueuedBuildsCount = (
  state: State,
  buildTypeId: BuildTypeId,
  branch?: Branch | null,
): number | undefined =>
  state.showQueuedBuildsCount[
    getStatusKey({
      type: 'bt',
      branch,
      id: buildTypeId,
    })
  ]
export const getShowQueuedBuildsInProject: (arg0: State, arg1: BuildTypeId) => boolean = (
  state,
  buildTypeId,
) => state.showQueuedBuildsInProject[buildTypeId] ?? false
export const isBuildLoaded: (arg0: State, arg1: BuildId | null | undefined) => boolean = (
  state,
  id,
) => (id ? getBuild(state, id) != null : false)
const getProjectDescriptionsHash: (arg0: State) => KeyValue<ProjectId, EntityDescription> = state =>
  state.entities.projectDescription
export const getProjectDescription: (
  arg0: State,
  arg1: ProjectId | null | undefined,
) => string | null | undefined = (state, id) =>
  id != null ? getProjectDescriptionsHash(state)[id]?.description : null
const getBuildTypeDescriptionsHash: (
  arg0: State,
) => KeyValue<BuildTypeId, EntityDescription> = state => state.entities.buildTypeDescription
export const getBuildTypeDescription: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => string | null | undefined = (state, id) =>
  id != null ? getBuildTypeDescriptionsHash(state)[id]?.description : null

export const buildTypeBranchesSectionCollapsedKey: (
  arg0: BuildTypeId,
  arg1: BranchesToShow,
) => string = (buildTypeId, branchesToShow) => `${stringifyId(buildTypeId)}_${branchesToShow}`
export const isBuildTypeBranchesSectionCollapsed: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
  arg2: BranchesToShow,
) => boolean = (state, buildTypeId, branchesToShow) => {
  if (buildTypeId == null || branchesToShow === BranchesToShow.DEFAULT) {
    return false
  }

  return getIsBlockIncluded(
    state,
    CollapsibleBlock.COLLAPSED_BRANCHES_SECTION,
    buildTypeBranchesSectionCollapsedKey(buildTypeId, branchesToShow),
  )
}
export const isProjectBuildTypeLineCollapsed: (
  arg0: State,
  arg1: BuildTypeId,
  arg2: boolean,
) => boolean = (state, buildTypeId, defaultCollapsed) =>
  getIsBlockIncluded(
    state,
    CollapsibleBlock.COLLAPSED_PROJECT_BUILDTYPELINE,
    stringifyId(buildTypeId),
    defaultCollapsed,
  )

export const isProjectPipelineLineCollapsed: (
  arg0: State,
  arg1: string,
  arg2: boolean,
) => boolean = (state, pipelineId, defaultCollapsed) =>
  getIsBlockIncluded(
    state,
    CollapsibleBlock.COLLAPSED_PROJECT_PIPELINE,
    pipelineId,
    defaultCollapsed,
  )

export const getIsAllSubtreeCollapsed: (
  arg0: State,
  arg1: ProjectsTree | null | undefined,
) => boolean = createSelector(
  (_: State, tree: ProjectsTree | null | undefined) => tree,
  (state: State) =>
    state.blocks[CollapsibleBlock.COLLAPSED_PROJECT_BUILDTYPELINE] ?? getEmptyHash(),
  (state: State) => state.blocks[CollapsibleBlock.COLLAPSED_SUBPROJECT] ?? getEmptyHash(),
  (subprojectTree, buildTypeLineBlocks, subprojectBlocks) => {
    if (!subprojectTree) {
      return false
    }

    for (let i = 0; i < subprojectTree.data.length; i++) {
      const item = subprojectTree.data[i]

      if (
        (item.itemType === 'buildType' && buildTypeLineBlocks[item.id!] !== false) ||
        (item.itemType === 'project' && subprojectBlocks[item.id] !== false)
      ) {
        return false
      }
    }

    return true
  },
)
export const getIsSubprojectCollapsed: (arg0: State, arg1: ProjectId, arg2?: boolean) => boolean = (
  state,
  projectId,
  defaultCollapsed = true,
) => getIsBlockIncluded(state, CollapsibleBlock.COLLAPSED_SUBPROJECT, projectId, defaultCollapsed)
export const getServerInfo: (arg0: State) => ServerInfo | null | undefined = state =>
  state.serverInfo
export const getTabUrl = (
  {projectId, buildTypeId, buildId}: ActiveEntityProps,
  tab: TabId,
): string | undefined => {
  if (projectId == null && buildTypeId == null && buildId == null) {
    return undefined
  }

  const currentSearch = new URLSearchParams(location.search)
  const tabValue = String(tab)

  if (buildId != null) {
    currentSearch.set('buildTab', tabValue)
  } else if (buildTypeId) {
    currentSearch.set('buildTypeTab', tabValue)
  } else {
    currentSearch.set('projectTab', tabValue)
  }

  return `${getOverviewHref({
    projectId,
    buildTypeId,
    buildId,
  })}?${currentSearch.toString()}`
}
const getChildBuildConfigurations: (
  arg0: State,
  arg1: ProjectId,
  arg2: boolean | null | undefined,
) => ReadonlyArray<BuildTypeId> = (state, projectId, onlyFavorites) => {
  const buildTypes =
    (onlyFavorites === true ? getOverviewProject : getProject)(state, projectId)?.buildTypes
      ?.buildType ?? emptyArray
  const pipelines =
    (onlyFavorites === true ? getOverviewProject : getProject)(state, projectId)?.pipelines
      ?.pipeline ?? emptyArray

  return [...buildTypes, ...pipelines]
}

export const getHasBuildConfigurations: (
  arg0: State,
  arg1: ProjectId,
  arg2: boolean | null | undefined,
) => boolean = (state, projectId, onlyFavorites) =>
  getChildBuildConfigurations(state, projectId, onlyFavorites).length > 0
export const getProjectId = (
  state: State,
  node?: ProjectOrBuildTypeNode | null,
): ProjectId | null | undefined => {
  if (node == null) {
    return null
  }

  switch (node.nodeType) {
    case 'project':
      return node.id

    case 'bt':
      return getBuildType(state, node.id)?.projectId

    default:
      return null
  }
}

export const getProjectIdFromBuildId = (
  state: State,
  buildId: BuildId | null | undefined,
): ProjectId | undefined => {
  const build = getBuild(state, buildId)
  return getBuildType(state, build?.buildType)?.projectId
}

export const makeGetProjectBuildTypes: () => (
  arg0: State,
  arg1: ProjectId,
) => ReadonlyArray<BuildType> = () =>
  createSelector(getProject, getBuildTypesHash, (project, buildTypes) => {
    const buildTypeIDs = project?.buildTypes?.buildType || emptyArray

    if (buildTypeIDs.length === 0) {
      return emptyArray
    }

    return buildTypeIDs.map(buildTypeId => buildTypes[buildTypeId]).filter(Boolean)
  })
export const getIsReadOnly: (arg0: State, arg1: ProjectId | null | undefined) => boolean = (
  state,
  projectId,
) => getProject(state, projectId)?.readOnlyUI?.value === true
const MAX_QUEUED_COUNT_FOR_AUTO_EXPAND = 3
export const shouldAutoExpandQueued = (state: State, key: StatusKey): boolean =>
  !getShowQueuedBuildsInBuildsList(state) &&
  (getStatusByKey(state, key)?.queued ?? 0) <= MAX_QUEUED_COUNT_FOR_AUTO_EXPAND
export const getAutoExpandQueuedBuilds: (arg0: State, arg1: StatusKey) => boolean = (
  state,
  statusKey,
) => state.queuedToggler.autoExpand[statusKey] === true
export const getHasTriggeredByMeBuilds: (
  arg0: State,
  arg1: BuildTypeId | null | undefined,
) => boolean = (state, buildTypeId) =>
  buildTypeId != null && state.queuedToggler.hasTriggeredByMeBuilds[buildTypeId] === true

export const getIsAgentTypeManageable = (state: State, agentTypeId: AgentTypeId): boolean =>
  isCloudImageManageable(getSingleCloudImage(state, agentTypeId))

const availibleTabNamesOnlyForQueuedBuild = [
  BuildPageTabNamesEnum.queuedBuildCompatibilityTab,
  BuildPageTabNamesEnum.queuedBuildOverviewTab,
]

export const tabExists: (
  arg0: State,
  arg1: TabParamsKey,
  arg2: TabId | null | undefined,
) => boolean = (state, tabParamsKey, tabId) => {
  const allowedTabs = getTabs(state, tabParamsKey)
  return allowedTabs.some(item => item.id === tabId)
}
export const getDefaultProjectTab: (arg0: State, arg1: ProjectId) => TabId = (state, projectId) => {
  const parameters = state.entities.projectParameters[projectId]?.parameters
  const tabParamsKey = getTabParamsKey({
    projectId,
  })
  const preferredTab = getPropertyFromList(BuildTypeProperties.DEFAULT_PROJECT_TAB, parameters)

  if (preferredTab && tabExists(state, tabParamsKey, preferredTab)) {
    return preferredTab
  }

  return ProjectPageTabNamesEnum.OVERVIEW
}

const getDefaultBuildTypeTab: (arg0: State, arg1: BuildTypeId) => TabId = (state, buildTypeId) => {
  const parameters = state.entities.buildTypeParameters[buildTypeId]?.parameters
  const tabParamsKey = getTabParamsKey({
    buildTypeId,
  })
  const preferredTab = getPropertyFromList(BuildTypeProperties.DEFAULT_BUILDTYPE_TAB, parameters)

  if (preferredTab && tabExists(state, tabParamsKey, preferredTab)) {
    return preferredTab
  }

  return BuildTypePageTabNamesEnum.OVERVIEW
}

const getIsAvailableBuildTab: (
  state: State,
  buildId: BuildId | undefined | null,
  tab: TabId | null | undefined,
) => boolean = (state, buildId, tab) => {
  const queued = getBuild(state, buildId)?.state === 'queued'

  if (buildId == null || tab == null) {
    return false
  }

  return queued ? true : !availibleTabNamesOnlyForQueuedBuild.includes(tab)
}

export const getCurrentBuildTypeTab = (state: State): TabId | null => state.buildTypeTab
export const getCurrentProjectPageTab = (state: State): TabId | null => state.projectPage.tab
export const getCurrentBuildTab = (state: State): TabId | null => state.buildTab
const projectTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(ProjectPageTabNamesEnum).includes(tab)
const buildTypeTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(BuildTypePageTabNamesEnum).includes(tab)
const buildTabPredefined: (arg0: TabId | null | undefined) => boolean = tab =>
  tab != null && Object.values(BuildPageTabNamesEnum).includes(tab)

interface CalcNextTabParams extends TabParams {
  tab: TabId | null | undefined
}

export const calcNextTab = (state: State, tabParams: CalcNextTabParams): TabId => {
  const {tab, buildId, buildTypeId, projectId} = tabParams
  const tabParamsKey = getTabParamsKey(tabParams)
  let defaultTab = BuildPageTabNamesEnum.OVERVIEW
  let tabPredefined = buildTabPredefined(tab)
  let availableTab = getIsAvailableBuildTab(state, buildId, tab)

  if (buildTypeId != null) {
    defaultTab = getDefaultBuildTypeTab(state, buildTypeId)
    tabPredefined = buildTypeTabPredefined(tab)
    availableTab = true
  }

  if (projectId != null) {
    defaultTab = getDefaultProjectTab(state, projectId)
    tabPredefined = projectTabPredefined(tab)
    availableTab = true
  }

  return tab && availableTab && (tabExists(state, tabParamsKey, tab) || tabPredefined)
    ? tab
    : defaultTab
}
