import Theme, {useThemeClasses} from '@jetbrains/ring-ui/components/global/theme'
import type {TooltipAttrs} from '@jetbrains/ring-ui/components/tooltip/tooltip'
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip'
import classNames from 'classnames'

import styles from './DarkTooltip.css'

export default function DarkTooltip(props: TooltipAttrs) {
  const themeClasses = useThemeClasses(Theme.DARK)

  return (
    <Tooltip
      {...props}
      popupProps={{
        top: 0,
        offset: 4,
        ...props.popupProps,
        className: classNames(styles.popup, themeClasses, props.popupProps?.className),
      }}
    />
  )
}
