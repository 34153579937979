import {ROOT_PROJECT_ID} from 'src/types'

export const pipelinesAppId = 'PipelineApp'

export const mainVcsRootIdSuffix = 'Main'

// This id is used instead of ROOT_PROJECT_ID to support multi-tenant TeamCity Cloud setups, aka
// TeamCity Dorm (multiple customers using one TeamCity Cloud instance).
// In such a setup, each customer has their own root project that this id points to.
// This id SHOULD NOT be used in requests related to OAuth connections, as these are currently
// defined at the ROOT_PROJECT_ID level. Use regular ROOT_PROJECT_ID instead.
// In a single-tenant setup, this id is equal to ROOT_PROJECT_ID.
// https://youtrack.jetbrains.com/issue/TCP-917/Support-dormitory-in-Pipelines
export const getAccountRootProjectId = () =>
  window.CloudReactUISettings?.accountProjectExternalId ?? ROOT_PROJECT_ID
