import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../utils/makeResource'

import type {HttpsConfigurationPageComponent} from './HttpsConfigurationPage'
import {query} from './HttpsConfigurationPage.queries'

export const HttpsConfigurationPageEntrypoint: EntryPoint<HttpsConfigurationPageComponent> = {
  root: makeResource(
    'HttpsConfigurationPage',
    () => import(/* webpackChunkName: "HttpsConfigurationPage" */ './HttpsConfigurationPage'),
  ),
  getPreloadProps: () => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
