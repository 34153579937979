import classNames from 'classnames'
import type {MouseEvent} from 'react'
import {useCallback} from 'react'
import * as React from 'react'

import {useBoolean} from '../../../hooks/useSlice'
import IconButton from '../../../library/components/IconButton/IconButton'
import {copyClipboard} from '../../../utils/clipboard'
import isVisualTest from '../../../utils/isVisualTest'
import Popup from '../Popup/Popup.lazy'

import styles from './CopyIconButton.css'

type Props = {
  iconClassName?: string
  wrapperClassName?: string
  text?: string | undefined | null
  title?: string
  onClick?: React.MouseEventHandler
  beforeCopyContent?: React.ReactNode
  afterCopyContent?: React.ReactNode
  withBorder?: boolean
  dataTestId?: string
}

const isTest = process.env.NODE_ENV === 'test' || isVisualTest()

function CopyIconButton({
  onClick,
  iconClassName,
  wrapperClassName,
  text = '',
  title = 'Copy to clipboard',
  beforeCopyContent,
  afterCopyContent,
  withBorder,
  dataTestId = 'copy-button',
}: Props) {
  const hasContent = Boolean(beforeCopyContent && afterCopyContent)
  const [copiedToClipboard, setCopiedToClipboard, unsetCopiedToClipboard] = useBoolean(false)

  const popupContent = React.useMemo(() => <span>{'Copied!'}</span>, [])

  const onCopyText = useCallback(
    async (e: MouseEvent<any>) => {
      e.preventDefault()
      e.stopPropagation()

      if (text) {
        await copyClipboard(text)
      }
      onClick?.(e)
      setCopiedToClipboard()
    },
    [text, onClick, setCopiedToClipboard],
  )
  return isTest || (navigator.clipboard != null && (text || hasContent)) ? (
    <div className={classNames(wrapperClassName, styles.wrapper)}>
      <Popup className={styles.popup} hidden={hasContent || !copiedToClipboard} trapFocus={false}>
        {popupContent}
      </Popup>
      <IconButton
        data-test={dataTestId}
        className={iconClassName}
        icon="copy"
        title={!copiedToClipboard ? title : ''}
        onClick={onCopyText}
        onMouseLeave={unsetCopiedToClipboard}
        onBlur={unsetCopiedToClipboard}
        withBorder={withBorder}
      >
        {hasContent && (copiedToClipboard ? afterCopyContent : beforeCopyContent)}
      </IconButton>
    </div>
  ) : null
}

export default React.memo(CopyIconButton)
