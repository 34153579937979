import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import Link from '@jetbrains/ring-ui/components/link/link'
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip'
import classnames from 'classnames'
import type {ReactElement, ReactNode} from 'react'
import * as React from 'react'

import Icon from '../Icon/Icon'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import Popup from '../Popup/Popup.lazy'

import styles from './LinkWithIcon.css'

type Props = {
  children?: string | null
  icon?: string | null
  className?: string
  iconClassName?: string
  iconInnerClassName?: string
  iconPlaceholder?: ReactElement | null
  iconTitle?: ReactNode | null
  textTitle?: string | null
  title?: string
  tailLength?: number
  side?: 'left' | 'right'
  href: string
  relative?: boolean
  onPlainLeftClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  leftNodes?: ReactNode
  rightNodes?: ReactNode
  noLink?: boolean
  withTooltip?: boolean
  onShowIconTitle?: () => void
  onHideIconTitle?: () => void
}

const DEFAULT_TAIL_LENGTH = 5
function LinkWithIcon({
  children,
  icon,
  className,
  iconClassName,
  iconInnerClassName,
  iconPlaceholder = null,
  side = 'left',
  tailLength = DEFAULT_TAIL_LENGTH,
  iconTitle,
  textTitle,
  leftNodes,
  rightNodes,
  noLink,
  withTooltip,
  onShowIconTitle,
  onHideIconTitle,
  ...restProps
}: Props) {
  const iconClasses = classnames(styles.icon, iconClassName, styles[side])
  const classes = classnames(styles.link, className)
  const popupClasses = classnames(styles.popup, 'popupDiv')
  const iconDiv =
    icon != null ? (
      <div className={iconClasses}>
        <Icon className={iconInnerClassName} name={icon} />
      </div>
    ) : (
      iconPlaceholder
    )
  const childrenWithEllipsis = React.useMemo(
    () =>
      children != null ? (
        <MiddleEllipsis
          title={withTooltip ? null : textTitle}
          tailLength={tailLength}
          className={classnames({[styles.label]: withTooltip})}
        >
          {children}
        </MiddleEllipsis>
      ) : null,
    [children, tailLength, textTitle, withTooltip],
  )

  const props = {
    ...restProps,
    className: classes,
    'data-test-link-with-icon': icon != null ? icon : 'none',
  }
  const content = (
    <>
      {leftNodes}
      {iconTitle != null ? (
        <Dropdown
          anchor={iconDiv ?? ''}
          hoverMode
          clickMode={false}
          onShow={onShowIconTitle}
          onHide={onHideIconTitle}
        >
          <Popup className={popupClasses}>{iconTitle}</Popup>
        </Dropdown>
      ) : (
        iconDiv
      )}
      {children != null && (
        <div className={styles.text}>
          {withTooltip ? (
            <>
              <Tooltip
                long
                title={textTitle}
                delay={500}
                selfOverflowOnly
                className={styles.tooltip}
              >
                {childrenWithEllipsis}
              </Tooltip>
            </>
          ) : (
            childrenWithEllipsis
          )}
        </div>
      )}
      {rightNodes}
    </>
  )

  return noLink ? <span {...props}>{content}</span> : <Link {...props}>{content}</Link>
}

export default LinkWithIcon
