import {branch, mapProps, renderNothing} from 'recompose'

import compose from 'lodash/flowRight'
import type {ComponentType} from 'react'
import {connect} from 'react-redux'

import type {DialogProps} from '../../../../hocs/withDialogContainer'
import withDialogContainer from '../../../../hocs/withDialogContainer'
import type {State} from '../../../../reducers/types'
import {getIsSakuraUI} from '../../../../selectors'
import type {ProjectId, ProjectInternalId, TestId, TestOccurrenceId} from '../../../../types'
import {DialogType, stringifyId} from '../../../../types'
import {getTestOccurrence} from '../../Tests/Tests.selectors'
import InvestigationHistoryPopup from '../InvestigationHistoryPopup/InvestigationHistoryPopup'

type OwnProps = {
  testOccurrenceId?: TestOccurrenceId
}
type StateProps = {
  projectId: ProjectId | null | undefined
  projectInternalId: ProjectInternalId | null | undefined
  testId: TestId | null | undefined
  testName: string | null | undefined
  isSakuraUI: boolean
}
type OwnPropsDummy = {
  projectId: ProjectId | null | undefined
  projectInternalId: ProjectInternalId | null | undefined
  testId: TestId | null | undefined
  testName: string | null | undefined
}

const mapStateToProps: (arg0: State, arg1: OwnProps) => StateProps = (
  state,
  {testOccurrenceId},
) => {
  const testOccurrence = getTestOccurrence(state, testOccurrenceId)
  const project = testOccurrence?.build?.buildType?.project

  return {
    projectId: project?.id,
    testId: testOccurrence?.test?.id,
    testName: testOccurrence?.name,
    projectInternalId: project?.internalId,
    isSakuraUI: getIsSakuraUI(state),
  }
}

export const TestInvestigationHistoryPopupDummy: ComponentType<OwnPropsDummy> = compose(
  withDialogContainer(DialogType.INVESTIGATION_HISTORY, (state, props: OwnPropsDummy) =>
    stringifyId(props.testId),
  ),
  mapProps((props: DialogProps<OwnPropsDummy>) => ({
    ...props,
    subTitle: `Test name – ${props.testName ?? 'Unknown'}`,
    locator: `projectId=${stringifyId(props.projectId)}&testNameId=${stringifyId(props.testId)}`,
  })),
  branch((props: DialogProps<OwnPropsDummy>) => !props.isOpened, renderNothing),
)(InvestigationHistoryPopup)
export default connect(mapStateToProps, {})(TestInvestigationHistoryPopupDummy)
