import type {DumpOptions} from 'js-yaml'
import {dump, load} from 'js-yaml'

import type {SerializedError} from '@reduxjs/toolkit'
import type {FetchBaseQueryError} from '@reduxjs/toolkit/src/query/fetchBaseQuery'
import omit from 'lodash/omit'

import type {Properties} from '../../../../services/rest'
import {internalProps} from '../../../../types/BS_types'
import {extractErrorMessage} from '../../../../utils/extractError'
import type {NewIntegrationDto} from '../services/pipelinesApi.types'
import type {Dependency, PipelineSettings, RunsOn, SelfHostedRunsOn} from '../types'
import {ParameterNames} from '../types'

export const hasPipelineHeadParameter = (parameters: Properties | null | undefined) =>
  parameters?.property?.some(property => property.name === ParameterNames.PIPELINE_HEAD)

export const parsePipeline = load as (yaml: string) => PipelineSettings
export const stringifyPipeline: (pipeline: PipelineSettings, opts?: DumpOptions) => string = dump

export const getDependencyName = (dependency: Dependency) =>
  typeof dependency === 'string' ? dependency : Object.keys(dependency)[0]

export const getPipelinesSettingsWithoutDeprecatedFields = (settings: PipelineSettings) =>
  omit(settings, ['name'])

export const getPreformattingSettings = (key: string, value: any) => {
  const isJobsSection = key === 'jobs'
  const isValueString = typeof value === 'string'

  if (isValueString) {
    return value.trim()
  }

  if (isJobsSection && typeof value === 'object') {
    return Object.keys(value).reduce((accumulator, jobName) => {
      const jobValue = value[jobName]
      const jobNameFormatted = jobName.trim()

      return {
        ...accumulator,
        [jobNameFormatted]: jobValue,
      }
    }, {})
  }

  return value
}

export const getPreformattingIntegrations = (draft: NewIntegrationDto[]) => {
  draft?.forEach(({id, parameters}, index) => {
    const isNewIntegration = id === parameters.displayName

    if (isNewIntegration) {
      delete draft[index].id
    }

    for (const [key, value] of Object.entries(parameters)) {
      if (value == null) {
        delete parameters[key]
      }
    }
  })
}

export const getCustomAgents = (): Array<{name: string}> => {
  const customAgentsProp = internalProps['teamcity.pipelines.customAgentsNames'] || null
  return customAgentsProp
    ? customAgentsProp
        .split(',')
        .filter(Boolean)
        .map((i: string) => ({name: i.trim()}))
    : []
}

export const jobLimits = {
  soft: window.internalProps['teamcity.pipelines.jobCountSoftLimit'],
  hard: window.internalProps['teamcity.pipelines.jobCountHardLimit'],
}

const unknownErrorMessage = 'Unknown error'
export const getExtractErrorMessage = (
  error: SerializedError | FetchBaseQueryError | string | null | undefined,
) => {
  try {
    if (error != null) {
      const extractedError = extractErrorMessage(error)

      if (typeof extractedError === 'string') {
        const errors = JSON.parse(extractedError).errors

        if (Array.isArray(errors)) {
          return errors.map(({message}) => message).join('\n')
        }

        return extractedError
      }
    }
  } catch (e) {
    return unknownErrorMessage
  }

  return unknownErrorMessage
}

export function isSelfHostedRunsOn(runsOn: RunsOn | undefined): runsOn is SelfHostedRunsOn {
  return typeof runsOn === 'object' ? 'self-hosted' in runsOn : runsOn === 'self-hosted'
}
