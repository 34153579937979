/**
 * @generated SignedSource<<3ab1f32181e4eecebc58ab76f3648cfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildProblemsPreviewQuery$variables = {
  locator: string;
};
export type BuildProblemsPreviewQuery$data = {
  readonly builds: {
    readonly " $fragmentSpreads": FragmentRefs<"BuildProblemsPreviewDependencyIdsFragment">;
  } | null | undefined;
};
export type BuildProblemsPreviewQuery = {
  response: BuildProblemsPreviewQuery$data;
  variables: BuildProblemsPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildProblemsPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuildProblemsPreviewDependencyIdsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildProblemsPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1d741597e2b7e5db33d8e28afab8476",
    "id": null,
    "metadata": {},
    "name": "BuildProblemsPreviewQuery",
    "operationKind": "query",
    "text": "query BuildProblemsPreviewQuery(\n  $locator: String!\n) {\n  builds(locator: $locator) {\n    ...BuildProblemsPreviewDependencyIdsFragment\n  }\n}\n\nfragment BuildProblemsPreviewDependencyIdsFragment on Builds {\n  build {\n    id\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "28a2967f36465d522067bb678ea2e0a1";

export default node;
