import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {PipelineBreadcrumbsComponent} from './PipelineBreadcrumbs'
import {query} from './PipelineBreadcrumbs.queries'

const PipelineBreadcrumbsEntryPoint: EntryPoint<PipelineBreadcrumbsComponent, LoaderFunctionArgs> =
  {
    root: makeResource(
      'PipelineBreadcrumbs',
      () =>
        import(
          /* webpackChunkName: "PipelineBreadcrumbs", webpackPrefetch: true */
          './PipelineBreadcrumbs'
        ),
    ),
    getPreloadProps({params}) {
      const {pipelineId} = params

      return {
        queries: {
          main: {
            parameters: getRequest(query),
            variables: {
              pipelineLocator: `id:${pipelineId}`,
              fetchPipeline: pipelineId != null,
            },
          },
        },
      }
    },
  }
export default PipelineBreadcrumbsEntryPoint
