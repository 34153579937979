import './utils/whyDidYouRender'
import Button from '@jetbrains/ring-ui/components/button/button'
import type {TeamCityAPIType} from '@jetbrains/teamcity-api'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as ReactDOMClient from 'react-dom/client'
import '@jetbrains/ring-ui/components/loader-inline/loader-inline.css'
import './injectConnectionIconStyles'
import deprecate from 'util-deprecate'

import {HINT_CATEGORY} from './components/packages/Hints/Hints.types'
import IconButton from './library/components/IconButton/IconButton'
import SvgIcon from './library/components/SvgIcon/SvgIcon'
import {Plugin, TabPlugin} from './pluginSystem/pluginSystem.prototype'
import pluginRegistry from './pluginSystem/pluginSystem.registry'
import ReactUI from './reactui'
import processResponse, {processTextResponse} from './rest/processResponse'
import request from './rest/request'
import type {RestRequestOptions} from './rest/request'
import {base_uri} from './types/BS_types'
import {resolveRelative} from './utils/url'

function requestJSON<T>(endpoint: string, options?: RestRequestOptions): Promise<T> {
  return request(base_uri, endpoint, options).then<T>(processResponse)
}

function requestText(endpoint: string, options?: RestRequestOptions): Promise<string> {
  return request(base_uri, endpoint, options).then(processTextResponse)
}

function render<P extends {} | null | undefined>(
  elementOrId: HTMLElement | string,
  Type: React.ComponentType<P>,
  props: P & React.JSX.IntrinsicAttributes,
) {
  ReactUI.renderConnected(elementOrId, Type, props)
}

const Components = {
  Button,
  IconButton,
  SvgIcon,
  AllBuilds: deprecate(() => null, 'TeamcityAPI.Components.AllBuilds is no longer supported'), // probably never used
  ServiceMessage: deprecate(
    () => null,
    'TeamcityAPI.Components.ServiceMessage is no longer supported',
  ),
  BuildNumber: deprecate(() => null, 'TeamcityAPI.Components.BuildNumber is no longer supported'),
  ContentPanel: ReactUI.ContentPanelAPI,
  EntityPath: ReactUI.EntityPathAPI,
  RouterLink: ReactUI.RouterLinkAPI,
  RouterButton: ReactUI.RouterButtonAPI,
}
const Services = {
  AlertService: ReactUI.AlertService,
  HintsService: {
    registerHint: ReactUI.registerHint.bind(ReactUI),
    unregisterHint: ReactUI.unregisterHint.bind(ReactUI),
    categories: HINT_CATEGORY,
  },
  REST: {
    requestJSON,
    requestText,
  },
}
const utils = {
  requestJSON,
  requestText,
  addMarkdownAlert: ReactUI.addMarkdownAlert.bind(ReactUI),
  resolveRelativeURL: resolveRelative,
  isSakuraUI: (): boolean => window.ReactUI.isSakuraUI,
}

const TC = {
  Components,
  Services,
  render,
  utils,
  pluginRegistry,
  Plugin,
  TabPlugin,
} as TeamCityAPIType
export {
  TC as default,
  render,
  utils,
  pluginRegistry,
  Plugin,
  TabPlugin,
  Components,
  Services,
  React,
  ReactDOM,
  ReactDOMClient,
}
