import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {Policy} from '../../../rest/branches'
import {getProjectFilter, ProjectPageTabNamesEnum, ROOT_PROJECT_ID} from '../../../types'
import type {
  ThinNestedEntryPointParamsObject,
  ThinQueryParamsObject,
} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {
  branchesQuery,
  getBranchesQueryVariables,
} from '../../common/BranchSelect/BranchSelect.queries'

import {ProjectChangeLogTabEntryPoint} from './ProjectChangeLogTab/ProjectChangeLogTab.entryPoint'
import {ProjectInvestigationsTabEntryPoint} from './ProjectInvestigationsTab/ProjectInvestigationsTab.entryPoint'
import type {
  ProjectPageOuterContainerComponent,
  NestedEntryPoints,
  Queries,
} from './ProjectPage.container'
import {projectPageQuery} from './ProjectPage.queries'

export const ProjectPageEntryPoint: EntryPoint<
  ProjectPageOuterContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'ProjectPage',
    () => import(/* webpackChunkName: "ProjectPage" */ './ProjectPage.container'),
  ),
  getPreloadProps: ({params, request}) => {
    const {projectId = ''} = params
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const url = new URL(request.url)
    const {projectTab, branch} = queryToObject(url.search)
    switch (projectTab) {
      case ProjectPageTabNamesEnum.INVESTIGATIONS:
        entryPoints.investigationsTab = {
          entryPoint: ProjectInvestigationsTabEntryPoint,
          entryPointParams: projectId,
        }
        break
      case ProjectPageTabNamesEnum.CHANGE_LOG:
        entryPoints.changeLogTab = {
          entryPoint: ProjectChangeLogTabEntryPoint,
          entryPointParams: {projectId, branch: parseBranch(branch)},
        }
        break
      default:
    }
    const queries: ThinQueryParamsObject<Queries> = {
      main: {
        parameters: getRequest(projectPageQuery),
        variables: {locator: `id:${projectId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    }
    const isOverview = projectId === ROOT_PROJECT_ID
    if (!isOverview && projectTab !== ProjectPageTabNamesEnum.PROBLEMS) {
      queries.branches = {
        parameters: getRequest(branchesQuery),
        variables: getBranchesQueryVariables({
          node: getProjectFilter(projectId),
          policy: Policy.ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES,
          includeSubprojects: true,
        }),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      }
    }

    return {
      queries,
      entryPoints,
    }
  },
}
