import Checkbox from '@jetbrains/ring-ui/components/checkbox/checkbox'
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import Link from '@jetbrains/ring-ui/components/link/link'
import Text from '@jetbrains/ring-ui/components/text/text'
import * as React from 'react'
import type {SyntheticEvent} from 'react'

import IconButton from '../../../../library/components/IconButton/IconButton'
import {restApi} from '../../../../services/rest'
import {BS} from '../../../../types/BS_types'
import {emptyArray} from '../../../../utils/empty'
import filesize from '../../../../utils/filesize'
import {resolveRelative} from '../../../../utils/url'
import HelpLink from '../../../common/HelpLink/HelpLink'
import Loader from '../../../common/Loader/Loader'
import Popup from '../../../common/Popup/Popup.lazy'

import styles from './MetricsTab.css'

type MetricTag = {
  name: string
  value: string
}

type MetricTagCollection = {
  count: number
  metricTag: MetricTag[]
}

type MetricValue = {
  name: string
  value: number
}

type MetricValueCollection = {
  count: number
  metricValue: MetricValue[]
}

type MetricRecord = {
  name: string
  description: string
  prometheusName: string
  metricTags?: MetricTagCollection
  metricValues?: MetricValueCollection
}

function MetricsTab({helpUrl}: {helpUrl: string}) {
  const expKey = 'teamcity.metrics.experimental'
  const initialExperimental = localStorage.getItem(expKey) != null || false
  const [experimental, setExperimental] = React.useState(initialExperimental)
  const {hasData, metricRecords} = restApi.endpoints.getAllMetrics.useQuery(
    {
      fields: `metric,count${experimental ? ',experimental' : ''}`,
    },
    {
      selectFromResult: ({data}) => ({
        hasData: data != null,
        metricRecords: data?.metric ?? emptyArray,
      }),
    },
  )
  const experimentalToggle = React.useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked
    setExperimental(checked)

    if (checked) {
      localStorage.setItem(expKey, 'true')
    } else {
      localStorage.removeItem(expKey)
    }
  }, [])

  const groupMetricRecordsByName = (records: readonly MetricRecord[]) => {
    const result = []
    let lastRecord: MetricRecord | null = null
    let currentGroup: MetricRecord[] = []
    records.forEach(record => {
      if (lastRecord == null || lastRecord.name !== record.name) {
        if (currentGroup.length) {
          result.push(currentGroup)
        }

        lastRecord = record
        currentGroup = []
      }

      currentGroup.push(record)
    })

    if (currentGroup.length) {
      result.push(currentGroup)
    }

    return result
  }

  const groups = groupMetricRecordsByName(metricRecords)
  BS?.Log?.warn(groups)
  return (
    <React.Fragment>
      <div className={styles.topbar}>
        <Checkbox checked={experimental} onChange={experimentalToggle}>
          {'Show experimental metrics'}
        </Checkbox>

        <HelpLink url={helpUrl} Component={IconButton} icon="help" />

        <div className={styles.metricsLink}>
          <Link
            href={resolveRelative(experimental ? '/app/metrics?experimental=true' : '/app/metrics')}
          >
            {'metrics in Prometheus format'}
          </Link>
        </div>
      </div>
      {hasData && groups.length > 0 ? <MetricList metricBunches={groups} /> : <Loader />}
    </React.Fragment>
  )
}

type MetricListProps = {
  metricBunches: MetricRecord[][]
}
function MetricList(props: MetricListProps) {
  const groups = props.metricBunches
  const namedMetricGroups = groups.map(group => (
    <MetricsWithName metrics={group} key={group[0].name} />
  ))
  return (
    <table className={styles.metricsTable}>
      <thead>
        <tr className={styles.metricRow}>
          <th>{'Description'}</th>
          <th>{'Name'}</th>
          <th>
            <div className={styles.headerTagsValues}>
              <div>{'Tags'}</div>
              <div>{'Values'}</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{namedMetricGroups}</tbody>
    </table>
  )
}

type MetricsWithNameProps = {
  metrics: MetricRecord[]
}
function MetricsWithName(props: MetricsWithNameProps) {
  const metrics = props.metrics
  const childMetrics = metrics.map(metric => {
    const key = `${metric.name}_${JSON.stringify(metric.metricTags)}`
    return <Metric metric={metric} key={key} />
  })

  const experimental = () => {
    const tags = metrics[0].metricTags

    if (!tags) {
      return false
    }

    let result = false
    tags.metricTag.forEach(t => {
      result = result || t.name === 'experimental'
    })
    return result
  }

  let rowClassNames = styles.metricRow

  if (experimental()) {
    rowClassNames += ` ${styles.metricRowExperimental}`
  }

  return (
    <tr className={rowClassNames}>
      <td className={styles.metricDescription}>{metrics[0].description}</td>
      <td className={styles.metricName}>
        <Dropdown
          anchor={() => <Text>{metrics[0].prometheusName}</Text>}
          clickMode={false}
          hoverMode
          hoverShowTimeOut={1000}
        >
          <Popup>
            <div className={styles.metricNameTooltip} title={'TeamCity metric code'}>
              {metrics[0].name}
            </div>
          </Popup>
        </Dropdown>
      </td>
      <td className={styles.metricRecords}>{childMetrics}</td>
    </tr>
  )
}

type MetricProps = {
  metric: MetricRecord
}
function Metric(props: MetricProps) {
  const metric = props.metric
  return (
    <div className={styles.metricRecord}>
      <MetricTags tags={metric.metricTags} />
      <MetricValues values={metric.metricValues} name={metric.prometheusName} />
    </div>
  )
}

type MetricTagsProps = {
  tags?: MetricTagCollection
}
function MetricTags(props: MetricTagsProps) {
  if (!props.tags) {
    return <div className={styles.metricTags} />
  }

  const tags = (props.tags.metricTag || [])
    .filter(tagData => tagData.name !== 'experimental')
    .map(tagData => (
      <div className="metric__tag" key={tagData.name}>
        <div>
          {tagData.name}
          {'='}
          {tagData.value}
        </div>
      </div>
    ))
  return <div className={styles.metricTags}>{tags}</div>
}

type MetricValuesProps = {
  values?: MetricValueCollection
  name: string
}
function MetricValues(props: MetricValuesProps) {
  if (!props.values) {
    return <div className={styles.metricValues} />
  }

  const formatNumber = (v: number) => {
    const fractionDigits = 5
    const valueString = v.toString()
    const dotPos = valueString.lastIndexOf('.')

    if (dotPos > 0 && valueString.length - dotPos > fractionDigits) {
      return v.toFixed(fractionDigits)
    }

    return valueString
  }

  const bytes = props.name.indexOf('_bytes') > 0
  const valuesData = props.values.metricValue || []
  const values = valuesData.map(data => {
    const v = bytes ? (filesize(data.value) as string) : formatNumber(data.value)
    return (
      <div key={data.name} className={styles.metricValue} title={String(data.value)}>
        {valuesData.length > 1 ? `${data.name}=${v}` : v}
      </div>
    )
  })
  return <div className={styles.metricValues}>{values}</div>
}

export default MetricsTab
