import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {BuildTypeId} from '../../../../types'
import {makeResource} from '../../../../utils/makeResource'

import type {DependenciesTabComponent} from './DependenciesTab'
import {getDependenciesTabQueryVariables, dependenciesTabQuery} from './DependenciesTab.queries'

type DependenciesTabEntryPointParams = {
  buildTypeId: BuildTypeId
  buildLocator: string
}

export const DependenciesTabEntryPoint: EntryPoint<
  DependenciesTabComponent,
  DependenciesTabEntryPointParams
> = {
  root: makeResource(
    'DependenciesTab',
    () => import(/* webpackChunkName: "DependenciesTab" */ './DependenciesTab'),
  ),
  getPreloadProps: ({buildTypeId, buildLocator}) => ({
    queries: {
      main: {
        parameters: getRequest(dependenciesTabQuery),
        variables: getDependenciesTabQueryVariables(buildTypeId, buildLocator),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
