export const updatePageOffset = (width: number) => {
  const sidebarPlaceholder = document.getElementById('projectsSidebarWrapper')
  const pageContent = document.getElementById('pageContentWrapper')

  if (sidebarPlaceholder) {
    sidebarPlaceholder.style.width = `${width}px`
  }
  if (pageContent) {
    pageContent.style.marginLeft = `${width}px`
  }
}
