import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuild} from '../../../selectors'
import BuildCommentByIcon from '../BuildCommentByIcon/BuildCommentByIcon'

import type {Props} from './BuildStatusChangeComment.types'

const mapStateToProps = (state: State, props: Props) => {
  const {buildType, status = 'UNKNOWN', statusChangeComment} = getBuild(state, props.buildId) || {}
  const {user, timestamp, text} = statusChangeComment || {}
  let markedStatus: string = status

  if (status === 'FAILURE') {
    markedStatus = 'failed'
  }

  if (status === 'SUCCESS') {
    markedStatus = 'successful'
  }

  const commentText =
    text != null
      ? `Marked as ${markedStatus} with comment: ${text}`
      : `Marked as ${markedStatus} manually`
  return {
    timestamp,
    user,
    text: statusChangeComment != null && status !== 'UNKNOWN' ? commentText : null,
    buildTypeId: buildType,
  }
}

export default connect(mapStateToProps)(BuildCommentByIcon)
