import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {branchExistsInBuildTypeQueryDefinition} from '../../../queries/branchExistsInBuildType'
import {getBuildTypeHasNonDefaultBranchesVariables, Policy} from '../../../rest/branches'
import {
  BuildTypePageTabNamesEnum,
  defaultBuildTypePageTabName,
  getBuildTypeFilter,
} from '../../../types'
import type {
  ThinNestedEntryPointParamsObject,
  ThinQueryParamsObject,
} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {
  branchesQuery,
  getBranchesQueryVariables,
} from '../../common/BranchSelect/BranchSelect.queries'
import {
  getPermalinksQueryVariables,
  permalinksQuery,
} from '../../common/Permalinks/Permalinks.queries'
import {TagsFiltersEntryPoint} from '../../common/TagsList/TagsFilters.entryPoint'

import {BuildTypeChangeLogTabEntryPoint} from './BuildTypeChangeLogTab/BuildTypeChangeLogTab.entryPoint'
import {
  buildTypeHistoryQuery,
  getBuildTypeHistoryQueryVariables,
} from './BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.queries'
import {defaultMode, Modes} from './BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import type {BuildTypePageContainerComponent, NestedEntryPoints, Queries} from './BuildTypePage'
import {query} from './BuildTypePage.queries'
import {PendingChangesTabEntryPoint} from './PendingChangesTab/PendingChangesTab.entryPoint'

export const BuildTypePageEntryPoint: EntryPoint<
  BuildTypePageContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'BuildTypePage',
    () => import(/* webpackChunkName: "BuildTypePage" */ './BuildTypePage'),
  ),
  getPreloadProps: ({params, request}) => {
    const {buildTypeId = ''} = params
    const url = new URL(request.url)
    const {
      branch,
      buildTypeTab = defaultBuildTypePageTabName,
      mode = defaultMode,
    } = queryToObject(url.search)
    const queries: ThinQueryParamsObject<Queries> = {
      main: {
        parameters: getRequest(query),
        variables: {locator: `id:${buildTypeId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      branches: {
        parameters: getRequest(branchesQuery),
        variables: getBranchesQueryVariables({
          node: getBuildTypeFilter(buildTypeId),
          policy: Policy.ALL_BRANCHES,
        }),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      withBranches: {
        parameters: getRequest(branchExistsInBuildTypeQueryDefinition),
        variables: getBuildTypeHasNonDefaultBranchesVariables(buildTypeId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    }
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const parsedBranch = parseBranch(branch)
    switch (buildTypeTab) {
      case BuildTypePageTabNamesEnum.CHANGE_LOG:
        entryPoints.changeLogTab = {
          entryPoint: BuildTypeChangeLogTabEntryPoint,
          entryPointParams: {buildTypeId, branch: parsedBranch},
        }
        break
      case BuildTypePageTabNamesEnum.PENDING_CHANGES:
        entryPoints.pendingChangesTab = {
          entryPoint: PendingChangesTabEntryPoint,
          entryPointParams: {buildTypeId, branch: parsedBranch},
        }
        break
      case BuildTypePageTabNamesEnum.OVERVIEW:
      default:
        if (mode === Modes.BUILDS) {
          queries.buildTypeHistory = {
            parameters: getRequest(buildTypeHistoryQuery),
            variables: getBuildTypeHistoryQueryVariables({
              buildTypeId,
              branch: parsedBranch,
              withRunningAndQueued: true,
            }),
            options: {networkCacheConfig: {metadata: {essential: true}}},
          }
          queries.permalinks = {
            parameters: getRequest(permalinksQuery),
            variables: getPermalinksQueryVariables(buildTypeId, branch),
            options: {networkCacheConfig: {metadata: {essential: true}}},
          }
          entryPoints.tagsFilters = {
            entryPoint: TagsFiltersEntryPoint,
            entryPointParams: buildTypeId,
          }
        }
    }
    return {queries, entryPoints}
  },
}
