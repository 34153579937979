import {createSelector} from 'reselect'

import type {State} from '../../../../reducers/types'

export const getPipelinesStateToPersist = createSelector(
  (state: State) => state.pipelines.pipelineDraft,
  (state: State) => state.pipelines.collapsedBlocks,
  (state: State) => state.pipelines.debug,
  (pipelineDraft, collapsedBlocks, debug) => ({
    pipelineDraft,
    collapsedBlocks,
    debug,
  }),
)
