import Link from '@jetbrains/ring-ui/components/link/link'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import {skipToken} from '@reduxjs/toolkit/query'
import classNames from 'classnames'
import * as React from 'react'
import {Suspense} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {shallowEqual} from 'react-redux'
import {graphql, useLazyLoadQuery} from 'react-relay'

import {useAppSelector} from '../../../hooks/react-redux'
import {useBooleanQueryParamState} from '../../../hooks/routes'
import {getExtensionEndpointsByKind} from '../../../selectors'
import {buildArtifactsApi} from '../../../services/buildArtifacts'
import {stringifyId} from '../../../types'
import {focusSelfOrChildLink} from '../../../utils/dom'
import {emptyArray} from '../../../utils/empty'
import {useSandbox} from '../../../utils/fastdom'
import {getType, isOnlyFolder} from '../../../utils/fileTree'
import {escapeFilePathForURL} from '../../../utils/url'

import type {BuildArtifactsTreeQuery} from './__generated__/BuildArtifactsTreeQuery.graphql'
import ArtifactsDownloadAll from './ArtifactsDownloadAll/ArtifactsDownloadAll'
import BuildArtifactStorageInfo from './BuildArtifactStorageInfo/BuildArtifactStorageInfo'
import connect from './BuildArtifactsTree.container'
import type {Props} from './BuildArtifactsTree.types'
import {
  getArtifactsHref,
  getLeftIndent,
  isHiddenArtifact,
  isParent,
  encodeArtifactPath,
} from './BuildArtifactsTree.utils'
import FileTreeNode, {ITEM_SELECTOR, MIN_OFFSET, STEP} from './FileTreeNode/FileTreeNode'

import styles from './BuildArtifactsTree.css'

const query = graphql`
  query BuildArtifactsTreeQuery($buildLocator: String!, $path: String!, $locator: String) {
    filesListForSubpathOfBuild(buildLocator: $buildLocator, path: $path, locator: $locator) {
      file {
        name
        fullName
        size
      }
    }
  }
`

function BuildArtifactsTree({
  buildId,
  buildUrl,
  path = '',
  buildType,
  level,
  labelledBy,
  showToggleHidden,
  showDownloadLink,
  showStorageInfo = false,
  storageFeatureIdQueryRef,
  hasArtifacts,
  canSelectDirs,
  autoWidth,
  compact = true,
  isPopup,
  expandedNodes,
  timeStamp,
  onSelect,
  onExpand,
  urlSync = false,
  onReady,
}: Props) {
  const showHiddenStateReact = React.useState(false)
  const showHiddenStateUrl = useBooleanQueryParamState('showAll')
  const [showHidden, setShowHidden] = urlSync ? showHiddenStateUrl : showHiddenStateReact
  const expandedNodeArray = React.useRef(
    urlSync ? location.hash.slice(1).split(';').map(decodeURIComponent) : expandedNodes,
  )
  const handleExpand = React.useCallback(
    (pathToExpand: string, expanded: boolean | null | undefined) => {
      if (urlSync) {
        const currentExpandedNodeArray = expanded
          ? // remove parent paths to avoid duplication
            expandedNodeArray.current
              ?.filter(node => !isParent(node, pathToExpand))
              .concat(pathToExpand)
          : // remove path and its children
            expandedNodeArray.current?.filter(
              node => node !== pathToExpand && !isParent(pathToExpand, node),
            )
        const isExpandedNodeEqual =
          currentExpandedNodeArray?.join('') === expandedNodeArray.current?.join('')

        if (!isExpandedNodeEqual) {
          expandedNodeArray.current = currentExpandedNodeArray

          window.location.hash = currentExpandedNodeArray?.map(encodeURIComponent).join(';') ?? ''
        }
      } else if (onExpand != null) {
        onExpand(pathToExpand, expanded)
      }
    },
    [onExpand, urlSync],
  )
  const handleToggleHidden = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      setShowHidden(!showHidden)
    },
    [setShowHidden, showHidden],
  )
  const ref = React.useRef<HTMLUListElement>(null)
  const hiddenRef = React.useRef()
  const isRoot = level === 0
  const {filesListForSubpathOfBuild} = useLazyLoadQuery<BuildArtifactsTreeQuery>(query, {
    buildLocator: `id:${buildId}`,
    path: encodeArtifactPath(path, level === 1),
    locator: `hidden:${isRoot ? 'any' : 'false'}`,
  })
  const files = filesListForSubpathOfBuild?.file ?? emptyArray
  React.useEffect(() => {
    onReady?.()
  }, [onReady])
  const {artifactsSize} = buildArtifactsApi.endpoints.getArtifactsSize.useQuery(
    isRoot ? {buildId, showAll: showHidden} : skipToken,
    {selectFromResult: ({data}) => ({artifactsSize: data?.size})},
  )
  const shouldFocusFirst = isRoot && files.length > 0
  const sandbox = useSandbox()
  React.useEffect(() => {
    if (shouldFocusFirst) {
      const element = ref.current

      if (element == null) {
        return
      }

      const firstItem = element.querySelector(ITEM_SELECTOR)
      sandbox.mutate(() => {
        const {documentElement} = document
        const prevScroll = documentElement?.scrollTop
        focusSelfOrChildLink(firstItem)

        if (documentElement && prevScroll != null) {
          documentElement.scrollTop = prevScroll
        }
      })
    }
  }, [sandbox, shouldFocusFirst])
  React.useEffect(() => {
    if (showHidden) {
      focusSelfOrChildLink(hiddenRef.current)
    }
  }, [showHidden])
  const onlyFolder = isOnlyFolder(files)
  const noUserArtifacts = hasArtifacts === false
  const userArtifacts = files.filter(file => !isHiddenArtifact(file.name))
  const hasHiddenArtifacts = files.some(file => isHiddenArtifact(file.name))
  const filesToRender = showHidden ? files : userArtifacts
  const artifactsHref = getArtifactsHref(buildUrl, true)
  const extensions = useAppSelector(
    state => getExtensionEndpointsByKind(state, 'artifacts'),
    shallowEqual,
  )
  const leftLevelIndentStyles = React.useMemo(() => getLeftIndent(level), [level])
  const downloadArtifactsLink =
    showDownloadLink && filesToRender.length > 0 ? (
      <ArtifactsDownloadAll
        buildId={buildId}
        buildType={buildType}
        showHidden={showHidden}
        className={styles.downloadLink}
        compact={isPopup}
      />
    ) : null

  return (
    <>
      {isRoot && (
        <div className={classNames(styles.header, {[styles.popupHeader]: isPopup})}>
          <div>
            {(noUserArtifacts || (hasHiddenArtifacts && userArtifacts.length === 0)) && (
              <div className={styles.noArtifacts}>
                <div>{'No user-defined artifacts in this build. '}</div>
              </div>
            )}
            {artifactsSize != null && (
              <div className={styles.artifactsSize}>
                {`Total size: ${artifactsSize}`}
                {isPopup && downloadArtifactsLink}
              </div>
            )}
            {showStorageInfo && storageFeatureIdQueryRef && (
              <Suspense>
                <ErrorBoundary fallback={null}>
                  <BuildArtifactStorageInfo
                    buildId={buildId}
                    storageFeatureIdQueryRef={storageFeatureIdQueryRef}
                  />
                </ErrorBoundary>
              </Suspense>
            )}
            {hasHiddenArtifacts && (
              <div className={styles.noteHidden} style={leftLevelIndentStyles}>
                {showHidden ? 'Hidden artifacts from the .teamcity directory are displayed ' : null}
                {(showToggleHidden || userArtifacts.length === 0) && artifactsHref != null && (
                  <Link
                    href={artifactsHref}
                    className={styles.toggleHidden}
                    onPlainLeftClick={handleToggleHidden}
                    style={leftLevelIndentStyles}
                  >
                    {showHidden ? 'Hide' : 'Show hidden artifacts'}
                  </Link>
                )}
              </div>
            )}
          </div>
          {!isPopup && downloadArtifactsLink}
        </div>
      )}
      {filesToRender.length > 0 && (
        <ul
          className={classNames(styles.tree, {[styles.autoWidth]: autoWidth})}
          role={isRoot ? 'tree' : 'group'}
          aria-labelledby={labelledBy}
          ref={ref}
        >
          {filesToRender.map(file => {
            const {name, fullName, size} = file
            const type = getType(file)
            const childPath = `/${fullName}`
            const encodedChildPath = escapeFilePathForURL(childPath)
            const defaultExpanded =
              onlyFolder ||
              expandedNodeArray.current?.some(
                node => node === childPath || node.startsWith(`${childPath}/`),
              )
            return (
              <FileTreeNode
                key={name}
                name={name!}
                expandable={type !== 'file'}
                defaultExpanded={defaultExpanded}
                path={childPath}
                type={type}
                icon={type}
                size={size}
                level={level}
                href={
                  type === 'folder'
                    ? undefined
                    : `/repository/download/${stringifyId(buildType)}/${stringifyId(
                        buildId,
                      )}:id${encodedChildPath}`
                }
                extensions={extensions}
                buildId={buildId}
                itemRef={isHiddenArtifact(file.name) ? hiddenRef : null}
                compact={compact}
                onSelect={canSelectDirs === true || type === 'file' ? onSelect : null}
                onExpand={handleExpand}
              >
                {type !== 'file' && (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <ConnectedTree
                    buildId={buildId}
                    buildType={buildType}
                    path={childPath}
                    level={level + 1}
                    canSelectDirs={canSelectDirs}
                    expandedNodes={expandedNodeArray.current}
                    timeStamp={timeStamp}
                    compact={compact}
                    onSelect={onSelect}
                    onExpand={handleExpand}
                  />
                )}
              </FileTreeNode>
            )
          })}
        </ul>
      )}
    </>
  )
}

const ConnectedTree = connect(function BuildArtifactsTreeContainer(props: Props) {
  const {hasArtifacts, level} = props
  const noUserArtifacts = hasArtifacts === false

  return (
    <div className={styles.container}>
      <Suspense
        fallback={
          !noUserArtifacts && (
            <div className={styles.loader} style={{paddingLeft: MIN_OFFSET + level * STEP}}>
              <LoaderInline />
              {' Loading files...'}
            </div>
          )
        }
      >
        <BuildArtifactsTree {...props} />
      </Suspense>
    </div>
  )
})

export default ConnectedTree
