import {Suspense} from 'react'

import {useIsPipelinesSelected} from '../../../../../hooks/routes'
import HeaderBanner from '../../../../App/Header/HeaderBanner/HeaderBanner'
import {
  isEarlyAccessBannerEnabled,
  isPipelinesEnabledInExclusiveMode,
} from '../../utils/featureToggles'

import styles from './PipelinesBetaBanner.css'

type Props = {
  isAdmin: 'true' | string
}

function PipelinesBetaBanner({isAdmin}: Props) {
  const isPipelinesPage = useIsPipelinesSelected()
  const shouldShow =
    isEarlyAccessBannerEnabled &&
    (isPipelinesPage || (isAdmin === 'true' && isPipelinesEnabledInExclusiveMode()))

  if (!shouldShow && !IS_STORYBOOK) {
    return null
  }

  return (
    <Suspense fallback={<div data-suspense-fallback className={styles.placeholder} />}>
      <HeaderBanner
        content={
          "You're using an early access version of TeamCity Pipelines. Expect regular updates and changes."
        }
      />
    </Suspense>
  )
}

export default PipelinesBetaBanner
