import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {AgentId} from '../../../../../types'
import {makeResource} from '../../../../../utils/makeResource'

import type {AgentParametersComponentType} from './AgentParameters'
import {query} from './AgentParameters.queries'

export const AgentParametersEntryPoint: EntryPoint<AgentParametersComponentType, AgentId> = {
  root: makeResource(
    'AgentParameters',
    () => import(/* webpackChunkName: "AgentParameters" */ './AgentParameters'),
  ),
  getPreloadProps: agentId => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {locator: `id:${agentId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
