import {useEffect, useMemo} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {useAppSelector} from '../../../../hooks/react-redux'
import {BuildStatusType} from '../../../../types'
import {getOriginalPipelineSettings} from '../EditPipelinePage/EditPipelinePage.selectors'
import {isSelfHostedRunsOn} from '../EditPipelinePage/EditPipelinePage.utils'
import {useJobIdState, usePipelineId} from '../hooks/pipeline'
import usePermissionCanEditProject from '../hooks/usePermissionCanEditProject'
import type {PipelineLastRunJobFragment$data} from '../PipelinePage/PipelineLastRun/PipelineLastRunJob/__generated__/PipelineLastRunJobFragment.graphql'
import {pipelinesApi} from '../services/pipelinesApi'
import {isJobIncompatibilityAgent} from '../utils/featureToggles'

import type {PipelineRunPageReusedJobQuery} from './__generated__/PipelineRunPageReusedJobQuery.graphql'

import {useQueryParam} from 'src/hooks/routes'
import type {Build, GetBuildApiArg} from 'src/services/rest'
import {restApi} from 'src/services/rest'
import {emptyArray} from 'src/utils/empty'
import {subscribeOnBuildTypeEvents, subscribeOnProjectEvents} from 'src/utils/subscriber'
import {
  BUILD_FINISHED,
  BUILD_INTERRUPTED,
  BUILD_STARTED,
  BUILD_TYPE_REMOVED_FROM_QUEUE,
} from 'src/utils/subscriptionEvents'

const getPipelineRunArgs = (pipelineRunId: string, options?: {withSnapshotAgents: boolean}) => ({
  buildLocator: `id:${pipelineRunId}`,
  fields:
    `id,number,state,branchName,defaultBranch,status,statusText,canceledInfo` +
    `,failedToStart,personal,detachedFromAgent,waitReason,startEstimate,user(id)` +
    `,triggered(user(username,name,avatars(urlToSize20,urlToSize40)),displayText,date)` +
    `,queuedDate,startDate,finishDate,buildType(id,internalId,project(id))` +
    `,running-info(percentageComplete,elapsedSeconds,estimatedTotalSeconds,leftSeconds,overtime)` +
    `,snapshot-dependencies(build(` +
    `attributes($locator(name:teamcity.pipeline.job.id),entry(name,value)),${
      options?.withSnapshotAgents
        ? 'agent(name,id,links(link(type,relativeUrl)),environment(osType),typeId,connected,pool(id,name)),'
        : ''
    }id,state,status,statusText,number,canceledInfo,failedToStart,personal,detachedFromAgent` +
    `,parallelized,waitReason,startEstimate,startDate,finishDate,user(id),buildType(name,id,project(internalId))` +
    `,running-info(percentageComplete,leftSeconds),artifacts(count)` +
    `,snapshot-dependencies(build(id,running-info(percentageComplete),buildType(project(internalId))))` +
    `))`,
})

export const usePipelineRunArg = (): GetBuildApiArg => {
  const {pipelineRunId = ''} = useParams()
  return useMemo(() => getPipelineRunArgs(pipelineRunId), [pipelineRunId])
}

export const usePipelineDebugArg = (): GetBuildApiArg => {
  const pipelineId = usePipelineId()
  const pipelineRunId = useAppSelector(state => state.pipelines.debug[pipelineId]?.runId)
  return useMemo(
    () =>
      getPipelineRunArgs(pipelineRunId ? String(pipelineRunId) : '', {withSnapshotAgents: true}),
    [pipelineRunId],
  )
}
export const usePipelineJobArg = (): GetBuildApiArg => {
  const jobId = useQueryParam('job') ?? ''

  return useMemo(
    () => ({
      buildLocator: `id:${jobId}`,
      fields:
        'id,number,state,branchName,defaultBranch,status,statusText,canceledInfo' +
        ',failedToStart,personal,detachedFromAgent,waitReason,startEstimate,user(id)' +
        ',queuedDate,startDate,finishDate,buildType(id,internalId,project(id))' +
        ',running-info(percentageComplete,elapsedSeconds,estimatedTotalSeconds,leftSeconds,overtime)' +
        ',snapshot-dependencies(build(' +
        'attributes($locator(name:teamcity.pipeline.job.id),entry(name,value)),' +
        'id,state,status,statusText,number,canceledInfo,failedToStart,personal,detachedFromAgent' +
        ',parallelized,waitReason,startEstimate,startDate,finishDate,user(id),buildType(name,project(internalId),internalId)' +
        ',running-info(percentageComplete,leftSeconds),artifacts(count)' +
        `,snapshot-dependencies(build(id,running-info(percentageComplete),buildType(project(internalId))))` +
        '))',
    }),
    [jobId],
  )
}

const reusedJobQuery = graphql`
  query PipelineRunPageReusedJobQuery($buildLocator: String!, $skip: Boolean!) {
    build(buildLocator: $buildLocator) @skip(if: $skip) {
      id
    }
  }
`

export const usePipelineRunPage = () => {
  const pipelineId = usePipelineId()
  const arg = usePipelineRunArg()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const canEditPipeline = usePermissionCanEditProject()
  const {buildLoading, dependencies, buildError, headInternalId, refetch} =
    restApi.endpoints.getBuildNormalized.useQuery(arg, {
      selectFromResult: ({data, isLoading, isUninitialized, error}) => {
        const run = data?.entities?.builds?.[data?.result!]
        const headId = run?.buildType
        const runSnapshotDependenciesBuild = run?.['snapshot-dependencies']?.build
        return {
          buildLoading: isLoading || isUninitialized,
          dependencies: runSnapshotDependenciesBuild ?? (emptyArray as Build[]),
          buildError: error,
          headInternalId: data?.entities?.buildTypes?.[headId!]?.internalId,
        }
      },
    })
  const {pipelineLoading, pipelineError} = pipelinesApi.endpoints.getPipelineById.useQuery(
    pipelineId,
    {
      selectFromResult: ({isLoading, error, isUninitialized}) => ({
        pipelineLoading: isLoading || isUninitialized,
        pipelineError: error,
      }),
    },
  )

  const [firstDependency] = dependencies
  const firstDependencyId = firstDependency?.id
  const childInternalId = firstDependency?.buildType?.project?.internalId
  const [jobId, setJobId] = useJobIdState()
  const selectedJob = dependencies.find(dep => String(dep.id) === jobId)
  const isEdit = searchParams.get('edit') === 'true'
  const reusedJobData = useLazyLoadQuery<PipelineRunPageReusedJobQuery>(reusedJobQuery, {
    buildLocator: `id:${jobId}`,
    skip: isEdit || jobId == null || dependencies.length === 0 || selectedJob != null,
  })
  const reusedJobId = reusedJobData.build?.id

  useEffect(() => {
    if (!jobId && firstDependencyId) {
      setJobId(String(firstDependencyId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDependencyId])

  useEffect(() => {
    if (reusedJobId != null) {
      setJobId(String(reusedJobId))
    }
  }, [reusedJobId, setJobId])

  useEffect(
    () =>
      childInternalId
        ? subscribeOnProjectEvents(
            childInternalId,
            [BUILD_TYPE_REMOVED_FROM_QUEUE, BUILD_STARTED, BUILD_FINISHED, BUILD_INTERRUPTED],
            refetch,
          )
        : undefined,
    [childInternalId, refetch],
  )

  useEffect(
    () =>
      headInternalId
        ? subscribeOnBuildTypeEvents(
            headInternalId,
            [BUILD_TYPE_REMOVED_FROM_QUEUE, BUILD_STARTED, BUILD_FINISHED, BUILD_INTERRUPTED],
            refetch,
          )
        : undefined,
    [headInternalId, refetch],
  )

  return {
    jobId,
    buildError,
    pipelineError,
    searchParams,
    navigate,
    location,
    isPageDataLoading: buildLoading || pipelineLoading,
    canEditPipeline,
    isEdit,
  }
}

export function usePipelineRunSettingJobIdMap() {
  const arg = usePipelineRunArg()
  const {jobs} = restApi.endpoints.getBuildNormalized.useQuery(arg, {
    selectFromResult: ({data}) => {
      const {entities, result} = data ?? {}
      const {builds} = entities ?? {}
      const run = builds?.[result!]
      const jobs = run?.['snapshot-dependencies']?.build
      return {
        jobs,
      }
    },
  })

  return useMemo(
    () =>
      jobs?.reduce<Map<number | null | undefined, string | undefined>>(
        (acc, {id, buildType, attributes}) => {
          const buildTypeName = buildType?.name
          const jobIdValue = attributes?.entry?.find(
            entry => entry?.name === 'teamcity.pipeline.job.id',
          )?.value
          const jobId = isNaN(Number(jobIdValue)) ? jobIdValue : buildTypeName

          acc.set(id, jobId)

          return acc
        },
        new Map(),
      ),
    [jobs],
  )
}

export function usePipelineRunJobAgentCompatibilityStatus(
  currentBuild: Build | PipelineLastRunJobFragment$data | null | undefined,
) {
  const pipelineId = usePipelineId()
  const runId = currentBuild?.id
  const waitReason = currentBuild?.waitReason
  const pipelineSettingJobId = useMemo(
    () =>
      currentBuild?.attributes?.entry?.find(entry => entry?.name === 'teamcity.pipeline.job.id')
        ?.value ?? '',
    [currentBuild],
  )
  const currentJobAgentId = useAppSelector(getOriginalPipelineSettings(pipelineId))?.jobs?.[
    pipelineSettingJobId
  ]?.['runs-on']
  const isJobQueued = currentBuild?.state === BuildStatusType.QUEUED
  const [
    getCompatibilityAgentsQueuedJob,
    {compatibilityAgentsQueuedJob = [], isCompatibilityAgentsQueuedJobLoading},
  ] = pipelinesApi.endpoints.getCompatibilityAgentsQueuedJob.useLazyQuery({
    skipPollingIfUnfocused: true,
    selectFromResult: ({data, isLoading}) => ({
      compatibilityAgentsQueuedJob: data?.compatibilities,
      isCompatibilityAgentsQueuedJobLoading: isLoading,
    }),
  })

  const compatibilityAgentQueuedJob = useMemo(() => {
    if (!isJobQueued) {
      return []
    }

    const isCurrentAgentSelfHosed = isSelfHostedRunsOn(currentJobAgentId)

    if (isCurrentAgentSelfHosed) {
      return compatibilityAgentsQueuedJob.filter(({agent}) => agent.type === 'AGENT')
    }
    const currentAgent = compatibilityAgentsQueuedJob.find(
      ({agent}) => agent.name === currentJobAgentId,
    )
    return currentAgent ? [currentAgent] : []
  }, [compatibilityAgentsQueuedJob, currentJobAgentId, isJobQueued])

  const isCurrentJobIncompatibilityAgent = useMemo(
    () =>
      compatibilityAgentQueuedJob.length > 0 &&
      compatibilityAgentQueuedJob.every(agent => agent?.compatible === false),
    [compatibilityAgentQueuedJob],
  )

  useEffect(() => {
    if (isJobQueued && runId != null && isJobIncompatibilityAgent) {
      getCompatibilityAgentsQueuedJob({buildId: runId, pipelineId})
    }
  }, [getCompatibilityAgentsQueuedJob, isJobQueued, pipelineId, runId, waitReason])

  return {
    pipelineSettingJobId,
    isCurrentJobIncompatibilityAgent,
    isCompatibilityAgentsQueuedJobLoading,
    compatibilityAgentsQueuedJob,
    compatibilityAgentQueuedJob,
  }
}
