import exceptionIcon from '@jetbrains/icons/exception.svg'
import {lazy, Suspense} from 'react'
import {useRouteError} from 'react-router-dom'

import {extractErrorFromRelay} from '../../../relay/extractErrorFromRelay'
import {getPipelinesHref} from '../../../routes'
import RouterLink from '../../common/RouterLink/RouterLink'

import FullScreenErrorWrapper from './components/FullScreenErrorWrapper/FullScreenErrorWrapper'
import {serializeError} from './utils/error'

const ErrorMessage = lazy(
  () =>
    import(
      /* webpackChunkName: "ErrorMessage" */ '@jetbrains/ring-ui/components/error-message/error-message'
    ),
)

export default function PipelinesPagesErrorBoundary() {
  const routeError = useRouteError() as Error | null | undefined
  const error = routeError && extractErrorFromRelay(routeError)
  return (
    <FullScreenErrorWrapper>
      <Suspense>
        <ErrorMessage
          icon={exceptionIcon}
          message="Error while loading data"
          description={serializeError(error)}
        >
          <RouterLink to={getPipelinesHref()}>{'Go to the home page'}</RouterLink>
        </ErrorMessage>
      </Suspense>
    </FullScreenErrorWrapper>
  )
}
