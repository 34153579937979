export const SW_CACHING_HEADER_NAME = 'X-TeamCity-SW-Cache'
export const SW_FORCE_REVALIDATE_HEADER_NAME = 'X-TeamCity-SW-Force-Revalidate'
export const UPDATE_MESSAGE_TYPE = 'JSONCheckerUpdate'

// Client commands
export const CHECK_VERSION = 'CHECK_VERSION'
export const SKIP_WAITING = 'SKIP_WAITING'
export const DELETE_CACHES = 'DELETE_CACHES'

// Caching-mark headers
export const CACHE_PROJECTS_TREE = 'projects-tree'
export const CACHE_TABS = 'tabs'
export const CACHE_ALL_BUILDS = 'all-builds'
export const CACHE_ALL_BUILDS_DETAILS = 'all-builds-details'
export const CACHE_GRAPHQL = 'graphql'
