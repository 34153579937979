import Link from '@jetbrains/ring-ui/components/link/link'

import SvgIcon from '../../../../library/components/SvgIcon/SvgIcon'

import styles from './HeaderBanner.css'

type Props = {
  href: string
}

function HeaderBannerFeedback({href}: Props) {
  return (
    <Link className={styles.feedback} href={href} target={'_blank'} rel="noreferrer">
      <SvgIcon className={styles.feedbackIcon} icon={'comment'} />
      {'Share feedback...'}
    </Link>
  )
}

export default HeaderBannerFeedback
