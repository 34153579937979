import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {AgentTypeId} from '../../../../../types'
import {makeResource} from '../../../../../utils/makeResource'

import type {AgentTypeParametersComponentType} from './AgentTypeParameters'
import {query} from './AgentTypeParameters.queries'

export const AgentTypeParametersEntryPoint: EntryPoint<
  AgentTypeParametersComponentType,
  AgentTypeId
> = {
  root: makeResource(
    'AgentTypeParameters',
    () => import(/* webpackChunkName: "AgentTypeParameters" */ './AgentTypeParameters'),
  ),
  getPreloadProps: agentTypeId => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {locator: `id:${agentTypeId}`},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
