import {useContext, useEffect, useMemo, useRef} from 'react'
import type {ComponentType, HTMLAttributes, Ref} from 'react'

import {stopFetching} from '../actions/builds'
import {useVisibility} from '../components/common/Visible/Visible.hooks'
import {LocatorContext} from '../contexts/locator'
import {wrapDisplayName} from '../library/utils/getDisplayName'
import type {Id} from '../types'
import {getEntityId} from '../utils/namespace'
import type {Namespace} from '../utils/namespace'

import {useAppSelector} from 'src/hooks/react-redux'

type Options = {
  namespace: Namespace
  childUsesVisibility?: boolean | null | undefined
  ignoreFetcher?: boolean
}
type LazyLoadingProps<IdType extends Id> = {
  id: IdType
  needsDetails: boolean
  fetchDetails: (id: IdType) => unknown
  defaultVisible?: boolean
  targetValue?: unknown
}
type UseLazyLoadingParams<IdType extends Id> = Options & LazyLoadingProps<IdType>
export type UseLazyLoadingOutput<T extends HTMLElement = HTMLSpanElement> = {
  isVisible?: boolean
  wrapperProps?: HTMLAttributes<T> & {ref?: Ref<T>}
}
export function useLazyLoading<IdType extends Id, T extends HTMLElement = HTMLSpanElement>({
  namespace,
  childUsesVisibility,
  ignoreFetcher,
  id,
  needsDetails,
  targetValue,
  fetchDetails,
  defaultVisible = true,
}: UseLazyLoadingParams<IdType>): UseLazyLoadingOutput<T> {
  const entityId = getEntityId(namespace, id as Id)
  const ref = useRef<T>(null)
  const locator = useContext(LocatorContext) ?? ''
  const isLoading = useAppSelector(state => state.buildsDetailsLoading[locator])
  const prevTargetValue = useRef(targetValue)
  const shouldFetchIfVisible =
    (needsDetails && (ignoreFetcher === true || !isLoading)) ||
    targetValue !== prevTargetValue.current
  const isVisible = useVisibility(
    ref,
    entityId,
    defaultVisible,
    !shouldFetchIfVisible && !childUsesVisibility,
  )
  const shouldFetch = isVisible && shouldFetchIfVisible
  useEffect(() => {
    if (shouldFetch) {
      fetchDetails(id)
    }

    return () => stopFetching(entityId)
  }, [id, shouldFetch, fetchDetails, entityId])
  useEffect(() => {
    prevTargetValue.current = targetValue
  })
  const wrapperProps = useMemo(
    () => ({
      id: entityId,
      ref: shouldFetchIfVisible ? ref : undefined,
    }),
    [entityId, shouldFetchIfVisible],
  )
  return {
    ...(childUsesVisibility === true
      ? {
          isVisible,
        }
      : null),
    wrapperProps,
  }
}

function withLazyLoading<IdType extends Id, P extends LazyLoadingProps<IdType>>(options: Options) {
  return (
    Component: ComponentType<Omit<P, keyof LazyLoadingProps<IdType>> & UseLazyLoadingOutput>,
  ) => {
    function WithLazyLoading({
      id,
      needsDetails,
      targetValue,
      fetchDetails,
      defaultVisible = true,
      ...restProps
    }: P) {
      const addProps = useLazyLoading({
        ...options,
        id,
        needsDetails,
        targetValue,
        fetchDetails,
        defaultVisible,
      })
      return <Component {...restProps} {...addProps} />
    }

    WithLazyLoading.displayName = wrapDisplayName(Component, 'withLazyLoading')
    return WithLazyLoading
  }
}

export default withLazyLoading
