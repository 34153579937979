import {connect} from 'react-redux'

import type {State} from '../../../reducers/types'
import {getBuild} from '../../../selectors'
import type {BuildId, RunningInfo} from '../../../types'
import {resolveWebEntityLink, WebEntityType} from '../../../utils/entityLinks'

import BuildWarning from './BuildWarning'

type Props = {
  buildId: BuildId
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)

  if (!build) {
    return {}
  }

  const runningInfo: RunningInfo | null | undefined = build['running-info']
  return {
    running: build.state === 'running',
    composite: build.composite,
    hasExecutor: build.executor != null,
    detachedFromAgent: build.detachedFromAgent,
    agentConnected: build.agent?.connected,
    agentName: build.agent?.name,
    lastActivityTime: runningInfo?.lastActivityTime,
    probablyHanging: runningInfo?.probablyHanging,
    outdated: runningInfo?.outdated,
    outdatedReasonBuildNumber: runningInfo?.outdatedReasonBuild?.number,
    outdatedReasonBuildUrl: resolveWebEntityLink(
      WebEntityType.BUILD,
      runningInfo?.outdatedReasonBuild?.id,
    ),
  }
}

export default connect(mapStateToProps)(BuildWarning)
