import Link from '@jetbrains/ring-ui/components/link/link'
import type {PlaceId} from '@jetbrains/teamcity-api'
import type * as React from 'react'

import {base_uri} from '../../../../types/BS_types'
import {getAdminSpaceAvailable} from '../../../App/Header/Header.utils'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'

type OwnProps = {
  name: string
  placeId: PlaceId
  children: React.ReactNode
}

function PluginErrorBoundary({name, placeId, children}: OwnProps) {
  const adminSpaceAvailable = getAdminSpaceAvailable()
  return (
    <ErrorBoundary
      messageProps={{
        title: `Error in Plugin "${name}"`,
        children: (
          <>
            <p>{`Plugin "${name}" in ${placeId} has been broken`}</p>
            {adminSpaceAvailable ? (
              <p>
                {'You could disable plugin '}
                <Link href={`${base_uri}/admin/admin.html?item=plugins`}>{'here'}</Link>
              </p>
            ) : null}
          </>
        ),
      }}
      onError={error => {
        // eslint-disable-next-line no-console
        console.error(`Plugin ${name} failed.`, error)
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default PluginErrorBoundary
