import type {EntryPoint} from 'react-relay'

import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {makeResource} from '../../../utils/makeResource'
import {ProjectsSidebarEntryPoint} from '../SidebarPanel/SidebarPanelContent/ProjectsSidebar/ProjectsSidebar.entryPoint'

import type {AdminSidebarPanelComponent, NestedEntryPoints} from './AdminSidebarPanel'

export const AdminSidebarPanelEntryPoint: EntryPoint<AdminSidebarPanelComponent> = {
  root: makeResource(
    'AdminSidebarPanel',
    () =>
      import(
        /* webpackChunkName: "AdminSidebarPanel", webpackPrefetch: true */ './AdminSidebarPanel'
      ),
  ),
  getPreloadProps: () => {
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}

    entryPoints.projectsSidebar = {entryPoint: ProjectsSidebarEntryPoint, entryPointParams: {}}

    return {entryPoints}
  },
}
