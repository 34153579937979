import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {makeResource} from '../../../utils/makeResource'
import {AgentPageEntryPoint} from '../../pages/AgentsPages/AgentPage/AgentPage.entryPoint'
import {AgentPipelinePageEntryPoint} from '../../pages/PipelinesPages/AgentsPage/Agent/AgentPipelinePage.entryPoint'
import {isPipelinesAgentPageEnabled} from '../../pages/PipelinesPages/utils/featureToggles'

import type {AppAgentTypeComponent, AppAgentTypeNestedEntryPoints} from './AppAgent'

export const AppAgentEntryPoint: EntryPoint<AppAgentTypeComponent, LoaderFunctionArgs> = {
  root: makeResource('AppAgent', () => import(/* webpackChunkName: "AppAgent" */ './AppAgent')),
  getPreloadProps: entryPointParams => {
    const entryPoints: ThinNestedEntryPointParamsObject<AppAgentTypeNestedEntryPoints> = {}

    if (isPipelinesAgentPageEnabled()) {
      entryPoints.pipeline = {
        entryPoint: AgentPipelinePageEntryPoint,
        entryPointParams,
      }
    } else {
      entryPoints.common = {
        entryPoint: AgentPageEntryPoint,
        entryPointParams,
      }
    }

    return {entryPoints}
  },
}
