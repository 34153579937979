import type {State} from '../../reducers/types'
import {restApi} from '../../services/rest'
import type {RouteAvailabilityResponse} from '../../types'

const STATUS_OK = 200
export const getAvailabilityResponse = (
  state: State,
  path: string,
): RouteAvailabilityResponse | null | undefined =>
  state.routeAvailabilityResponse[path] ??
  restApi.endpoints.getRouteAvailability.select(path)(state)?.data
export function getIsAvailabilityError(state: State, path: string): boolean {
  const availabilityResponse = getAvailabilityResponse(state, path)
  return availabilityResponse != null && availabilityResponse.statusCode !== STATUS_OK
}
