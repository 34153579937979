import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {getBranchLocator} from '../../../../rest/locators'
import type {Branch} from '../../../../services/rest'
import type {BuildTypeId} from '../../../../types'
import {makeResource} from '../../../../utils/makeResource'
import {commiterSelectQuery} from '../../../common/UserSelect/CommiterSelect.queries'

import type {BuildTypeChangeLogTabComponent} from './BuildTypeChangeLogTab'

type Params = {
  buildTypeId: BuildTypeId
  branch: Branch | null | undefined
}

export const BuildTypeChangeLogTabEntryPoint: EntryPoint<BuildTypeChangeLogTabComponent, Params> = {
  root: makeResource(
    'BuildTypeChangeLogTab',
    () => import(/* webpackChunkName: "BuildTypeChangeLogTab" */ './BuildTypeChangeLogTab'),
  ),
  getPreloadProps: ({buildTypeId, branch}) => {
    const branchLocator = getBranchLocator(branch, true)
    const policyLocator = branch == null ? '' : ''
    const branchAndPolicyLocator = branchLocator ? `,${branchLocator}${policyLocator}` : ''
    const baseLocator = `buildType:(id:${buildTypeId})${branchAndPolicyLocator}`
    return {
      queries: {
        commiterSelect: {
          parameters: getRequest(commiterSelectQuery),
          variables: {changeLocator: baseLocator},
        },
      },
      extraProps: {baseLocator},
    }
  },
}
