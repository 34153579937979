import {graphql} from 'react-relay'

export const query = graphql`
  query HttpsConfigurationPageQuery {
    serverInfo {
      ...HttpsConfigurationPageDomainInfoFragment
      ...HttpsConfigurationPageServerUrlInfoFragment
    }
  }
`
