import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'

import {resolveHelpURL} from '../../../../utils/url'
import {HINT_CATEGORY, toHintId} from '../Hints.types'
import type {Hint} from '../Hints.types'

import styles from './HintItems.css'

export const buildLogSearchHint: Hint = {
  id: toHintId('buildlog-search-button'),
  name: 'Search',
  text: 'This is a full-text search',
  category: HINT_CATEGORY.buildOverview,
}
export const buildLogTimelineHint: Hint = {
  id: toHintId('buildlog-timeline-button'),
  name: 'Build Timeline',
  text: 'This is an interactive timeline of your build. Click on any stage of the build to open the corresponding place in the build log.',
  category: HINT_CATEGORY.buildOverview,
}
export const buildLogKeyboardNavigationHint: Hint = {
  id: toHintId('buildlog-keyboard-navigation'),
  name: 'Keyboard navigation',
  text: 'You can use keyboard navigation to move through the log and expand / collapse lines',
  category: HINT_CATEGORY.buildOverview,
}
export const buildTypeSettingsHint: Hint = {
  id: toHintId('edit-entity'),
  name: 'Build Settings',
  text: 'The TeamCity UI has the user mode, where you can view the results, and admin mode, where you can edit the settings. Use this panel to switch between these modes. To go to a specific settings tab, expand the edit menu.',
  helpLink: resolveHelpURL('?Creating+and+Editing+Build+Configurations'),
  category: HINT_CATEGORY.buildOverview,
  offset: 40,
}
export const testDetailsHint: Hint = {
  id: toHintId('test-details-hint'),
  name: 'Test Details',
  text: 'TeamCity is great at reporting your test results. Click on any test to expand its detailed results.',
  helpLink: resolveHelpURL('?Viewing+Tests+and+Configuration+Problems'),
  category: HINT_CATEGORY.buildOverview,
  directions: [Directions.LEFT_CENTER],
  obligatory: true,
}
export const testActionsHint: Hint = {
  id: toHintId('test-actions-hint'),
  name: 'Test Actions',
  offset: 44,
  text: 'The test menu allows viewing its history, assigning its investigation, and more.',
  category: HINT_CATEGORY.buildOverview,
  directions: [Directions.LEFT_CENTER],
}
export const downloadLogHint: Hint = {
  id: toHintId('test-download-log'),
  name: 'Download Log',
  text: 'You can either view the full structured build log right in the UI or download it to your disk.',
  category: HINT_CATEGORY.buildOverview,
  directions: [Directions.BOTTOM_LEFT],
}
export const buildGraphHint: Hint = {
  id: toHintId('build-graph'),
  name: 'Build Graph',
  text: 'This chart shows the sequence of builds in this configuration and lets you quickly switch between them. Hover over any build to see its details.',
  category: HINT_CATEGORY.buildOverview,
  directions: [Directions.BOTTOM_LEFT],
}
export const customRunHint: Hint = {
  id: toHintId('custom-run'),
  name: 'Custom Run',
  text: 'Open the Custom Run dialog to readjust the default settings for just one build run: for example, use a specific agent or change values of some parameters.',
  helpLink: resolveHelpURL('?Triggering+a+Custom+Build'),
  category: HINT_CATEGORY.buildOverview,
  directions: [Directions.BOTTOM_LEFT],
  className: styles.customRunHint,
}
