import type {JsonSchema} from '@jsonforms/core'
import type {Optional} from 'utility-types'

import type {Build} from '../../../../services/rest'
import type {BuildState, OSType} from '../../../../types'
import type {PipelineSettings} from '../types'

export type NewVcsRootDto = {
  url: string
  branch: string
  vcsName: string
  branchSpecification?: string
  vcsProviderType?: VcsProviderType | null
  username?: string | null
  password?: string | null
  isPrivate?: boolean
  connectionId?: string
}

export type GetRepositoryBranchesArg = {
  url: string
  vcsName?: string
  username?: string
  isPrivate?: boolean
  branchSpecification?: string
  connectionId?: string
}

export type GetParametersResolveArg = {
  id: string
  body: {
    pipeline: NewPipeline
    scope: {
      search?: string
      parameterName?: string
      context?: Record<string, string | undefined>
    }
  }
}

export type GetBuildStepDescriptionArg = {
  id: string
  body: NewPipeline
}

type GetBuildStepDescriptionJob = Record<string, string[]>

export type GetBuildStepDescription = {
  jobs: Record<string, GetBuildStepDescriptionJob>
}

export type PipelineParametersResolve = {
  name: string
  type: 'KNOWN_PASSWORD' | 'KNOWN_PARAMETER' | 'SUSPECTED_PARAMETER'
  values: string[]
}

export type RepositoryTestConnectionArg = {
  url: string
  connectionId?: string
  username?: string | null
  password?: string | null
  vcsName?: string
}

export type VcsRootDto = NewVcsRootDto & {
  id: string
}

type NewTriggerDto = {
  type: string
  parameters?: Record<string, string>
  disabled?: boolean
}

type TriggerDto = Required<NewTriggerDto>

export type NewIntegrationDto = {
  id?: string
  type: string
  parameters: Record<string, string | null>
}

type IntegrationDto = Required<NewIntegrationDto>

export enum ConfirmationDecision {
  OVERRIDE = 'override',
  IMPORT = 'import',
}

export type VersionedSettingsDto = {
  storedInRepo?: boolean
  filename?: string | null
  confirmationDecision?: ConfirmationDecision
}

export type NewPipelineDto = {
  yaml: string
  name: string
  vcsRoot: NewVcsRootDto
  triggers?: NewTriggerDto[]
  additionalVcsRoots: NewVcsRootDto[]
  integrations?: NewIntegrationDto[]
  versionedSettings?: VersionedSettingsDto
}

export type NewPipeline = Omit<NewPipelineDto, 'yaml'> & {
  settings: PipelineSettings
}

export type PipelineDto = {
  id: string
  name: string
  pipelineVersion?: string | null
  yaml: string
  vcsRoot: VcsRootDto
  additionalVcsRoots: VcsRootDto[]
  triggers: TriggerDto[]
  integrations: IntegrationDto[]
  versionedSettings?: VersionedSettingsDto
}

export type Pipeline = Optional<PipelineDto, 'yaml'> & {
  settings: PipelineSettings
}

export type CreateNewPipelineArg = {
  parentProjectId?: string
  body: NewPipeline
}

export type UpdatePipelineArg = {
  id: string
  body: NewPipeline
}

type Owner = {
  avatarUrl: string
  login: string
}

type Repository = {
  defaultBranch: string
  htmlUrl: string
  name: string
  private: boolean
}

export enum RepositoryGroupType {
  CURRENT_USER = 1,
  OTHER_USER = 2,
  ORGANISATION = 3,
}

export type RepositoryGroup = {
  myGroupType: RepositoryGroupType
  myOwner: Owner
  myRepositories: Repository[]
}

export type Optimization = {
  name: string
  value: number
  type: OptimizationType
}

export enum Optimizations {
  PARALLEL_TESTING = 'parallelTests',
  REUSING_JOBS = 'reusedJobs',
  DEPENDENCY_CACHE = 'dependencyCache',
  REUSING_DOCKER_IMAGE = 'reusedDockerImage',
}

export type PossibleOptimization = {
  name: string
  expectedValue?: string
}

export type RunDuration = {
  number: number
  duration: number
  optimized?: number
  canceled?: boolean
}

export type RunDurations = {
  queuedDate: string
  startedDate?: string
  finishedDate?: string
  estimatedDuration?: number
  optimizedDuration?: number
  previousRunDuration?: number
  appliedOptimizations?: Optimization[]
  possibleOptimizations?: PossibleOptimization[]
}

export type PipelineDurations = {
  totalDuration: number
  optimizedDuration?: number
  optimizations?: Optimization[]
  runs?: RunDuration[]
}

export type CheckVersionedSettingsArg = {
  vcsRoot: NewVcsRootDto
  filename?: string
}

type JsonSchemaExtendedDefs = Record<
  string,
  {
    enumNames: string[]
    enumDescription: string[]
  }
>

type JsonSchemaDefs = {
  $defs: JsonSchema['definitions'] & JsonSchemaExtendedDefs
}

export type PipelineRunnersJsonFromXMLs = Record<
  string,
  {
    jsonSchema: JsonSchema & JsonSchemaDefs
  }
>

export type PipelineProviderJdk = {
  displayName: string
  parameterReference: string
}

export enum OptimizationType {
  DURATION = 'duration',
  SIZE = 'size',
}

export enum VcsProviderType {
  GITHUB = 'GitHub',
  GITLAB_COM = 'GitLabCom',
  BITBUCKET_CLOUD = 'BitBucketCloud',
  ANY = 'Any',
}
export enum VcsProviderCapability {
  REPOS_PAGING = 'REPOS_PAGING',
  REPOS_PAGE_SIZE = 'REPOS_PAGE_SIZE',
  REPOS_SEARCH = 'REPOS_SEARCH',
}

export type VcsProvider = {
  connectionId?: string
  password?: string | null
  username?: string | null
  type?: VcsProviderType
  displayName?: string
  capabilities?: VcsProviderCapability[]
  description?: string
}

export type VcsRepository = {
  repositoryName: string
  httpCloneUrl: string | null
  sshCloneUrl: string | null
  htmlUrl: string
  defaultBranch?: string | null
  description: string | null
  language: string | null
  isPrivate: boolean
  isFork: boolean
}

export type GetRepositoriesResponse = {
  values: VcsRepository[]
  hasMore: boolean
  nextPage: number | null
  totalCount: number | null
}

export type GetRepositoriesArg = {
  connectionId: string
  q?: string
  page?: number
  pageSize?: number
}

export type GetPipelineRunVcsRootsArg = {
  pipelineId: string
  runId: number
}

export interface AgentCompatibilitiesArg {
  pipelineId: string
  jobId?: string
  body: NewPipeline
}

export type GetCompatibilityAgentsQueuedJobArg = {
  pipelineId: string
  buildId: number
}

type JobAgentCompatibilities = {
  compatibilities: Array<AgentCompatibility>
}

export type JobMapAgentCompatibilities = Record<string, JobAgentCompatibilities>

export type AgentCompatibilities = JobAgentCompatibilities

export interface AgentCompatibility {
  unmetReqirements: Array<AgentRequirement>
  unresolvedParameters: Array<{origin: string; parameter: string}>
  invalidRunnerParameters: Array<{
    parameter: string
    reason: string
  }>
  compatible: boolean
  agent: Agent
}

interface AgentRequirement {
  parameter: string
  predicate: string
  name: string
  value: string
}

interface Agent {
  osType: Uppercase<OSType> | 'MAC' | null
  name: string
  id: number
  type: string | 'AGENT' | 'CLOUD_IMAGE'
}

export type DebugResponse = {
  id: string
  state: BuildState
  jobs: {
    count?: number
    job?: {build: Build}[]
  }
  head: Build
}

export type DebugArg = {
  pipelineId: string
  body: {
    branch?: string
    debug?: Record<
      string,
      {
        breakpoints: string[]
      }
    >
    yaml?: string
  }
  fields: string
}
