import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {makeResource} from '../../../utils/makeResource'
import {AgentsOverviewPageEntryPoint} from '../../pages/AgentsPages/AgentsOverviewPage/AgentsOverviewPage.entryPoint'
import {isPipelinesAgentPageEnabled} from '../../pages/PipelinesPages/utils/featureToggles'

import type {AppAgentOverviewComponent, AppAgentTypeNestedEntryPoints} from './AppAgentOverview'

export const AppAgentOverviewEntryPoint: EntryPoint<AppAgentOverviewComponent, LoaderFunctionArgs> =
  {
    root: makeResource(
      'AppAgentOverview',
      () => import(/* webpackChunkName: "AppAgentOverview" */ './AppAgentOverview'),
    ),
    getPreloadProps: entryPointParams => {
      const entryPoints: ThinNestedEntryPointParamsObject<AppAgentTypeNestedEntryPoints> = {}

      if (!isPipelinesAgentPageEnabled()) {
        entryPoints.common = {
          entryPoint: AgentsOverviewPageEntryPoint,
          entryPointParams,
        }
      }

      return {entryPoints}
    },
  }
