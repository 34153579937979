import {Tabs, Tab, CustomItem} from '@jetbrains/ring-ui/components/tabs/tabs'
import classNames from 'classnames'
import {useEffect} from 'react'

import SvgIcon from '../../../library/components/SvgIcon/SvgIcon'
import {safeLocalStorage} from '../../../utils/safeStorages'
import {addHint, removeHint} from '../../packages/Hints/Hints.actions'
import type {Hint} from '../../packages/Hints/Hints.types'
import DarkTooltip from '../DarkTooltip/DarkTooltip'

import {
  BTN_MORE_CLASSNAME,
  RING_TABS_CLASSNAME,
  HINTS_REGISTRATION_DELAY,
} from './AdministrationTabs.consts'

import styles from './AdministrationTabs.css'

import {useAppDispatch} from 'src/hooks/react-redux'
import {noop} from 'src/utils/empty'
import {parseTabTitle} from 'src/utils/tabs'

type AdminTab = {
  id: string
  title: string
  url: string
  openNewTab?: boolean
  disabled?: boolean
  tooltip?: string
}

type Props = {
  selected: string
  tabs: AdminTab[]
  hints?: Hint[]
  autoCollapse?: boolean
}

const STORAGE_KEY = 'adminTabs.lastVisibleIndex'
const lastVisibleIndex = safeLocalStorage.getItemInJSON<number>(STORAGE_KEY)

function AdministrationTabs({selected, tabs, hints}: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (hints) {
      const timer = setTimeout(() => {
        hints.forEach(hint => {
          if (tabs.find(tab => tab.id === hint.id)) {
            const isTabVisible = document.querySelector(
              `.${RING_TABS_CLASSNAME} [data-hint-container-id="${hint.id}"]`,
            )
            dispatch(
              addHint({
                ...hint,
                category: 'Tabs',
                selector: isTabVisible ? undefined : `.${BTN_MORE_CLASSNAME}`,
              }),
            )
          }
        })
      }, HINTS_REGISTRATION_DELAY)

      return () => {
        clearTimeout(timer)
        hints.forEach(hint => dispatch(removeHint(hint.id)))
      }
    }

    return noop
  }, [hints, tabs, dispatch])

  return (
    <Tabs
      autoCollapse
      className={styles.tabs}
      moreClassName={BTN_MORE_CLASSNAME}
      selected={selected}
      initialVisibleItems={lastVisibleIndex != null ? lastVisibleIndex + 1 : tabs.length}
      onLastVisibleIndexChange={index => safeLocalStorage.setItemInJSON(STORAGE_KEY, index)}
    >
      {tabs.map(tab => {
        const {name, counter, marker, warning} = parseTabTitle(tab.title)

        const tabTitle = (
          <DarkTooltip
            className={classNames({[styles.disabled]: tab.disabled})}
            title={tab.tooltip}
            delay={100}
            data-hint-container-id={tab.id}
          >
            {tab.openNewTab && <SvgIcon className={styles.icon} icon="new-window" />}
            {warning && <SvgIcon className={styles.warningIcon} icon="warning" />}
            {name}
            {counter && <span className={styles.counter}>{counter}</span>}
            {marker && <SvgIcon className={styles.marker} icon="checkmark-12px" />}
          </DarkTooltip>
        )

        if (tab.disabled) {
          return <CustomItem key={tab.id}>{tabTitle}</CustomItem>
        }

        return (
          <Tab
            id={tab.id}
            key={tab.id}
            href={tab.url}
            titleProps={tab.openNewTab ? {target: '_blank'} : undefined}
            activeClassName={styles.active}
            className={styles.tab}
            title={tabTitle}
          />
        )
      })}
    </Tabs>
  )
}

export default AdministrationTabs
