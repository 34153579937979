import type {Link} from '../services/rest'
import type {BuildId, BuildTypeId, Id, LinkType, WebLinks} from '../types'

import {resolveRelative} from './url'

const findEntityLinkByType = (
  entity: WebLinks | null | undefined,
  type: LinkType,
): Link | null | undefined => entity?.links?.link?.find(({type: linkType}) => linkType === type)

export const getSettingsAvailable = (entity: WebLinks | null | undefined): boolean =>
  !!findEntityLinkByType(entity, 'webEdit') || !!findEntityLinkByType(entity, 'webViewSettings')

const makeWebEntityLinkResolver =
  (targetType: LinkType) =>
  (entity: WebLinks | null | undefined): string | null | undefined => {
    const link = findEntityLinkByType(entity, targetType)
    return link?.relativeUrl ? resolveRelative(link.relativeUrl) : undefined
  }

export enum WebEntityType {
  BUILD,
  BUILD_TYPE,
  PROJECT,
  AGENT,
  AGENT_TYPE,
}

export const resolveWebEntityLink = (
  entityType: WebEntityType,
  id: Id | null | undefined,
): string | null => {
  if (id == null) {
    return null
  }
  switch (entityType) {
    case WebEntityType.BUILD:
      return resolveRelative(`/viewLog.html?buildId=${id}`)
    case WebEntityType.BUILD_TYPE:
      return resolveRelative(`/viewType.html?buildTypeId=${id}`)
    case WebEntityType.PROJECT:
      return resolveRelative(`/project.html?projectId=${id}`)
    case WebEntityType.AGENT:
      return resolveRelative(`/agentDetails.html?id=${id}`)
    case WebEntityType.AGENT_TYPE:
      return resolveRelative(`/agentDetails.html?agentTypeId=${id}`)
    default:
      return null
  }
}
export const resolveWebEntityEditLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webEdit')
export const resolveWebEntitySettingsLink: (
  entity: WebLinks | null | undefined,
) => string | null | undefined = makeWebEntityLinkResolver('webViewSettings')

export const getOldWebBuildTypeLink = (buildTypeId: BuildTypeId | null | undefined) =>
  resolveRelative(`/viewType.html?buildTypeId=${buildTypeId}`)
export const getOldWebBuildLink = (buildId: BuildId | null | undefined, isQueued?: boolean) =>
  resolveRelative(
    isQueued ? `/viewQueued.html?itemId=${buildId}` : `/viewLog.html?buildId=${buildId}`,
  )
