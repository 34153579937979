import type {UrlExtension} from '../types'

export default function getAvailablePlugins(): ReadonlyArray<UrlExtension<any>> {
  return [
    {name: 'fetchStatuses', endpoint: 'overview'},
    {name: 'expandState', endpoint: 'overview'},
    {name: 'fetchExpandedBuildLogMessage', endpoint: 'app/messages'},
    {name: 'fetchBuildLogTail', endpoint: 'app/messages'},
    {name: 'fetchBuildLogTimeline', endpoint: 'app/timeline'},
  ]
}
