import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import {BuildPageTabNamesEnum} from '../../../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../../../types/entryPoints'
import {makeResource} from '../../../../../utils/makeResource'
import {queryToObject} from '../../../../../utils/queryParams'
import {BuildTabs} from '../../../../common/ToggleSakuraUI/ToggleSakuraUI.utils'
import {SELECTED_BUILD_DEPENDENCY_ID_PARAM} from '../../../../packages/BuildLog/FullBuildLog/BuildLogBuildNavigationWrapper/BuildLogBuildNavigationWrapper.consts'
import {BuildArtifactsTabEntryPoint} from '../../../BuildPage/BuildArtifactsTab/BuildArtifactsTab.entryPoint'
import {BuildTestsTabEntryPoint} from '../../../BuildPage/BuildTestsTab/BuildTestsTab.entryPoint'
import {ChangesTabEntryPoint} from '../PipelineTab/JobDetails/ChangesTab/ChangesTab.entryPoint'

import type {NestedEntryPoints, PipelineRunContentComponent} from './PipelineRunContent'

export const PipelineRunContentEntryPoint: EntryPoint<
  PipelineRunContentComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'PipelineRunContent',
    () => import(/* webpackChunkName: "PipelineRunContent" */ './PipelineRunContent'),
  ),
  getPreloadProps: ({params, request}) => {
    const url = new URL(request.url)
    const {
      job,
      tab,
      [SELECTED_BUILD_DEPENDENCY_ID_PARAM]: pageFullLogBuildId,
    } = queryToObject(url.search)
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}

    if (job != null) {
      const id = Number(pageFullLogBuildId ?? job)
      const buildLocator = `id:${id}`
      switch (BuildTabs[tab ?? '']) {
        case BuildPageTabNamesEnum.TESTS:
          entryPoints.testsTab = {
            entryPoint: BuildTestsTabEntryPoint,
            entryPointParams: {
              params,
              request,
              context: {
                buildLocator,
              },
            },
          }
          break
        case BuildPageTabNamesEnum.ARTIFACTS:
          entryPoints.artifactsTab = {
            entryPoint: BuildArtifactsTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        case BuildPageTabNamesEnum.CHANGES:
          entryPoints.changesTab = {
            entryPoint: ChangesTabEntryPoint,
            entryPointParams: id,
          }
          break
        default:
      }
    }
    return {
      entryPoints,
    }
  },
}
