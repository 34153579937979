import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../utils/makeResource'

import {hasOfflineKeysQuery} from './LicenseActivation/LicenseActivation.queries'
import type {LicensesPageComponent} from './LicensesPage'

export const LicensesPageEntryPoint: EntryPoint<LicensesPageComponent> = {
  root: makeResource(
    'LicensesPage',
    () => import(/* webpackChunkName: "LicensesPage" */ './LicensesPage'),
  ),
  getPreloadProps: () => ({
    queries: {
      hasOfflineKeys: {
        parameters: getRequest(hasOfflineKeysQuery),
        variables: {},
      },
    },
  }),
}
