import memoize from '@jetbrains/ring-ui/components/global/memoize'
import type {Size} from 're-resizable'

import {
  COLLAPSED_SIDEBAR_WIDTH,
  MIN_CONTENT_WIDTH,
  MIN_NON_COLLAPSED_SIDEBAR_WIDTH,
} from './SidebarPanel.consts'

export const getSidebarSize: (width: number) => Size = memoize((width: number) => ({
  height: '100%',
  width: `${width - 1}px`,
}))
export const getContentTopOffset: () => number = () => {
  // use #pageContentWrapper because it has correct offsetTop value while page is loading
  const contentEl = document.getElementById('pageContentWrapper')

  if (document.documentElement && contentEl) {
    return contentEl.getBoundingClientRect().top
  }

  return 0
}

export function getDefaultSidebarWidthByWindowWidth(windowWidth: number): number {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  if (windowWidth <= 1000) {
    return 180
  }

  if (windowWidth >= 1600) {
    return 336
  }

  // System of linear equations by three dots - 180:1000, 272:1200, 336:1600
  if (windowWidth < 1200) {
    return (windowWidth - 1000) * 0.46 + 180
  }

  return (windowWidth - 1200) * 0.16 + 272
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

export const getMaxSidebarWidth: () => number = () => {
  const result: number = window.innerWidth - MIN_CONTENT_WIDTH

  if (result < MIN_NON_COLLAPSED_SIDEBAR_WIDTH) {
    return MIN_NON_COLLAPSED_SIDEBAR_WIDTH
  }

  return result
}

export const getCalculatedSidebarWidth = ({
  sidebarCollapsed,
  maxSidebarWidth,
  sidebarWidth,
}: {
  sidebarCollapsed: boolean
  maxSidebarWidth: number
  sidebarWidth: number
}): number => {
  if (sidebarCollapsed) {
    return COLLAPSED_SIDEBAR_WIDTH
  }

  if (sidebarWidth > maxSidebarWidth) {
    return maxSidebarWidth
  }

  if (sidebarWidth < MIN_NON_COLLAPSED_SIDEBAR_WIDTH) {
    return MIN_NON_COLLAPSED_SIDEBAR_WIDTH
  }

  return Math.round(sidebarWidth)
}
