/**
 * @generated SignedSource<<cb09eb318be48ed0daa90b6fce4ddb23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LicenseActivationHasOfflineKeysQuery$variables = Record<PropertyKey, never>;
export type LicenseActivationHasOfflineKeysQuery$data = {
  readonly licensingData: {
    readonly licenseKeys: {
      readonly count: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type LicenseActivationHasOfflineKeysQuery = {
  response: LicenseActivationHasOfflineKeysQuery$data;
  variables: LicenseActivationHasOfflineKeysQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LicensingData",
    "kind": "LinkedField",
    "name": "licensingData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseKeys",
        "kind": "LinkedField",
        "name": "licenseKeys",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LicenseActivationHasOfflineKeysQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LicenseActivationHasOfflineKeysQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d2b0ca4a99d3d0e99ad58c3a9ccd85a5",
    "id": null,
    "metadata": {},
    "name": "LicenseActivationHasOfflineKeysQuery",
    "operationKind": "query",
    "text": "query LicenseActivationHasOfflineKeysQuery {\n  licensingData {\n    licenseKeys {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c9926754e0508bcc4ed631ec857f6d5";

export default node;
