import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import groupBy from 'lodash/groupBy'
import * as React from 'react'

import SvgIcon from '../../../../library/components/SvgIcon/SvgIcon'

import HealthItem from './HealthItem.lazy'
import type {HealthItemOwnProps} from './HealthItem.types'
import {getSeverityClassName} from './HealthItem.utils'
import HealthItemIcon from './HealthItemIcon'

import styles from './HealthItem.css'

type HealthItemGroupProps = {
  severity: 'Error' | 'Warning' | 'Info'
  items: HealthItemOwnProps[]
}

const fullNames = {
  Error: 'critical errors',
  Warning: 'warnings',
  Info: 'recommendations',
}
const names = {
  Error: 'errors',
  Warning: 'warnings',
  Info: 'recommendations',
}

function HealthItemGroup({severity, items}: HealthItemGroupProps) {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <div className={classNames(styles.outerContainer, getSeverityClassName(severity))}>
      <div className={styles.container}>
        <HealthItemIcon severity={severity} />
        <div
          className={classNames(styles.content, styles.groupTitle)}
        >{`${items.length} ${fullNames[severity]}`}</div>
        <Button className={styles.toggleGroupButton} inline onClick={() => setExpanded(!expanded)}>
          {`${expanded ? 'Hide' : 'Show'} list of ${names[severity]}`}
          <SvgIcon
            className={styles.toggleGroupChevron}
            icon={expanded ? 'chevron-up' : 'chevron-down'}
          />
        </Button>
      </div>
      <div className={styles.groupedItems} hidden={!expanded}>
        {items.map(item => (
          <HealthItem key={item.identity} {...item} grouped />
        ))}
      </div>
    </div>
  )
}

export type HeaderHealthItemsProps = {
  items: HealthItemOwnProps[]
}
const severityOrder = ['Error', 'Warning', 'Info'] as const
export default function HeaderHealthItems({items}: HeaderHealthItemsProps) {
  const groups = groupBy(items, 'severity')
  return severityOrder.map(severity => {
    const group = groups[severity] ?? []
    if (group.length === 0) {
      return null
    }
    if (group.length === 1) {
      return <HealthItem key={severity} {...group[0]} />
    }
    return <HealthItemGroup key={severity} severity={severity} items={group} />
  })
}
