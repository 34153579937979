import archiveIcon from '@jetbrains/icons/archive.svg'
import fileIcon from '@jetbrains/icons/file.svg'
import folderIcon from '@jetbrains/icons/folder.svg'
import type {IconAttrs} from '@jetbrains/ring-ui/components/icon/icon'

import SvgIcon from '../../../library/components/SvgIcon/SvgIcon'

const icons = {
  file: fileIcon,
  folder: folderIcon,
  archive: archiveIcon,
}

type Props = IconAttrs & {
  icon: keyof typeof icons
}

export default function FileTreeIcon({icon, ...restProps}: Props) {
  return <SvgIcon {...restProps} icon={icons[icon]} />
}
