/**
 * @generated SignedSource<<c7912abfd53f06c92cad740519dda564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HttpsConfigurationPageQuery$variables = Record<PropertyKey, never>;
export type HttpsConfigurationPageQuery$data = {
  readonly serverInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"HttpsConfigurationPageDomainInfoFragment" | "HttpsConfigurationPageServerUrlInfoFragment">;
  } | null | undefined;
};
export type HttpsConfigurationPageQuery = {
  response: HttpsConfigurationPageQuery$data;
  variables: HttpsConfigurationPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HttpsConfigurationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Server",
        "kind": "LinkedField",
        "name": "serverInfo",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HttpsConfigurationPageDomainInfoFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HttpsConfigurationPageServerUrlInfoFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HttpsConfigurationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Server",
        "kind": "LinkedField",
        "name": "serverInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "artifactsUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "205424bdbfbe4677bc76797688d4f860",
    "id": null,
    "metadata": {},
    "name": "HttpsConfigurationPageQuery",
    "operationKind": "query",
    "text": "query HttpsConfigurationPageQuery {\n  serverInfo {\n    ...HttpsConfigurationPageDomainInfoFragment\n    ...HttpsConfigurationPageServerUrlInfoFragment\n  }\n}\n\nfragment HttpsConfigurationPageDomainInfoFragment on Server {\n  webUrl\n  artifactsUrl\n}\n\nfragment HttpsConfigurationPageServerUrlInfoFragment on Server {\n  webUrl\n}\n"
  }
};

(node as any).hash = "80ce52fe4a7266eb7eed420edb3b81bb";

export default node;
