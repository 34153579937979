import {graphql} from 'react-relay'

export const hasOfflineKeysQuery = graphql`
  query LicenseActivationHasOfflineKeysQuery {
    licensingData {
      licenseKeys {
        count
      }
    }
  }
`
