import Button from '@jetbrains/ring-ui/components/button/button'
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group'
import {ControlsHeight} from '@jetbrains/ring-ui/components/global/controls-height'
import {useEffect} from 'react'

import {restApi} from '../../../../services/rest'
import {hideDsl, initDslOptions, showDsl} from '../Dsl.actions'
import {getDslOptions} from '../Dsl.selectors'

import {useIsDslLoaded} from './ShowDsl.hooks'

import {useAppDispatch, useAppSelector} from 'src/hooks/react-redux'
import type {BuildTypeId} from 'src/types'

type Props = {
  controlId: string
  isFragment?: boolean
  buildTypeOrTemplateId?: BuildTypeId
  onShowDsl?: () => unknown
  onHideDsl?: () => unknown
}

function ShowDsl({controlId, isFragment, buildTypeOrTemplateId, onShowDsl, onHideDsl}: Props) {
  const dispatch = useAppDispatch()

  const isDslMode = useAppSelector(state => getDslOptions(state, controlId)?.show ?? false)
  const isDslLoaded = useIsDslLoaded(controlId, buildTypeOrTemplateId, isFragment)

  const handleHideDsl = () => {
    dispatch(hideDsl(controlId))
    onHideDsl?.()
  }

  const handleShowDsl = () => {
    dispatch(showDsl(controlId))
    onShowDsl?.()
  }

  const {portable, version} = restApi.endpoints.getDslOptions.useQuery(undefined, {
    selectFromResult: ({data}) => {
      const defaultOptionFromBackend =
        data != null ? data.kotlin.find(options => options.current) || data.kotlin[0] : null
      return {
        portable: defaultOptionFromBackend ? defaultOptionFromBackend.portable : true,
        version: defaultOptionFromBackend ? defaultOptionFromBackend.version : 'v2018_2',
      }
    },
  })
  useEffect(() => {
    dispatch(initDslOptions({controlId, options: {show: false, portable, version}}))
  }, [dispatch, controlId, portable, version])

  return (
    <span>
      <ButtonGroup>
        <Button
          onClick={handleHideDsl}
          active={!isDslMode}
          disabled={isDslMode && !isDslLoaded}
          height={ControlsHeight.M}
        >
          {'UI'}
        </Button>
        <Button onClick={handleShowDsl} active={isDslMode} height={ControlsHeight.M}>
          {'View as code'}
        </Button>
      </ButtonGroup>
    </span>
  )
}

export default ShowDsl
