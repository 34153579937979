import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../utils/makeResource'

import type {ProjectPopupContainerComponent} from './ProjectsPopup'
import {federationServersQuery} from './ProjectsPopup.queries'

export const ProjectsPopupEntryPoint: EntryPoint<ProjectPopupContainerComponent, boolean> = {
  root: makeResource(
    'ProjectsPopup',
    () => import(/* webpackChunkName: "ProjectsPopup" */ './ProjectsPopup'),
  ),
  getPreloadProps: isBreadcrumbs => ({
    queries: {
      federationServers: {
        parameters: getRequest(federationServersQuery),
        variables: {skip: isBreadcrumbs},
      },
    },
  }),
}
