import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {BuildPageTabNamesEnum} from '../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {parseBranch} from '../../../utils/branchNames'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'
import {ParameterNames} from '../PipelinesPages/types'

import {BuildArtifactsTabEntryPoint} from './BuildArtifactsTab/BuildArtifactsTab.entryPoint'
import {BuildChangesTabEntryPoint} from './BuildChangesTab/BuildChangesTab.entryPoint'
import {BuildOverviewTabEntryPoint} from './BuildOverviewTab/BuildOverviewTab.entryPoint'
import type {BuildPageComponent, NestedEntryPoints} from './BuildPage.container'
import {query} from './BuildPage.queries'
import {getBuildOrPermalinkLocator} from './BuildPage.utils'
import {BuildParametersTabEntryPoint} from './BuildParametersTab/BuildParametersTab.entryPoint'
import {BuildTestsTabEntryPoint} from './BuildTestsTab/BuildTestsTab.entryPoint'
import {DependenciesTabEntryPoint} from './DependenciesTab/DependenciesTab.entryPoint'

export const BuildPageEntryPoint: EntryPoint<BuildPageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'BuildPage',
    () => import(/* webpackChunkName: "BuildPage" */ './BuildPage.container'),
  ),
  getPreloadProps({params, request}) {
    const {buildTypeId, buildId} = params
    const url = new URL(request.url)
    const {buildTab, branch, buildParametersTab} = queryToObject(url.search)
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const buildLocator = getBuildOrPermalinkLocator(buildId, buildTypeId, parseBranch(branch))
    if (buildTypeId != null && buildId != null) {
      switch (buildTab) {
        case BuildPageTabNamesEnum.DEPENDENCIES:
          entryPoints.dependenciesTab = {
            entryPoint: DependenciesTabEntryPoint,
            entryPointParams: {buildTypeId, buildLocator},
          }
          break
        case BuildPageTabNamesEnum.TESTS:
          entryPoints.testsTab = {
            entryPoint: BuildTestsTabEntryPoint,
            entryPointParams: {
              params,
              request,
              context: {
                buildLocator,
              },
            },
          }
          break
        case BuildPageTabNamesEnum.PARAMETERS:
          entryPoints.parametersTab = {
            entryPoint: BuildParametersTabEntryPoint,
            entryPointParams: {buildLocator, buildParametersTab},
          }
          break
        case BuildPageTabNamesEnum.ARTIFACTS:
          entryPoints.artifactsTab = {
            entryPoint: BuildArtifactsTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        case BuildPageTabNamesEnum.CHANGES:
          entryPoints.changesTab = {
            entryPoint: BuildChangesTabEntryPoint,
            entryPointParams: buildLocator,
          }
          break
        case BuildPageTabNamesEnum.OVERVIEW:
        default:
          entryPoints.overviewTab = {
            entryPoint: BuildOverviewTabEntryPoint,
            entryPointParams: buildLocator,
          }
      }
    }
    return {
      queries: {
        main: {
          parameters: getRequest(query),
          variables: {
            btLocator: `id:${buildTypeId}`,
            headLocator: `parameter(${ParameterNames.PIPELINE_HEAD})`,
          },
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
