import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {toAgentPoolId} from '../../../types'
import {makeResource} from '../../../utils/makeResource'
import {queryToObject} from '../../../utils/queryParams'

import {queueActionsBuildsQuery} from './QueueActions/QueueActions.queries'
import type {QueuePageComponent} from './QueuePage'
import {query} from './QueuePage.queries'
import {getQueuePageLocator} from './QueuePage.utils'

export const QueuePageEntryPoint: EntryPoint<QueuePageComponent, LoaderFunctionArgs> = {
  root: makeResource('QueuePage', () => import(/* webpackChunkName: "QueuePage" */ './QueuePage')),
  getPreloadProps: ({request}) => {
    const url = new URL(request.url)
    const {agentPoolId, onlyMyPersonal} = queryToObject(url.search)

    return {
      queries: {
        main: {
          parameters: getRequest(query),
          variables: {locator: `id:${agentPoolId}`, skip: agentPoolId == null},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
        queueActionsBuilds: {
          parameters: getRequest(queueActionsBuildsQuery),
          variables: {
            locator: getQueuePageLocator(
              agentPoolId != null ? toAgentPoolId(agentPoolId) : null,
              onlyMyPersonal === 'true',
            ),
          },
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }
  },
}
