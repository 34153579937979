import styles from './SidebarPanel.css'

export const COLLAPSED_SIDEBAR_WIDTH = 32
export const MIN_NON_COLLAPSED_SIDEBAR_WIDTH = 180
export const SIDEBAR_UNCOLLAPSE_DELTA = 10
export const MIN_CONTENT_WIDTH = 820
const CONTENT_WIDTH = 1600
const CONTENT_SIDE_PADDING = 32
export const CONTENT_WIDTH_WITH_PADDING = CONTENT_WIDTH + CONTENT_SIDE_PADDING * 2
export const RESIZABLE_ENABLE_CONFIG = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}
export const RESIZABLE_HANDLE_CLASSES = {
  right: styles.resizableRightHandle,
}
