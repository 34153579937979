import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {FetchBranchesParams} from '../../../rest/branches'
import {makeResource} from '../../../utils/makeResource'

import {branchesQuery, getBranchesQueryVariables} from './BranchSelect.queries'
import type {BranchSelectStandaloneComponent} from './BranchSelect.standalone'

export const BranchSelectEntryPoint: EntryPoint<
  BranchSelectStandaloneComponent,
  FetchBranchesParams
> = {
  root: makeResource(
    'BranchSelect',
    () => import(/* webpackChunkName: "BranchSelect" */ './BranchSelect.standalone'),
  ),
  getPreloadProps: params => ({
    queries: {
      branches: {
        parameters: getRequest(branchesQuery),
        variables: getBranchesQueryVariables(params),
      },
    },
  }),
}
