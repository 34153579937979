import {graphql} from 'react-relay'

export const matrixQuery = graphql`
  query MatrixParamBuildQuery($btLocator: String!, $featureId: String!, $skip: Boolean!) {
    buildTypeMatrix(btLocator: $btLocator, featureId: $featureId) @skip(if: $skip) {
      ...MatrixParamBuildContext_matrix
    }
  }
`
export const projectsDefaultValueSetsQuery = graphql`
  query MatrixParamBuildProjectsDefaultValueSetsQuery($projectLocator: String!) {
    projectsDefaultValueSets(projectLocator: $projectLocator) {
      valueSet {
        name
        keyword
        description
        value {
          value
          label
        }
        displayName
      }
    }
  }
`
