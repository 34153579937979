import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {TestScopesViewContainerComponent} from './TestScopesView'
import {testScopesViewQuery} from './TestScopesView.queries'
import {getScopesViewVariables} from './TestScopesView.utils'

type TestScopesViewEntryPointParams = {
  buildLocator: string
}
export const TestScopesViewEntryPoint: EntryPoint<
  TestScopesViewContainerComponent,
  LoaderFunctionArgs<TestScopesViewEntryPointParams>
> = {
  root: makeResource(
    'TestScopesView',
    () => import(/* webpackChunkName: "TestScopesView" */ './TestScopesView'),
  ),
  getPreloadProps: ({request, context}) => {
    const url = new URL(request.url)
    const {locator, scopeName} = getScopesViewVariables({
      location: url,
      buildLocator: context?.buildLocator!,
    })

    return {
      queries: {
        testScopes: {
          parameters: getRequest(testScopesViewQuery),
          variables: {
            scopeName,
            locator,
          },
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }
  },
}
