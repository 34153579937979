import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {
  getSnapshotDependenciesCountersVariables,
  snapshotDependenciesCountersQuery,
} from '../../../common/BuildInfoTable/SnapshotDependenciesCounters/SnapshotDependenciesCounters.queries'
import {
  buildProblemsPreviewQuery,
  getBuildProblemsPreviewVariables,
} from '../../../packages/BuildProblems/BuildProblemsPreview/BuildProblemsPreview.queries'
import {
  getCountsVariables,
  testsPreviewPanelCountsQuery,
} from '../../../packages/Tests/TestsPreviewPanel/TestsPreviewPanel.queries'

import type {BuildOverviewTabOuterContainerComponent} from './BuildOverviewTab.container'

export const BuildOverviewTabEntryPoint: EntryPoint<
  BuildOverviewTabOuterContainerComponent,
  string
> = {
  root: makeResource(
    'BuildOverviewTab',
    () => import(/* webpackChunkName: "BuildOverviewTab" */ './BuildOverviewTab.container'),
  ),
  getPreloadProps: buildLocator => ({
    queries: {
      testCounts: {
        parameters: getRequest(testsPreviewPanelCountsQuery),
        variables: getCountsVariables(buildLocator),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      snapshotDependenciesCounters: {
        parameters: getRequest(snapshotDependenciesCountersQuery),
        variables: getSnapshotDependenciesCountersVariables(buildLocator),
      },
      buildProblemsPreview: {
        parameters: getRequest(buildProblemsPreviewQuery),
        variables: getBuildProblemsPreviewVariables(buildLocator),
      },
    },
  }),
}
