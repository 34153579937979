import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {QueueSidebarContainerComponent} from './QueueSidebar'
import {query} from './QueueSidebar.queries'

export const QueueSidebarEntryPoint: EntryPoint<QueueSidebarContainerComponent> = {
  root: makeResource(
    'QueueSidebar',
    () => import(/* webpackChunkName: "QueueSidebar" */ './QueueSidebar'),
  ),
  getPreloadProps: () => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
