import type {EntryPoint, EntryPointComponent} from 'react-relay'

import {makeResource} from '../../../utils/makeResource'

import type {OpenInSakuraUIProps} from './ToggleSakuraUI.types'

export const OpenInSakuraUIEntryPoint: EntryPoint<
  EntryPointComponent<{}, {}, OpenInSakuraUIProps>
> = {
  root: makeResource(
    'OpenInSakuraUI',
    () => import(/* webpackChunkName: "OpenInSakuraUI" */ './OpenInSakuraUI.container'),
  ),
  getPreloadProps: () => ({}),
}
