import type {Diagnostic} from '@codemirror/lint'

import type {BuildId} from '../../../../../types'
import type {KeyValue} from '../../../../../utils/object'
import type {SuggestionType} from '../../types'

import type {PipelineDraftState, PipelineFormsState} from './EditPipelinePage.slices.types'

export const initialState: PipelineDraftState = {}

export const collapsedBlocksInitialState: Record<string, boolean> = {}

export const pipelineFormInitialState: PipelineFormsState = {}

export const pipelineYamlInitialState: KeyValue<
  string,
  {yaml?: string; diagnostics?: Diagnostic[]}
> = {}
export const pipelineYamlValidityState: KeyValue<string, {isParsed: boolean; yaml?: string}> = {}
export const MIN_PARALLELISM_COUNT = 2

export const suggestionsInitialState: {
  skippedSuggestions: Record<string, string[]>
  successMessages: Record<string, SuggestionType | null>
} = {
  skippedSuggestions: {},
  successMessages: {},
}

export const debugInitialState: Record<
  string,
  {
    isRunning?: boolean
    isPanelOpen?: boolean
    isPanelExpanded?: boolean
    runId?: BuildId | null
    jobId?: string | null
    yaml?: string | null
  }
> = {}
