import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {PipelineRunBreadcrumbsComponent} from './PipelineRunBreadcrumbs'
import {query} from './PipelineRunBreadcrumbs.queries'

const PipelineRunBreadcrumbsEntryPoint: EntryPoint<
  PipelineRunBreadcrumbsComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'PipelineRunBreadcrumbs',
    () =>
      import(
        /* webpackChunkName: "PipelineRunBreadcrumbs" */
        './PipelineRunBreadcrumbs'
      ),
  ),
  getPreloadProps: ({params}) => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables: {pipelineRunLocator: `id:${params.pipelineRunId}`},
      },
    },
  }),
}
export default PipelineRunBreadcrumbsEntryPoint
