import type {TabParams, TabParamsKey} from '../types'
import {stringifyId} from '../types'

import {stringifyBranch} from './branchNames'
import {objectToQuery} from './queryParams'

type ParsedTabTitle = {
  name: string
  counter?: string
  marker?: boolean
  warning?: boolean
}
export function parseTabTitle(title: string): ParsedTabTitle {
  const re = /^(.*)\s\((\d*)(\+?)(!?)\)$/ // tab title format can be: (<num>), (<num>+), (+), (<num>!), (<num>+!), (!), (+!)

  const match = title.match(re)

  if (!match) {
    return {
      name: title,
    }
  }

  const [_, name, counter, marker, warning] = match

  return {
    name,
    counter,
    marker: Boolean(marker),
    warning: Boolean(warning),
  }
}

export const getTabParamsKey = ({
  buildId,
  buildTypeId,
  projectId,
  changeId,
  agentId,
  agentTypeId,
  branch,
  personal,
}: TabParams): TabParamsKey => {
  if (buildId != null) {
    return objectToQuery({
      buildId: stringifyId(buildId),
    })
  }

  if (buildTypeId != null) {
    return objectToQuery({
      buildTypeId: stringifyId(buildTypeId),
      ...(branch != null ? {branch: stringifyBranch(branch)} : {}),
    })
  }

  if (projectId != null) {
    return objectToQuery({
      projectId: stringifyId(projectId),
      ...(branch != null ? {branch: stringifyBranch(branch)} : {}),
    })
  }

  if (agentId != null) {
    return objectToQuery({
      agentId: stringifyId(agentId),
    })
  }

  if (agentTypeId != null) {
    return objectToQuery({
      agentTypeId: stringifyId(agentTypeId),
    })
  }

  if (changeId != null) {
    return objectToQuery({
      modId: stringifyId(changeId),
      personal: (personal ?? false).toString(),
    })
  }

  return ''
}
