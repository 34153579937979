import {injectRuleSet} from '@jetbrains/ring-ui/components/global/inject-styles'
import {renderToStaticMarkup} from 'react-dom/server'

import GitlabIcon from './svg/gitlab.svg'

import(
  /* webpackChunkName: "BitbucketIcon" */ './components/common/BitbucketIcon/BitbucketIcon'
).then(({default: BitbucketIcon, mainColor}) => {
  const values = [false, true]
  values.forEach(disabled => {
    const html = renderToStaticMarkup(<BitbucketIcon disabled={disabled} />)
    const [svg] = html.replace(/\n/g, '').match(/<svg.*<\/svg>/) || ['']
    const patchedSvg = svg.replace(/<svg/, `$& color="${mainColor(disabled)}"`)
    injectRuleSet(`.tc-icon_bitbucket${disabled ? '_disabled' : ''}`, {
      background: `url('data:image/svg+xml;utf8,${encodeURIComponent(patchedSvg)}')`,
      'background-size': '140% 140%',
      'background-position': '50% 55%',
    })
  })
})
injectRuleSet(
  `.tc-icon_gitlab,
  .tc-icon_gitlab_disabled,
  .tc-icon_gitlab-enterprise,
  .tc-icon_gitlab-enterprise_disabled`,
  {
    background: `url('data:image/svg+xml;utf8,${encodeURIComponent(
      renderToStaticMarkup(<GitlabIcon xmlns="http://www.w3.org/2000/svg" />),
    )}')`,
    'background-size': '110%',
    'background-position': '50% 55%',
  },
)
