import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'
import {EditPipelinePageEntryPoint} from '../EditPipelinePage/EditPipelinePage.entryPoint'

import {PipelineRunContentEntryPoint} from './PipelineRunContent/PipelineRunContent.entryPoint'
import type {NestedEntryPoints, PipelineRunPageComponent} from './PipelineRunPage'
import {pipelineQuery} from './PipelineRunPage.queries'

export const PipelineRunPageEntryPoint: EntryPoint<PipelineRunPageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'PipelineRunPage',
    () => import(/* webpackChunkName: "PipelineRunPage" */ './PipelineRunPage'),
  ),
  getPreloadProps: ({params, request}) => {
    const url = new URL(request.url)
    const {edit} = queryToObject(url.search)
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {
      pipelineRunContent: {
        entryPoint: PipelineRunContentEntryPoint,
        entryPointParams: {request, params},
      },
    }
    if (edit === 'true') {
      entryPoints.editPipelineContent = {
        entryPoint: EditPipelinePageEntryPoint,
        entryPointParams: {request, params},
      }
    }
    return {
      queries: {
        pipeline: {
          parameters: getRequest(pipelineQuery),
          variables: {locator: `internalId:${params.pipelineId}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
