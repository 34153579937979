import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {getBranchLocator} from '../../../../rest/locators'
import type {Branch} from '../../../../services/rest'
import type {BuildTypeId} from '../../../../types'
import {makeResource} from '../../../../utils/makeResource'
import {commiterSelectQuery} from '../../../common/UserSelect/CommiterSelect.queries'

import type {PendingChangesTabComponent} from './PendingChangesTab'

export type Params = {
  buildTypeId: BuildTypeId
  branch?: Branch | null | undefined
}

export const PendingChangesTabEntryPoint: EntryPoint<PendingChangesTabComponent, Params> = {
  root: makeResource(
    'PendingChangesTab',
    () => import(/* webpackChunkName: "PendingChangesTab" */ './PendingChangesTab'),
  ),
  getPreloadProps: ({buildTypeId, branch}) => {
    const branchLocator = getBranchLocator(branch, true)
    const policyLocator = branch == null ? ',policy:SINCE_LAST_BUILD_IN_ANY_BRANCH' : ''
    const branchAndPolicyLocator = branchLocator ? `,${branchLocator}${policyLocator}` : ''
    const baseLocator = `buildType:(id:${buildTypeId})${branchAndPolicyLocator},pending:true`
    return {
      queries: {
        commiterSelect: {
          parameters: getRequest(commiterSelectQuery),
          variables: {changeLocator: baseLocator},
        },
      },
      extraProps: {baseLocator},
    }
  },
}
