import type {Diagnostic} from '@codemirror/lint'
import type {PayloadAction} from '@reduxjs/toolkit'
import {isAnyOf, createSlice} from '@reduxjs/toolkit'

import {assignIfDeepDifferent} from '../../../../../reducers/utils'
import {pipelinesApi} from '../../services/pipelinesApi'
import type {SuggestionType} from '../../types'
import {isStoreYamlInVcsEnabled} from '../../utils/featureToggles'
import {matchError} from '../../utils/validators'
import {parsePipeline} from '../EditPipelinePage.utils'

import * as pipelineDraftAgent from './EditPipelinePage.agent.slice'
import * as pipelineDraftBranch from './EditPipelinePage.branch.slice'
import * as pipelineDraftDependencies from './EditPipelinePage.dependencies.slice'
import * as pipelineDraftFilesPublicationJob from './EditPipelinePage.filesPublicationJob.slice'
import * as pipelineDraftIntegration from './EditPipelinePage.integration.slice'
import * as pipelineDraftJob from './EditPipelinePage.job.slice'
import * as pipelineDraftParameter from './EditPipelinePage.parameter.slice'
import * as pipelineDraftMain from './EditPipelinePage.pipeline.slice'
import * as pipelineDraftSecret from './EditPipelinePage.secret.slice'
import {
  collapsedBlocksInitialState,
  debugInitialState,
  initialState,
  pipelineFormInitialState,
  pipelineYamlInitialState,
  pipelineYamlValidityState,
  suggestionsInitialState,
} from './EditPipelinePage.slices.consts'
import type {FormType, PipelineFormParams} from './EditPipelinePage.slices.types'
import {deleteIfEmpty, getPipelineFormKey} from './EditPipelinePage.slices.utils'
import * as pipelineDraftSteps from './EditPipelinePage.steps.slice'
import * as pipelineDraftTrigger from './EditPipelinePage.trigger.slice'
import * as pipelineDraftVersionedSettings from './EditPipelinePage.versionedSettings.slice'

export const pipelineDraft = createSlice({
  name: 'pipelineDraft',
  initialState,
  reducers: {
    ...pipelineDraftMain,
    ...pipelineDraftDependencies,
    ...pipelineDraftBranch,
    ...pipelineDraftSteps,
    ...pipelineDraftJob,
    ...pipelineDraftParameter,
    ...pipelineDraftIntegration,
    ...pipelineDraftSecret,
    ...pipelineDraftFilesPublicationJob,
    ...pipelineDraftTrigger,
    ...pipelineDraftAgent,
    ...pipelineDraftVersionedSettings,
  },
  extraReducers: builder => {
    builder.addMatcher(pipelinesApi.endpoints.getPipelineById.matchFulfilled, (state, action) => {
      const {originalArgs: id, forceRefetch} = action.meta.arg
      const data = action.payload
      const apiPipelineVersion = data.pipelineVersion
      const originalPipelineVersion = state[id]?.original?.pipelineVersion
      const isCollisionApi = apiPipelineVersion !== originalPipelineVersion

      state[id] ??= {}

      if (!isCollisionApi) {
        assignIfDeepDifferent(state[id]!, {
          original: data,
          draft: state[id]!.draft ?? data,
        })
      } else if (forceRefetch) {
        assignIfDeepDifferent(state[id]!, {
          original: data,
          draft: data,
        })
      }

      state[id]!.isCollisionApi = isCollisionApi && !forceRefetch
    })
  },
})

export const pipelineDraftForm = createSlice({
  name: 'pipelineDraftForm',
  initialState: {
    submitted: false,
  },
  reducers: {
    submit: state => {
      state.submitted = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(pipelineDraft.actions.set, pipelineDraft.actions.reset), state => {
      state.submitted = false
    })
  },
})

export const hoveredJob = createSlice({
  name: 'hoveredJob',
  initialState: null as string | null,
  reducers: {
    set: (_, action: PayloadAction<string | null>) => action.payload,
  },
})

export const collapsedBlocks = createSlice({
  name: 'collapsedBlocks',
  initialState: collapsedBlocksInitialState,
  reducers: {
    toggle(state, action: PayloadAction<{id: string; collapsed: boolean}>) {
      const {id, collapsed} = action.payload
      state[id] = collapsed
    },
  },
})

export const pipelineForm = createSlice({
  name: 'pipelineForm',
  initialState: pipelineFormInitialState,
  reducers: {
    open(state, action: PayloadAction<PipelineFormParams>) {
      const {pipelineId, ...rest} = action.payload
      state[pipelineId] ??= {}
      state[pipelineId][getPipelineFormKey(rest)] = {open: true, isAdd: action.payload.isAdd}
    },
    close(state, action: PayloadAction<PipelineFormParams>) {
      const {pipelineId, ...rest} = action.payload
      if (state[pipelineId] != null) {
        state[pipelineId][getPipelineFormKey(rest)] = {open: false}
      }
    },
    closeAll(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
  },
})

export const pipelineYaml = createSlice({
  name: 'pipelineYaml',
  initialState: pipelineYamlInitialState,
  reducers: {
    setYaml: (state, action: PayloadAction<{id: string; yaml: string}>) => {
      const {id, yaml} = action.payload
      if (state[id] != null) {
        state[id]!.yaml = yaml
      } else {
        state[id] = {yaml}
      }
    },
    setDiagnostics: (
      state,
      action: PayloadAction<{id: string; diagnostics: Omit<Diagnostic, 'actions'>[]}>,
    ) => {
      const {id, diagnostics} = action.payload

      if (state[id] != null) {
        state[id]!.diagnostics = diagnostics
      } else {
        state[id] = {diagnostics}
      }
    },
    reset: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
})
export const pipelineYamlValidity = createSlice({
  name: 'pipelineYamlValidity',
  initialState: pipelineYamlValidityState,
  reducers: {
    setParsed: (state, action: PayloadAction<{id: string; value: boolean; yaml?: string}>) => {
      const {id, value, yaml} = action.payload
      state[id] = {isParsed: value, yaml}
    },
  },
  extraReducers: builder => {
    builder.addMatcher(pipelinesApi.endpoints.getPipelineById.matchFulfilled, (state, action) => {
      if (isStoreYamlInVcsEnabled) {
        const data = action.payload
        if (data) {
          const {id, yaml = ''} = action.payload
          try {
            if (yaml !== '') {
              parsePipeline(yaml)
              state[id] = {isParsed: true}
            } else {
              state[id] = {isParsed: false, yaml}
            }
          } catch (e) {
            state[id] = {isParsed: false, yaml}
          }
        }
      } else {
        const {id} = action.payload
        state[id] = {isParsed: true}
      }
    })
  },
})

export const suggestions = createSlice({
  name: 'suggestions',
  initialState: suggestionsInitialState,
  reducers: {
    skip(state, action: PayloadAction<{id: string; values: string[]}>) {
      const {id, values} = action.payload
      state.skippedSuggestions[id] = values
    },
    showSuccessMessage(state, action: PayloadAction<{id: string; type: SuggestionType}>) {
      const {id, type} = action.payload
      state.successMessages[id] = type
    },
    hideSuccessMessage(state, action: PayloadAction<{id: string}>) {
      const {id} = action.payload
      state.successMessages[id] = null
    },
  },
})

export type PipelineError = {
  pipelineId: string
  jobId?: string | null
  formType?: FormType
  formId?: string | number
  fieldName?: string
  message?: string
}
const pipelineErrorsInitialState: PipelineError[] = []
export const pipelineErrors = createSlice({
  name: 'pipelineErrors',
  initialState: pipelineErrorsInitialState,
  reducers: {
    add(state, action: PayloadAction<PipelineError>) {
      state.push(action.payload)
    },
    clear(state, action: PayloadAction<PipelineError>) {
      const condition = (error: PipelineError) => !matchError(action.payload)(error)
      if (!state.every(condition)) {
        return state.filter(condition)
      }
      return undefined
    },
  },
})

type PipelineSecretsState = Record<string, Record<string, string>>
const pipelineSecretsInitialState: PipelineSecretsState = {}
export const pipelineSecrets = createSlice({
  name: 'pipelineSecrets',
  initialState: pipelineSecretsInitialState,
  reducers: {
    set: (state, action: PayloadAction<{pipelineId: string; savedName: string; value: string}>) => {
      const {pipelineId, savedName, value} = action.payload
      state[pipelineId] ??= {}
      state[pipelineId][savedName] = value
    },
    unset: (state, action: PayloadAction<{pipelineId: string; savedName: string}>) => {
      const {pipelineId, savedName} = action.payload
      delete state[pipelineId]?.[savedName]
      deleteIfEmpty(state, pipelineId)
    },
    unsetAll: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
})

export const debug = createSlice({
  name: 'debugging',
  initialState: debugInitialState,
  reducers: {
    start(state, action: PayloadAction<{pipelineId: string; jobId: string}>) {
      const {pipelineId: id, jobId} = action.payload
      state[id] ??= {}
      state[id].isRunning = true
      state[id].isPanelOpen = true
      state[id].isPanelExpanded = true
      state[id].jobId = jobId
      state[id].runId = null
    },
    stop(state, action: PayloadAction<string>) {
      const id = action.payload
      state[id].isRunning = false
      state[id].isPanelOpen = false
      state[id].runId = null
      state[id].jobId = null
      state[id].yaml = null
    },
    finish(state, action: PayloadAction<string>) {
      const id = action.payload
      state[id].isRunning = false
    },
    expandPanel(state, action: PayloadAction<{pipelineId: string; expanded: boolean}>) {
      const {pipelineId: id, expanded} = action.payload
      state[id].isPanelExpanded = expanded
    },
    toggleOpenPanel(state, action: PayloadAction<{pipelineId: string; open: boolean}>) {
      const {pipelineId: id, open} = action.payload
      state[id].isPanelOpen = open
      state[id].isPanelExpanded = open
    },
  },
  extraReducers: builder => {
    builder.addMatcher(pipelinesApi.endpoints.runPipelineDebug.matchFulfilled, (state, action) => {
      const {originalArgs: args} = action.meta.arg
      const data = action.payload
      const id = args.pipelineId
      const yaml = args.body.yaml
      const runId = data.id

      state[id] ??= {}
      state[id].runId = Number(runId)
      state[id].yaml = yaml
    })
  },
})
