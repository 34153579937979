import MoveToTopIcon from '@jetbrains/icons/move-to-top.svg'
import classNames from 'classnames'
import {useState} from 'react'

import {usePermission} from '../../../hooks/usePermission'
import IconButton from '../../../library/components/IconButton/IconButton'
import type {BuildId, ProjectId} from '../../../types'
import {Permission} from '../../../types'

import styles from './MoveToTop.css'

type Props = {
  className?: string
  buildId: BuildId
  projectId?: ProjectId
  isDraggable: boolean
  clickHandler?: (arg0: BuildId) => Promise<unknown>
  updateBuilds?: () => unknown
}

const getTitle = () => 'Move to top'

export default function MoveToTop({
  className,
  buildId,
  projectId,
  isDraggable,
  clickHandler = () => Promise.resolve(),
  updateBuilds,
}: Props) {
  const canMoveToTop = usePermission(Permission.REORDER_BUILD_QUEUE, projectId)

  const [isMoving, setIsMoving] = useState(false)

  const handleClick: () => Promise<void> = async () => {
    setIsMoving(true)
    await clickHandler(buildId)
    setIsMoving(false)

    if (updateBuilds) {
      updateBuilds()
    }
  }

  const classes = classNames(styles.moveToTop, className)
  return isDraggable && canMoveToTop ? (
    <IconButton
      className={classes}
      title={getTitle()}
      icon={MoveToTopIcon}
      onClick={handleClick}
      loader={isMoving}
    />
  ) : null
}
