import {createContext} from 'react'

export enum PipelinesTarget {
  JOB_DETAILS_POPUP,
  JOB_DETAILS_SIDEBAR,
  JOB_DEBUG_POPUP,
  JOB_DEBUG_PANEL,
}

export const PipelinesTargetContext = createContext<PipelinesTarget | null>(null)
