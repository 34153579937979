import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'

import type {BuildParametersComponent} from './BuildParameters'
import {parametersQuery} from './BuildParameters.queries'

export const BuildParametersEntryPoint: EntryPoint<BuildParametersComponent, string> = {
  root: makeResource(
    'BuildParameters',
    () => import(/* webpackChunkName: "BuildParameters" */ './BuildParameters'),
  ),
  getPreloadProps: buildLocator => ({
    queries: {
      parameters: {
        parameters: getRequest(parametersQuery),
        variables: {buildLocator},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
