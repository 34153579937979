import {getAccountRootProjectId} from '../PipelinesPages.consts'

import {usePermission} from 'src/hooks/usePermission'
import {Permission} from 'src/types'

function usePermissionCanEditProject(): boolean {
  return usePermission(Permission.EDIT_PROJECT, getAccountRootProjectId())
}

export default usePermissionCanEditProject
