/**
 * @generated SignedSource<<91b2bf295cf07e1e0b74393e544c0175>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MatrixParamBuildProjectsDefaultValueSetsQuery$variables = {
  projectLocator: string;
};
export type MatrixParamBuildProjectsDefaultValueSetsQuery$data = {
  readonly projectsDefaultValueSets: {
    readonly valueSet: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly displayName: string | null | undefined;
      readonly keyword: ReadonlyArray<string> | null | undefined;
      readonly name: string | null | undefined;
      readonly value: ReadonlyArray<{
        readonly label: string | null | undefined;
        readonly value: string | null | undefined;
      }> | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type MatrixParamBuildProjectsDefaultValueSetsQuery = {
  response: MatrixParamBuildProjectsDefaultValueSetsQuery$data;
  variables: MatrixParamBuildProjectsDefaultValueSetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectLocator"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "projectLocator",
        "variableName": "projectLocator"
      }
    ],
    "concreteType": "ProjectsDefaultValueResponse",
    "kind": "LinkedField",
    "name": "projectsDefaultValueSets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectsDefaultValue",
        "kind": "LinkedField",
        "name": "valueSet",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keyword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MatrixParameterValue",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatrixParamBuildProjectsDefaultValueSetsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatrixParamBuildProjectsDefaultValueSetsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a2a39826689e4cff01b96115666da7bf",
    "id": null,
    "metadata": {},
    "name": "MatrixParamBuildProjectsDefaultValueSetsQuery",
    "operationKind": "query",
    "text": "query MatrixParamBuildProjectsDefaultValueSetsQuery(\n  $projectLocator: String!\n) {\n  projectsDefaultValueSets(projectLocator: $projectLocator) {\n    valueSet {\n      name\n      keyword\n      description\n      value {\n        value\n        label\n      }\n      displayName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f7d968ffd4900274c7da9b7fd65302b";

export default node;
