import Link from '@jetbrains/ring-ui/components/link/link'
import classnames from 'classnames'
import {useMemo} from 'react'
import {useLocation, useParams} from 'react-router-dom'

import {matchRoute, Routes} from '../../../routes'
import type {BuildId} from '../../../types'
import {stringifyId} from '../../../types'
import BuildCommentByIcon from '../BuildCommentByIcon/BuildCommentByIcon.container'
import BuildStatusChangeComment from '../BuildStatusChangeComment/BuildStatusChangeComment'
import BuildWarning from '../BuildWarning/BuildWarning.container'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import MoveToTop from '../MoveToTop/MoveToTop.container'
import RouterLinkWrapper from '../RouterLinkWrapper/RouterLinkWrapper'
import StarBuild from '../StarBuild/StarBuild.container'

import {formatBuildNumber} from './BuildNumber.utils'

import styles from './BuildNumber.css'

type Props = {
  buildId: BuildId
  className?: string
  number?: string | null | undefined
  queuePosition?: number | null | undefined
  hideStar?: boolean
  showEmptyStar?: boolean
  isPersonal: boolean
  isMine: boolean
  isQueued: boolean
  isCanceled: boolean
  withLink?: boolean
  withComment?: boolean
  withStatusChangeComment?: boolean
  withWarnings?: boolean
  href?: string | null | undefined
}

function BuildNumber({
  buildId,
  className,
  number,
  hideStar,
  showEmptyStar,
  isPersonal,
  isMine,
  isQueued,
  isCanceled,
  queuePosition,
  withComment = false,
  withStatusChangeComment,
  withWarnings = false,
  ...otherProps
}: Props) {
  const location = useLocation()
  const params = useParams()
  const withLinkLocal =
    otherProps.withLink ??
    !(matchRoute(Routes.BUILD, location.pathname) && stringifyId(buildId) === params.buildId)
  const href = withLinkLocal ? otherProps.href : null

  const classes = classnames(styles.buildNumber, className)
  const linkTextClasses = classnames({
    [styles.myPersonal]: isPersonal && isMine,
    [styles.personal]: isPersonal && !isMine,
    [styles.canceled]: isCanceled,
    [styles.link]: href != null,
  })
  const {text, title} = formatBuildNumber({
    number,
    isQueued,
    queuePosition,
  })

  const numberSpan = useMemo(
    () => (
      <MiddleEllipsis
        title={title}
        className={classnames({
          [styles.numberWithComment]: Boolean(withComment),
        })}
      >
        {text}
      </MiddleEllipsis>
    ),
    [text, title, withComment],
  )
  return (
    <div className={classes}>
      {isQueued && <MoveToTop className={styles.moveToTop} buildId={buildId} />}
      {href != null ? (
        <RouterLinkWrapper>
          <Link data-test-build-number-link className={linkTextClasses} href={href}>
            {numberSpan}
          </Link>
        </RouterLinkWrapper>
      ) : (
        <span className={linkTextClasses}>{numberSpan}</span>
      )}
      {hideStar !== true && (
        <StarBuild className={styles.starIcon} buildId={buildId} showEmpty={showEmptyStar} />
      )}
      {withComment && <BuildCommentByIcon buildId={buildId} className={styles.comment} />}
      {withStatusChangeComment && (
        <BuildStatusChangeComment buildId={buildId} className={styles.comment} />
      )}
      {withWarnings && <BuildWarning buildId={buildId} className={styles.warning} />}
    </div>
  )
}
export default BuildNumber
