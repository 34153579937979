/**
 * @generated SignedSource<<824a5f3a1f39a60e716e141f7696748a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PipelineRunPageReusedJobQuery$variables = {
  buildLocator: string;
  skip: boolean;
};
export type PipelineRunPageReusedJobQuery$data = {
  readonly build?: {
    readonly id: number | null | undefined;
  } | null | undefined;
};
export type PipelineRunPageReusedJobQuery = {
  response: PipelineRunPageReusedJobQuery$data;
  variables: PipelineRunPageReusedJobQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineRunPageReusedJobQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineRunPageReusedJobQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1a9cd8d3ee103f74ae249659a2ed1abd",
    "id": null,
    "metadata": {},
    "name": "PipelineRunPageReusedJobQuery",
    "operationKind": "query",
    "text": "query PipelineRunPageReusedJobQuery(\n  $buildLocator: String!\n  $skip: Boolean!\n) {\n  build(buildLocator: $buildLocator) @skip(if: $skip) {\n    id\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "49b1064dfc1ed46aaebc81ff068e16ca";

export default node;
