import {safeLocalStorage} from '../../utils/safeStorages'

export const STORAGE_KEY = 'jb.teamcity.sidebarSettings'
export const STORAGE_VERSION = '0.3'
type SidebarSettings = {
  resizeDelta: number
  collapsed: boolean
  version: string | null | undefined
}
export const getStoredSidebarSettings = (): SidebarSettings | null | undefined => {
  const parsedObj: SidebarSettings | null | undefined = safeLocalStorage.getItemInJSON(STORAGE_KEY)
  return parsedObj && parsedObj.version === STORAGE_VERSION ? parsedObj : null
}
export const saveSidebarSettingsInStore = (resizeDelta: number, collapsed: boolean) => {
  const settings: SidebarSettings = {
    version: STORAGE_VERSION,
    resizeDelta,
    collapsed,
  }
  safeLocalStorage.setItem(STORAGE_KEY, JSON.stringify(settings))
}
