/**
 * @generated SignedSource<<3503f247d3ae34f3fe61087ec1efc07d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentTypePageQuery$variables = {
  agentTypeLocator: string;
  cloudImagesLocator: string;
};
export type AgentTypePageQuery$data = {
  readonly agentType: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentTypePage_agentType">;
  } | null | undefined;
  readonly cloudImages: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentTypePage_cloudImages">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentTypePageQuery = {
  response: AgentTypePageQuery$data;
  variables: AgentTypePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentTypeLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudImagesLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentTypeLocator",
    "variableName": "agentTypeLocator"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "cloudImagesLocator"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentTypePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentTypePage_agentType"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CloudImages",
        "kind": "LinkedField",
        "name": "cloudImages",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentTypePage_cloudImages"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentTypePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CloudImages",
        "kind": "LinkedField",
        "name": "cloudImages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CloudImageRest",
            "kind": "LinkedField",
            "name": "cloudImage",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CloudProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectRest",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CloudProfileError",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "detailedMessage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "51c086eecd37d873e0bc313377d9a6d5",
    "id": null,
    "metadata": {},
    "name": "AgentTypePageQuery",
    "operationKind": "query",
    "text": "query AgentTypePageQuery(\n  $agentTypeLocator: String!\n  $cloudImagesLocator: String!\n) {\n  ...AgentsPagesCanViewAgentDetailsFragment\n  agentType(agentTypeLocator: $agentTypeLocator) {\n    ...AgentTypePage_agentType\n    _id\n  }\n  cloudImages(locator: $cloudImagesLocator) {\n    ...AgentTypePage_cloudImages\n  }\n}\n\nfragment AgentTypePage_agentType on AgentTypeRest {\n  name\n}\n\nfragment AgentTypePage_cloudImages on CloudImages {\n  cloudImage {\n    profile {\n      id\n      project {\n        id\n        _id\n      }\n      error {\n        message\n        detailedMessage\n      }\n      _id\n    }\n    _id\n  }\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5f08f5ad40d279f432caa8b6f3a2548";

export default node;
