import type {EntryPoint} from 'react-relay'

import {makeResource} from '../../../../../utils/makeResource'

import type {PipelineContentComponent} from './PipelineContent'

export const PipelineContentEntryPoint: EntryPoint<PipelineContentComponent> = {
  root: makeResource(
    'PipelineContent',
    () =>
      import(
        /* webpackChunkName: "PipelineContent" */
        './PipelineContent'
      ),
  ),
  getPreloadProps: () => ({}),
}
