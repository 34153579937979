import Dialog from '@jetbrains/ring-ui/components/dialog/dialog'
import {H2} from '@jetbrains/ring-ui/components/heading/heading'
import classNames from 'classnames'
import {useRef, memo, useState, useCallback} from 'react'
import type * as React from 'react'

import EntityPath from '../../../../containers/EntityPath'
import InvestigationHistoryList from '../InvestigationHistoryList/InvestigationHistoryList'

import type {InvestigationHistoryPopupProps} from './InvestigationHistoryPopup.types'

import styles from './InvestigationHistoryPopup.css'

function InvestigationHistoryPopup({
  buildTypeId,
  projectId,
  projectInternalId,
  locator,
  subTitle,
  closeDialog,
  isProcessing,
  isSakuraUI,
}: InvestigationHistoryPopupProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const onScrollContent = useCallback(() => {
    if (ref.current?.scrollTop != null) {
      if (ref.current.scrollTop === 0) {
        setIsScrolled(false)
      }

      if (!isScrolled) {
        setIsScrolled(true)
      }
    }
  }, [ref, isScrolled, setIsScrolled])
  const entityPathProps =
    buildTypeId != null
      ? {
          buildTypeId,
        }
      : {
          projectId,
        }

  const onClick = useCallback((event: React.MouseEvent<HTMLElement> | KeyboardEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const title = 'Investigation History'
  return (
    <div onClick={onClick} role="presentation">
      <Dialog
        label={title}
        className={styles.overlay}
        onCloseAttempt={isProcessing ? undefined : closeDialog}
        showCloseButton={!isProcessing}
        closeButtonInside
        show
        trapFocus
        autoFocusFirst={false}
        contentClassName={styles.dialog}
      >
        <div className={styles.wrapper}>
          <div className={classNames(styles.header, {[styles.contentScroled]: isScrolled})}>
            <H2 className={styles.title}>{title}</H2>
            <div>
              {isSakuraUI === true && (buildTypeId != null || projectId != null) && (
                <EntityPath
                  className={styles.buildTypePath}
                  projectOrBuildTypeNode={{nodeType: 'all'}}
                  {...entityPathProps}
                />
              )}
              {subTitle != null && (
                <div className={styles.subTitle} title={subTitle}>
                  {subTitle}
                </div>
              )}
            </div>
          </div>
          <div
            ref={ref}
            onScroll={onScrollContent}
            className={styles.content}
            /* tabIndex is necessary for "Accessibility Audit", because it is used onScroll */
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
          >
            <InvestigationHistoryList
              className={styles.historyList}
              projectInternalId={projectInternalId}
              locator={locator}
            />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default memo(InvestigationHistoryPopup)
