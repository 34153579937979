import Button from '@jetbrains/ring-ui/components/button/button'
import type {ButtonAttrs} from '@jetbrains/ring-ui/components/button/button'
import buttonStyles from '@jetbrains/ring-ui/components/button/button.css'
import type {IconType} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import * as React from 'react'

import {getDisplayName} from '../../utils/getDisplayName'
import SvgIcon from '../SvgIcon/SvgIcon'

type DefaultProps = {
  loader: boolean
}
type Props = ButtonAttrs &
  DefaultProps & {
    className?: string
    icon: IconType | string
    special?: boolean
    title?: string
    children?: React.ReactNode
    childrenLeft?: boolean
    withBorder?: boolean
  }
export default class IconButton extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    loader: false,
  }

  render(): React.ReactNode {
    const {
      className,
      icon,
      iconClassName,
      special,
      title,
      children,
      childrenLeft,
      withBorder = false,
      ...restProps
    } = this.props
    const classes = classNames(className, {
      [buttonStyles.withNormalIcon]:
        special !== true &&
        !restProps.primary &&
        !restProps.danger &&
        !restProps.disabled &&
        !withBorder &&
        !children,
      [buttonStyles.iconOnly]: !children,
    })
    const iconClasses = classNames(buttonStyles.icon, iconClassName)
    return (
      <Button
        {...restProps}
        inline={!withBorder}
        className={classes}
        title={title ?? (typeof children === 'string' ? children : null) ?? getDisplayName(icon)}
      >
        {Boolean(children) && Boolean(childrenLeft) && <span>{children}</span>}
        <SvgIcon icon={icon} className={iconClasses} />
        {Boolean(children) && !childrenLeft && <span>{children}</span>}
      </Button>
    )
  }
}
