import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import type {ProjectId} from '../../../../types'
import {makeResource} from '../../../../utils/makeResource'

import type {ProjectInvestigationsTabComponent} from './ProjectInvestigationsTab'
import {countQuery, getCountVariables} from './ProjectInvestigationsTab.queries'

export const ProjectInvestigationsTabEntryPoint: EntryPoint<
  ProjectInvestigationsTabComponent,
  ProjectId
> = {
  root: makeResource(
    'ProjectInvestigationsTab',
    () => import(/* webpackChunkName: "ProjectInvestigationsTab" */ './ProjectInvestigationsTab'),
  ),
  getPreloadProps: projectId => ({
    queries: {
      count: {
        parameters: getRequest(countQuery),
        variables: getCountVariables(projectId),
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
